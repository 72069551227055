import React, { useState, useContext, useEffect } from "react";
import { getListOfRecords, postData, downloadReport } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import moment from "moment";
import axios from "axios";
import appJson from "../../assets/app.json";
import {
  Row,
  Col,
  Button,
  Drawer,
  AutoComplete,
  Form,
  InputNumber,
  DatePicker,
  Space,
  Input,
  Modal,
  Table,
  Menu,
  Dropdown,
  message,
  Select,
  Typography,
  Tooltip,
  Card,
  Spin,
} from "antd";
import {
  PlusOutlined,
  ClearOutlined,
  SaveOutlined,
  EditOutlined,
  MedicineBoxOutlined,
  DeleteOutlined,
  DownOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Document, Page } from "react-pdf";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;
const DiagnosticRequest = (props) => {
  const { settings } = useContext(SettingsContext);
  const [form] = Form.useForm();
    const [isProcessing, setIsProcessing] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [diagnostics, setDiagnostics] = useState([]);
  const [formx, setForm] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [brandname, setBrandname] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [caseId, setCaseId] = useState(props.caseId);
  const [emailAddress] = useState(props.emailAddress);
  const [bName, setBName] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [Id, setId] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  const [prescriptionDate, setPrescriptionDate] = useState(
    moment(new Date(), "YYYY-MM-DD")
  );
  const [data, setData] = useState([]);
  const [showModalAddMedicines, setShowModalAddMedicines] = useState(false);
  const [followUpDate, setFollowUpDate] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [patientId, setPatientId] = useState(props.patientId);
  const [dataPrescriptions, setDataPrescriptions] = useState([]);
  const [printFile, setPrintFile] = useState(null);
const [showModalPrintWithSignature, setShowModalPrintWithSignature] = useState(
  false
);
  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };

  useEffect(() => {
    getListOfRecords({
      sp: "diagnostics",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setDiagnostics(res);
      }
    });

    getListOfRecords({
      sp: "diag",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setPharmacies(res);
      }
    });

    handleGetPrescription();
  }, []);

  useEffect(() => {
    getListOfRecords({
      sp: "brandname",
      param1: bName,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setBrandname(res);
      }
    });
  }, [bName]);

  const handleGetPrescription = () => {
    getListOfRecords({
      sp: "udp_RequestPerPatient",
      param1: patientId,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setDataPrescriptions(res);
    });
  };

  const handeChangePrescriptionDate = (date, dateString) => {
    setPrescriptionDate(dateString);
  };
  const handleRefresh = () => {
    setIsNew(false);
    setData([]);
    setPrescriptionId("");
    form.setFieldsValue({
      TransDate: moment(new Date(), "MM/DD/YYYY"),
      GenName: "",
      BrandName: "",
      Dosage: "",
      Form: "",
      Frequency: "",
      StartFrom: moment(new Date(), "MM/DD/YYYY"),
      StartTo: moment(new Date(), "MM/DD/YYYY"),
      Note: "",
      Qty: "",
    });
    setDataPrescriptions([]);
    setPrescriptionDate(null);
    setFollowUpDate(null);
    setRemarks("");
    setPharmacy("");
    handleGetPrescription();
  };
  const handleNew = () => {
    handleRefresh();
    setIsNew(true);
    setPrescriptionId("");
    setPrescriptionDate(moment(new Date(), "YYYY-MM-DD"));
    setShowModalAddMedicines(true);
  };
  const handleCloseAddMedicines = () => {
    setShowModalAddMedicines(false);
  };

  const handleEdit = () => {
    const arr = data.filter((item) => item.Id === Id);

    form.setFieldsValue({
      Description: arr[0].Description,

      Note: arr[0].Note,
    });
    setShowModalAddMedicines(true);
  };
  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => {
               
                setId(record.Id);
              }}
              trigger={["click"]}
              disabled={!isNew}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "Description",
    },

    {
      title: "Note",
      dataIndex: "Note",
    },
  ];

  const handleDeleteItem = () => {
    if (data.length <= 1) {
      return message.error("Request must have at least 1 procedure!");
    }

    confirm({
      title: "Do you want to delete this procedure?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        const arr = data.filter((item) => item.Id !== Id);
        setData(arr);
        if (prescriptionId !== "") {
          postData({
            endPoint: "deleteRequestDetails",
            valuestosave: {
              Id: Id,
              emailaddress: settings.UserName,
            },
          }).then((res) => {});
        }
      },
      onCancel() {},
    });
  };
  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit()}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleDeleteItem()}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleAddItem = async () => {
    const values = await form.validateFields();
    console.log(Id);
    const valuesToSave = {
      ...values,
      Id: Id === "" ? uuidv4() : Id,
    };
    form.setFieldsValue({
      Description: "",
      Note: "",
    });

    if (Id === "") {
      setData([...data, valuesToSave]);
    } else {
      const arr = data.filter((item) => item.Id === Id);

      data.forEach((element) => {
        if (element.Id === Id) {
          element.Description = values.Description;
          element.Note = values.Note;
          setShowModalAddMedicines(false);
        }
      });
    }

    if (prescriptionId !== "") {
      await postData({
        endPoint: "insertupdateDiagnostic",
        valuestosave: {
          ...values,
          Id: Id === "" ? "0" : Id,
          FkIdtblRequestParent: prescriptionId,
          emailaddress: settings.UserName,
        },
      }).then((res) => {
        if (res) {
        }
      });
      await getListOfRecords({
        sp: "diagnosticDetails",
        param1: prescriptionId,
        param2: "",
        param3: "",
        param4: "",
      }).then((res) => {
        setData(res);
      });
    }

    if (Id === "") {
      message.success("Request sucessfully added!");
    } else {
      message.success("Request sucessfully updated!");
    }
    setId("");
  };
  const handleSubmitForm = async () => {
    if (data.length <= 0) {
      return message.error("Please add procedure(s) to be requested!");
    } else if (prescriptionDate === "") {
      return message.error("Prescription date is required!");
    }

    const presDate = moment(new Date(prescriptionDate), "YYYY-MM-DD").format(
      "MM/DD/YYYY"
    );

    const fDate =
      followUpDate === null || followUpDate === undefined
        ? null
        : moment(new Date(followUpDate), "YYYY-MM-DD").format("MM/DD/YYYY");

    const valuestosave = {
      Id: prescriptionId,
      data: data,
      TransDate: presDate,
      emailaddress: settings.UserName,
      FkIdtblPharmacyDiagnostics: pharmacy,
      caseId: caseId,
      FollowUpDate: fDate,
      Remarks: remarks,
      patientId: patientId,
      doctorId: settings.userId,
    };

    const payLoad = {
      endPoint: "saverequest",
      valuestosave: valuestosave,
    };

    postData(payLoad).then((res) => {
      if (res.status === 1) {
        setPrescriptionId(res.param1);
        handleGetPrescription();
        message.success("Successfully saved!");
      } else {
        Modal.error({
          centered: true,
          title: settings.appName,
          content: res.message,
          onOk: () => {},
        });
      }
    });
  };

  const handleMenuDates = async (e) => {
    setPrescriptionId(e.key);

    let p = dataPrescriptions.filter((i) => i.requestId === e.key);

    setPrescriptionDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));
    if (p[0].FollowUpDate !== null) {
      setFollowUpDate(moment(new Date(p[0].FollowUpDate), "YYYY-MM-DD"));
    } else {
      setFollowUpDate(null);
    }
    setRemarks(p[0].Remarks);

    if (p[0].FkIdtblPharmacyDiagnostics.substr(0, 8) !== "00000000") {
      setPharmacy(p[0].FkIdtblPharmacyDiagnostics);
    } else {
      setPharmacy(null);
    }

    await getListOfRecords({
      sp: "requestDetails",
      param1: e.key,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setData(res);
    });
  };
  const handlePrint = async (w) => {
    setIsProcessing(true);
       setShowModalPrintWithSignature(false);
    downloadReport({
      reportName: "diagnostic_request.rpt",
      param1: prescriptionId,
      param2: false,
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: false,
      withSignature: w,
    }).then((res) => {
      setIsProcessing(false);
    });
  };

  const handleEmail = async () => {
    if (emailAddress === "") {
      return message.error("Patient's Email address is required!");
    }
    setIsProcessing(true)
    downloadReport({
      reportName: "diagnostic_request.rpt",
      param1: prescriptionId,
      param2: "",
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: true,
      emailAddress: emailAddress,
    }).then((res) => {
      message.success(
        "Diagnostic request successfully emailed to the patient!"
      );
      setIsProcessing(false);
    });
  };

  const handleEditPrescription = (e) => {
    setPrescriptionId(e);
    let p = dataPrescriptions.filter((i) => i.requestId === e);
    console.log(p);
    setPrescriptionDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));

    let fdate = moment(p[0].FollowUpDate, "YYYY-MM-DD");
    if (fdate.isValid()) {
      setFollowUpDate(moment(new Date(p[0].FollowUpDate), "YYYY-MM-DD"));
    } else {
      setFollowUpDate(null);
    }
    setRemarks(p[0].Remarks);
    setPharmacy(p[0].FkIdtblPharmacyDiagnostics);

    setIsNew(true);
  };

  const handleDeletePrescription = async (e) => {
    await confirm({
      title: "Do you want to delete this request?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deleteRequest",
          valuestosave: {
            Id: e,
            emailaddress: settings.UserName,
          },
        }).then((res) => {
          handleRefresh();
        });
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <Spin spinning={isProcessing} tip="Please wait...">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row>
              <Col span={24}>
                <Space>
                  <Button
                    disabled={isNew}
                    type="primary"
                    onClick={() => handleNew()}
                  >
                    New
                  </Button>
                  <Button type="primary" onClick={() => handleRefresh()}>
                    Refresh
                  </Button>
                </Space>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <Text strong>Requests</Text>
                <Menu
                  onClick={handleMenuDates}
                  defaultSelectedKeys={prescriptionId}
                  mode={"vertical"}
                  theme={"light"}
                >
                  {dataPrescriptions.map((value, index) => {
                    return (
                      <Menu.Item
                        onSelect={handleMenuDates}
                        key={value.requestId}
                      >
                        <div>
                          {value.TransDateStr + "    "}
                          <Space>
                            <Tooltip title="Edit">
                              <Button
                                onClick={(e) =>
                                  handleEditPrescription(e.target.value)
                                }
                                value={value.requestId}
                                icon={<EditOutlined />}
                              ></Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Button
                                onClick={(e) =>
                                  handleDeletePrescription(e.target.value)
                                }
                                value={value.requestId}
                                icon={<DeleteOutlined />}
                              ></Button>
                            </Tooltip>
                          </Space>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Card hoverable>
              <Row>
                <Col span={24}>Trans. Date</Col>
                <Col span={24}>
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    placeholder="Trans. Date"
                    value={prescriptionDate}
                    onChange={(e) => setPrescriptionDate(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>Request To:</Col>
                <Col span={24}>
                  <Select
                    value={pharmacy}
                    onChange={(e) => setPharmacy(e)}
                    allowClear
                    style={{ width: 300 }}
                  >
                    {pharmacies.map((d) => (
                      <Option key={d.Id}>{d.Name}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col span={24}>Follow-up Date:</Col>
                <Col span={24}>
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    placeholder="Follow-up Date"
                    value={followUpDate}
                    onChange={(e) => setFollowUpDate(e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>Remarks:</Col>
                <Col span={24}>
                  <Input
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                    style={{ width: 300 }}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                <Space>
                  <Button
                    onClick={() => {
                      setId("");
                      setShowModalAddMedicines(true);
                    }}
                    type="primary"
                    disabled={!isNew}
                  >
                    Add Procedure(s)
                  </Button>
                  <Button
                    disabled={!isNew}
                    onClick={() => handleSubmitForm()}
                    type="primary"
                  >
                    Save
                  </Button>
                  <Button
                    disabled={prescriptionId === ""}
                    type="primary"
                    onClick={() => setShowModalPrintWithSignature(true)}
                  >
                    Print
                  </Button>
                  <Button
                    disabled={prescriptionId === ""}
                    type="primary"
                    onClick={() => handleEmail()}
                  >
                    Email Diagnostic Request
                  </Button>
                </Space>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
                <Col span={24}>
                  <Table
                    rowKey={(record) => record.Id}
                    size="small"
                    columns={columns}
                    dataSource={data}
                    pagination={{ pageSize: 50 }}
                    scroll={{ y: 340 }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          visible={showModalAddMedicines}
          onOk={handleCloseAddMedicines}
          onCancel={handleCloseAddMedicines}
          width={800}
          footer={null}
        >
          <Form
            {...formItemLayout}
            form={form}
            onFinish={() => {
              handleSubmitForm();
            }}
          >
            <Row gutter={[8, 8]}>
              <Col span={20}>
                <Form.Item label="Description" name="Description">
                  <AutoComplete
                    value="Description"
                    options={diagnostics}
                    placeholder="Please select"
                    onChange={(e) => setBName(e)}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    rules={[
                      {
                        required: true,
                        message: "Required field!",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item label="Note" name="Note">
                  <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    onClick={() => handleAddItem()}
                    disabled={!isNew}
                    type="primary"
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>

      <Modal
        title="Print request with doctor's signature?"
        visible={showModalPrintWithSignature}
        onOk={() => handlePrint(true)}
        onCancel={() => handlePrint(false)}
        okText="Yes"
        cancelText="No"
      ></Modal>
    </div>
  );
};

export default DiagnosticRequest;
