import axios from "axios";
import appJson from "../assets/app.json";




export async function postData(payLoad) {
  let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/" + payLoad.endPoint;

  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.post(url, payLoad.valuestosave);
    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");
    // await alert(error.response.data.Message);

    return { status: 0, message: error.response.data.Message };
  }
}

export async function getListOfRecords(payLoad) {
 
 


  let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getlistofrecords";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        sp: payLoad.sp,
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
      },
    });

    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");

    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getRecord(payLoad) {
   let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getrecord";
  var token = localStorage.getItem("tokenid");

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        sp: payLoad.sp,
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
      },
    });
    return response.data;
  } catch (error) {
    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getuserdetailsbytokenid(tokenid) {
    let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getuserdetailsbytokenid";
  var token = localStorage.getItem("tokenid");

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        tokenid: tokenid,
      },
    });

    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenid");
    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getListOfSettings(code) {
    let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getlistofsettings";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        code: code,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");

    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getListOfHimsPatients(payLoad) {
   let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getlistofhimspatients";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        lastname: payLoad.lastname,
        firstname: payLoad.firstname,
        middlename: payLoad.middlename,
        patientno: payLoad.patientno,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");

    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getHimsPatientInfo(patientno) {
  let apiUrl = appJson.apiUrl;

  const url = apiUrl + "/api/gethimspatientinformation";
  var token = localStorage.getItem("tokenid");

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        patientno: patientno,
      },
    });

    return response.data;
  } catch (error) {
    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function downloadReport(payLoad) {
   let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/downloadreport";
  var token = localStorage.getItem("tokenid");

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    await axios({
      url: url, //your url
      method: "GET",
      responseType: "blob", // important
      params: {
        reportName: payLoad.reportName,
        param1: payLoad.param1,
        param2: payLoad.param2,
        param3: payLoad.param3,
        param4: payLoad.param4,
        isExcel: payLoad.isExcel,
        forEmail: payLoad.forEmail,
        emailAddress: payLoad.emailAddress,
        withSignature: payLoad.withSignature,
      },
    }).then((response) => {
      let newBlob;

      if (payLoad.isExcel === false) {
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });
      } else {
        newBlob = new Blob([response.data], {
          type: "application/excel",
        });
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);

      window.open(data, "_blank");
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  } catch (error) {
    console.log(error.response.request);
    return { Status: 0, Message: error.response.data.Message };
  }
}

export async function getPatientHimsResults(himsPatientNo) {
   let apiUrl = appJson.apiUrl;
  console.log(himsPatientNo);
  const url = apiUrl + "api/getPatientHimsResults";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        himsPatientNo: himsPatientNo.himsPatientNo,
      },
    });

    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");

    return { Status: 0, Message: error.response.data.Message };
  }
}


export async function getListOfRecords2(query) {
  let apiUrl = appJson.apiUrl;

  const url = apiUrl + "api/getlistofrecords2";
  var token = localStorage.getItem("tokenid");
  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(url, {
      params: {
        query: query,
      },
    });

    return response.data;
  } catch (error) {
    localStorage.removeItem("tokenId");

    return { Status: 0, Message: error.response.data.Message };
  }
}


