import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../../settingsContext.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getListOfRecords, postData, downloadReport,getRecord } from "../DataService.js";
import {
  Row,
  Col,
  Button,
  Space,
  Form,
  DatePicker,
  AutoComplete,
  Input,
  message,
  Menu,
  Tooltip,
  Modal,
  Spin,
  Checkbox,
  Table,
} from "antd";
import {
  PlusOutlined,
  ClearOutlined,
  SaveOutlined,
  PrinterOutlined,
  MailOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
const { TextArea } = Input;
const { confirm } = Modal;
const ReferralForm = (props) => {
  const { settings } = useContext(SettingsContext);
  const [patientId] = useState(props.patientId);
  const [caseId] = useState(props.caseId);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [toDr, setToDr] = useState([]);
  const [Id, setId] = useState("");
  const [dataDates, setDataDates] = useState([]);  
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModalTemplate,setShowModalTemplate] = useState(false)
  const [dataTemplate,setDataTemplate] = useState([])
  const [template,setTemplate] = useState("")
  const [filteredTemplate,setFilteredTemplate] = useState([])
  const [transDate, setTransDate] = useState(
    moment(new window.Date(), "MM/DD/YYYY")
  );

  useEffect(() => {
   
    getListOfRecords({
      sp: "referralToDr",
      param1: settings.userId,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
       
        setToDr(res);
      }
    });
    handleGetDates();

 handleGetTemplate();

  }, []);

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 6,
        },
        sm: {
          span: 6,
        },md: {
          span: 6,
        }
      },
      wrapperCol: {
        xs: {
          span: 18,
        },
        sm: {
          span: 18,
        },
        md: {
          span: 18,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 18,
          offset: 6,
        },
        sm: {
          span: 18,
          offset: 6,
        },
      },
    };
  const columns2 = [
    {
      title: "",
      dataIndex: "operation",
      width: 100,
      render: (text, record) =>
        dataTemplate.length >= 1 ? (
          <Button type="link" onClick={() => handleSelect(record.Id)}>
            {<CheckOutlined />}
            Select
          </Button>
        ) : null,
    },
    {
      title: "Template Name",
      dataIndex: "TemplateName",
      width: "100px",
    },
    {
      title: "Template",
      dataIndex: "Template",
    },
  ];
  const handleChangeTransDate = (date, dateString) => {
    setTransDate(dateString);
  };
  const handleNew = () => {

 

    form.setFieldsValue({
      TransDate: moment(new window.Date(), "MM/DD/YYYY"),
      ToDr: "",
      Reason: "",
      Diagnosis: "",
      Remarks: "",
      PatientEmailAddress: props.emailAddress,
    });
    setTransDate(moment(new window.Date(), "MM/DD/YYYY"));
    setId("");
    setIsNew(true);
  };
  const handleRefresh = () => {
    form.setFieldsValue({
      TransDate: moment(new window.Date(), "YYYY-MM-DD"),
      ToDr: null,
      Reason: null,
      EmailAddress:null,
      Template:false,
      TemplateName:null
    });
    setTransDate(moment(new window.Date(), "MM/DD/YYYY"));
    setId("")
    setIsNew(false);
    handleGetDates();
  };
  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
     


      if (values.Template===true && !values.TemplateName){

        return message.error("Please encode template name!");
      }

      await postData({
        endPoint: "saveReferral",
        valuestosave: {
          Id: Id,
          TransDate: transDate,
          Reason: values.Reason,
          emailaddress: settings.UserName,
          FkIdtblUsers: settings.userId,
          FkIdPatientInformation: patientId,
          ToDr: values.ToDr,
          EmailAddress :values.EmailAddress,
          Template:values.Template,
          TemplateName:values.TemplateName

        },
      }).then((res) => {
      
        message.success("Referral successfully saved!");
        setId(res.param1);
        console.log(Id)
      });
      handleGetDates();
    } catch (error) {
      message.error(error);
    }
  };
  const handlePrint = () => {
    setIsProcessing(true)
    downloadReport({
      reportName: "referral_form.rpt",
      param1: Id,
      param2: false,
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: false,
    }).then((res) => {  setIsProcessing(false);});
  };
  const handleEmail =async () => {

 const values = await form.validateFields();
 if (values.EmailAddress === "") {
   return message.error("Doctor's Email address is required!");
 }
 setIsProcessing(true);
 downloadReport({
   reportName: "referral_form.rpt",
   param1: Id,
   param2: false,
   param3: caseId,
   param4: "",
   isExcel: false,
   forEmail: true,
   emailAddress: values.EmailAddress,
 }).then((res) => {
   message.success("Diagnostic request successfully emailed to the doctor!");
   setIsProcessing(false);
 });





  };
    const handleEmailToPatient = async () => {
      const values = await form.validateFields();
      if (values.PatientEmailAddress === "") {
        return message.error("Patient's Email address is required!");
      }
      setIsProcessing(true);
      downloadReport({
        reportName: "referral_form.rpt",
        param1: Id,
        param2: false,
        param3: caseId,
        param4: "",
        isExcel: false,
        forEmail: true,
        emailAddress: values.PatientEmailAddress,
      }).then((res) => {
        message.success(
          "Diagnostic request successfully emailed to the patient!"
        );
        setIsProcessing(false);
      });
    };
  const handleGetDates = () => {
     
    getListOfRecords({
      sp: "udp_GetReferralPerPatient",
      param1: patientId,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setDataDates(res);
    });
  
  };
  const handleMenuDates = async (e) => {
    setId(e.key);
    let p = dataDates.filter((i) => i.Id === e.key);

    setTransDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));

    form.setFieldsValue({
      TransDate: moment(new Date(p[0].TransDate), "YYYY-MM-DD"),
      ToDr: p[0].ToDr,
      Reason: p[0].Reason,
      EmailAddress: p[0].EmailAddress,
      Template: false,
      TemplateName:"",
      PatientEmailAddress: props.emailAddress,
    });
    setIsNew(true);
  };
  const handleDelete = async (e) => {
    await confirm({
      title: "Do you want to delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deleteReferral",
          valuestosave: {
            Id: e,
            emailaddress: settings.UserName,
          },
        }).then((res) => {
          handleRefresh();
        });
      },
      onCancel() {},
    });
  };
  const handleChangeToDr=async (value)=>
  {
      console.log(value)
      if (value!==null)
      {
           await getRecord({
             sp: "getDrEmailAddress",
             param1:value
           }).then((res) => {
            
            if (res)
            {
   form.setFieldsValue({
     EmailAddress: res.EmailAddress,
   }); 
            }
          


           });
           
      }
  }
  const handleCloseTemplate =()=>
  {
    setShowModalTemplate(false)
  }
  const handleSelect=(id)=>
  {
      console.log(id)
    const t = dataTemplate.filter(f=>f.Id ===id);

        form.setFieldsValue({
      
      Reason: t[0].Template
    
    });

  setShowModalTemplate(false)  

  }
  const handleShowTemplate=()=>
  {
    handleGetTemplate();
    setShowModalTemplate(true)
  }
  const handleGetTemplate = async () => {
    await getListOfRecords({
      sp: "udp_GetReferralTemplate",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setDataTemplate(res);
        setFilteredTemplate(res);
      }
    });
  };
  const handleSearchTemplate = (value)=>
  {
    console.log(value)

   const x= dataTemplate.filter(f=>f.TemplateName.toLowerCase().includes(value))
  
  setFilteredTemplate(x)


  }
  return (
    <div>
      <Spin spinning={isProcessing} tip="Please wait...">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Row>
              <Col span={24}>
                <Space>
                  <Button
                    disabled={isNew}
                    type="primary"
                    onClick={() => handleNew()}
                  >
                     New
                  </Button>
                  <Button type="primary" onClick={() => handleRefresh()}>
                     Refresh
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Menu
                onClick={handleMenuDates}
                defaultSelectedKeys={Id}
                mode={"vertical"}
                theme={"light"}
              >
                {dataDates.map((value, index) => {
                  return (
                    <Menu.Item onSelect={handleMenuDates} key={value.Id}>
                      <div>
                        {value.TransDateStr + "    "}

                        <Tooltip title="Delete">
                          <Button
                            onClick={(e) => handleDelete(e.target.value)}
                            value={value.Id}
                            icon={<DeleteOutlined />}
                          ></Button>
                        </Tooltip>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={() => {
                handleSubmitForm();
              }}
            >
              <Form.Item label="Date" name="TransDate">
                <DatePicker
                  format={"MM/DD/YYYY"}
                  onChange={handleChangeTransDate}
                />
              </Form.Item>
              <Form.Item
                label="To"
                name="ToDr"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <AutoComplete
                  value="ToDr"
                  options={toDr}
                  placeholder="Please select"
                  onChange={(value) => handleChangeToDr(value)}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>

              <Form.Item
                label="Doctor's Email Address"
                name="EmailAddress"
                rules={[
                  {
                    required: false,
                    message: "Required field!",
                  },
                  {
                    max: 100,
                    message: "Maximum characters for email address is 100!",
                  },
                  { type: "email", message: "Invalid email address!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Patient's Email Address"
                name="PatientEmailAddress"
                rules={[
                  {
                    required: false,
                    message: "Required field!",
                  },
                  {
                    max: 100,
                    message: "Maximum characters for email address is 100!",
                  },
                  { type: "email", message: "Invalid email address!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  onClick={() => handleShowTemplate()}
                  disabled={!isNew}
                  type="primary"
                >
                  <FontAwesomeIcon icon={faNotesMedical} />
                  Templates
                </Button>
              </Form.Item>

              <Form.Item
                label="Remarks"
                name="Reason"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
              </Form.Item>
              <Form.Item
                {...tailFormItemLayout}
                name="Template"
                valuePropName="checked"
              >
                <Checkbox>Save as a template</Checkbox>
              </Form.Item>

              <Form.Item
                label="Template Name"
                name="TemplateName"
                rules={[
                  {
                    max: 100,
                    message: "Maximum characters 100!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Space>
                  <Button
                    onClick={() => handleSubmitForm()}
                    disabled={!isNew}
                    type="primary"
                  >
                    <SaveOutlined />
                    Save
                  </Button>

                  <Button
                    disabled={Id === ""}
                    type="primary"
                    onClick={() => handlePrint()}
                  >
                    <PrinterOutlined /> Print
                  </Button>
                  <Button
                    disabled={Id === ""}
                    type="primary"
                    onClick={() => handleEmail()}
                  >
                    <MailOutlined /> Email Referral To Dr
                  </Button>

                  <Button
                    disabled={Id === ""}
                    type="primary"
                    onClick={() => handleEmailToPatient()}
                  >
                    <MailOutlined /> Email Referral To Patient
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>

      <Modal
        visible={showModalTemplate}
        onOk={handleCloseTemplate}
        onCancel={handleCloseTemplate}
        width={800}
        footer={null}
      >
        <div style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search Template"
                onChange={(e) => handleSearchTemplate(e.target.value)}
            //  onPressEnter={(e) => handleSearch(e)}
            style={{ width: 250 }}
          />
        </div>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns2}
            dataSource={filteredTemplate}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 340 }}
          />
        </Row>
      </Modal>
    </div>
  );
};

export default ReferralForm;
