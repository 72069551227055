import React, { useState, useCallback, useEffect, useContext } from "react";
import { getListOfRecords } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Space,
  Input,
  Spin,
  Popover,
  Menu,
  Dropdown,
} from "antd";
import {
  UserAddOutlined,
  SelectOutlined,
  SearchOutlined,
  EditOutlined,
  CreditCardOutlined,
  ClockCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const ClinicPatients = (props) => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { settings, updateSettings } = useContext(SettingsContext);
  const AccessToHimsPatients = settings.AccessToHimsPatients;
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [patientid, setPatientId] = useState("");
  const [Id, setId] = useState("");
  const handleNew = () => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        Id: "",
        himsPatientNo: "",
        returnUrl: "/clinicpatients",
      },
    });
  };

  const handleAppointment = (x) => {
    props.history.push({
      pathname: "/appointmentdetails",
      state: {
        patientId: x,
        Id: "",
        returnUrl: "/clinicpatients",
      },
    });
  };
  const fetchData = () => {
    const payLoad = {
      sp: "udp_GetListOfPatients",
      param1: lastname,
      param2: firstname,
      param3: middlename,
      param4: patientid,
    };

    getListOfRecords(payLoad).then((res) => {
      if (res)

{
 setData(res);
}     

    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit(Id)}>
        Edit
      </Menu.Item>
      <Menu.Item
        icon={<ClockCircleOutlined />}
        onClick={() => handleAppointment(Id)}
      >
        Set an appointment
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      dataIndex: "operation",
    
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => setId(record.Id)}
              trigger={["click"]}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
      {
      title: "Patient ID",
      dataIndex: "PatientId",
    
    },
    {
      title: "Name",
      dataIndex: "Name",
     
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Address",
      dataIndex: "Address",
     
    },
    {
      title: "Sex",
      dataIndex: "Sex",
     
    },
    {
      title: "Dob",
      dataIndex: "dobstr",
     
    },
  ];
  const handleEdit = (x) => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        himsPatientNo: "",
        Id: x,
        returnUrl: "/clinicpatients",
      },
    });
  };

 /*  useEffect(() => {
    if (!AccessToHimsPatients) {
      props.history.push("/accessdenied");
    }
  }, [AccessToHimsPatients]); */

  const handleSearch = () => {
    fetchData();
  };



      const handleTemplateImages = () => {
console.log(settings.userId)
        props.history.push({
          pathname: "/imagetemplate",
          state: {
            doctorId: settings.userId,
          },
        });
      };

  
  return (
    <div>
      <Spin spinning={reload} tip="Loading...">
        <h1>List of Patients</h1>
       {/*  <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => handleTemplateImages()}
        >
          Template Images
        </Button> */}
        <Card hoverable="true">
          <Row gutter={[16, 16]}>
            <Col>
              <Space>
                <Button
                  type="primary"
                  icon={<UserAddOutlined />}
                  onClick={() => handleNew()}
                >
                  Create new patient
                </Button>
                <Input
                  placeholder="Lastname"
                  onChange={(e) => setLastname(e.target.value)}
                  //  onPressEnter={(e) => handleSearch(e)}
                  style={{ width: 150 }}
                />
                <Input
                  placeholder="Firstname"
                  onChange={(e) => setFirstname(e.target.value)}
                  style={{ width: 150 }}
                />
                <Input
                  placeholder="Middlename"
                  onChange={(e) => setMiddlename(e.target.value)}
                  style={{ width: 150 }}
                />
                <Input
                  placeholder="Patient Id."
                  onChange={(e) => setPatientId(e.target.value)}
                  style={{ width: 150 }}
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Table
              rowKey={(record) => record.Id}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 50 }}
            />
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default withRouter(ClinicPatients);
