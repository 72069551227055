import React, { useState, useContext } from "react";
import "./users.css";
import { withRouter } from "react-router-dom";
import { postData, getListOfRecords } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import appJson from "../../assets/app.json";
import {
  Row,
  Col,
  Input,
  Form,
  Card,
  Button,
  Space,
  Typography,
  Modal,
} from "antd";
import { LoginOutlined } from "@ant-design/icons";

const { Title } = Typography;
const LogIn = (props) => {
  const { settings, updateSettings } = useContext(SettingsContext);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleSubmit = async () => {
    setIsSaving(true);
    const values = form.getFieldsValue();
    console.log(values)
    const payLoad = {
      endPoint: "login",
      valuestosave: values,
    };


     let apiUrl = appJson.apiUrl;

/*
    await fetch("/app.json")
      .then((r) => r.json())
      .then((data) => {
        console.log(data)
        apiUrl = data.apiUrl;
      }); */

    await postData(payLoad).then((res) => {
      if (res.stat.status === 1) {
        localStorage.setItem("tokenid", res.userDetails.tokenid);
        var ddate = new Date();
        updateSettings({
          ...settings,
          UserName: res.userDetails.EmailAddress,
          fullName: res.userDetails.Name,
          profilePic:
            apiUrl +
            "api/getprofilepicture?id=" +
            res.userDetails.Id +
            "&date=" +
            ddate,
          userId: res.userDetails.Id,
          isLogged: true,
          isAdministrator: res.userDetails.isAdministrator,
          AccessToHimsPatients: res.userDetails.AccessToHimsPatients,
          isDoctor: res.userDetails.isDoctor,
          isHospitalAdmin: res.userDetails.isHospitalAdmin,
        });

        props.history.push("/");
      } else {
        setIsSaving(false);
        Modal.error({
          centered: true,
          title: settings.appName,
          content: res.stat.message,
          onOk: () => {
            setIsSaving(false);
          },
        });
      }
    });
  };

  return (
    <div className="container">
      <div style={{ marginTop: 150 }}>
        <Row>
          <Col md={8}></Col>
          <Col md={8}>
            <Card
              hoverable={true}
              style={{ width: "100%" }}
              title={<Title level={4}>Sign in to your account</Title>}
            >
              <Form
                {...formItemLayout}
                form={form}
                onFinish={() => {
                  handleSubmit();
                }}
              >
                <Form.Item
                  label="Email Address"
                  name="Username"
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                    { type: "email", message: "Invalid email address!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="Password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <Space>
                    <Button
                      type="primary"
                      icon={<LoginOutlined />}
                      //  disabled={isSaving}
                      loading={isSaving}
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(LogIn);
