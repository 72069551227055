import React, { useState, useEffect, useContext, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import { SettingsContext } from "../../settingsContext.js";
import appJson from "../../assets/app.json";
import { getListOfRecords, postData, downloadReport } from "../DataService.js";
import {
  Space,
  Col,
  Button,
  Row,
  Form,
  DatePicker,
  Input,
  message,
  Modal,
  Table,
  Menu,
  Tooltip,Spin
} from "antd";
import {
  PlusOutlined,
  ClearOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  SearchOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { confirm } = Modal;
const MedicalCertificate = (props) => {
  const [isNew, setIsNew] = useState(false);
      const [isProcessing, setIsProcessing] = useState(false);
  const [form] = Form.useForm();
  const [TransDate, setTransDate] = useState("");
  const [DateFrom, setDateFrom] = useState("");
  const [DateTo, setDateTo] = useState("");
  const [patientId] = useState(props.patientId);
  const { settings } = useContext(SettingsContext);
  const [Id, setId] = useState("");
  const [showModalDiagnosis, setShowModalDiagnosis] = useState(false);
  const [diagnosis, setDiagnosis] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [caseId, setCaseId] = useState(props.caseId);
  const [dataDates, setDataDates] = useState([]);
  const [emailAddress] = useState(props.emailAddress);
  const [
    showModalPrintWithSignature,
    setShowModalPrintWithSignature,
  ] = useState(false);
  const handleGetDates = useCallback(() => {
    getListOfRecords({
      sp: "udp_GetMedicalCertificatesPerPatient",
      param1: patientId,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setDataDates(res);
    });
  });
  useEffect(() => {
    handleGetDates();
  }, [handleGetDates]);

  const handleNew = () => {
    form.setFieldsValue({
      Date: moment(new window.Date(), "MM/DD/YYYY"),
      DateFrom: moment(new window.Date(), "MM/DD/YYYY"),
      DateTo: moment(new window.Date(), "MM/DD/YYYY"),
      Diagnosis: localStorage.getItem("Diagnosis"),
      Remarks: "",
    });
    setTransDate(moment(new window.Date(), "MM/DD/YYYY"));
    setDateFrom(moment(new window.Date(), "MM/DD/YYYY"));
    setDateTo(moment(new window.Date(), "MM/DD/YYYY"));
    setIsNew(true);
  };
  const handleRefresh = () => {
    confirm({
      title: "Refresh fields now?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        handleResetFields();
      },
      onCancel() {},
    });
  };

  const handleResetFields = () => {
    form.setFieldsValue({
      Date: moment(new window.Date(), "MM/DD/YYYY"),
      DateFrom: moment(new window.Date(), "MM/DD/YYYY"),
      DateTo: moment(new window.Date(), "MM/DD/YYYY"),
      Diagnosis: "",
      Remarks: "",
    });
    setTransDate(moment(new window.Date(), "MM/DD/YYYY"));
    setDateFrom(moment(new window.Date(), "MM/DD/YYYY"));
    setDateTo(moment(new window.Date(), "MM/DD/YYYY"));
    setId("");
    setIsNew(false);
    handleGetDates();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 6,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 18,
      },
      sm: {
        span: 18,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 18,
        offset: 6,
      },
      sm: {
        span: 18,
        offset: 6,
      },
    },
  };

  const handleChangeTransDate = (date, dateString) => {
    setTransDate(dateString);
  };

  const handleChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString);
  };

  const handleChangeDateTo = (date, dateString) => {
    setDateTo(dateString);
  };

  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      await postData({
        endPoint: "saveMedicalCertificate",
        valuestosave: {
          Id: Id,
          TransDate: TransDate,
          DateFrom: DateFrom,
          DateTo: DateTo,
          Diagnosis: values.Diagnosis,
          Remarks: values.Remarks,
          emailaddress: settings.UserName,
          FkIdtblUsers: settings.userId,
          FkIdPatientInformation: patientId,
        },
      }).then((res) => {
        message.success("Medical Certificate successfully saved!");
        setId(res.param1);
      });
      handleGetDates();
    } catch (error) {
      message.error(error);
    }
  };

  const handleCloseDiagnosis = () => {
    setShowModalDiagnosis(false);
  };

  const handleShowDiagnosis = async () => {
    await getListOfRecords({
      sp: "udp_GetDiagnosisPerPatient",
      param1: patientId,
    }).then((res) => {
      setDiagnosis(res);
    });
    setShowModalDiagnosis(true);
  };
  const columns = [
    {
      title: "ICD Code",
      dataIndex: "IcdCode",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleOk = () => {
    if (selectedRows.length > 0) {
      let Diagnosis = "";
      selectedRows.forEach((element) => {
        if (Diagnosis === "") {
          Diagnosis = element.Description;
        } else {
          Diagnosis = Diagnosis + "\n" + element.Description;
        }
      });
      form.setFieldsValue({
        Diagnosis: Diagnosis,
      });
    }
    setShowModalDiagnosis(false);
  };

  const handlePrint = async (w) => {
     setShowModalPrintWithSignature(false);
    setIsProcessing(true)
    downloadReport({
      reportName: "medical_certificate.rpt",
      param1: Id,
      param2: false,
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: false,
      withSignature: w,
    }).then((res) => {
      setIsProcessing(false);
    });
  };
  const handleMenuDates = async (e) => {
    setId(e.key);
    let p = dataDates.filter((i) => i.Id === e.key);

    setTransDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));
    if (p[0].DateFrom !== null) {
      setDateFrom(moment(new Date(p[0].DateFrom), "YYYY-MM-DD"));
    } else {
      setDateFrom(null);
    }

    if (p[0].DateTo !== null) {
      setDateTo(moment(new Date(p[0].DateTo), "YYYY-MM-DD"));
    } else {
      setDateTo(null);
    }

    form.setFieldsValue({
      Date: moment(new Date(p[0].TransDate), "YYYY-MM-DD"),
      DateFrom: moment(new Date(p[0].DateFrom), "YYYY-MM-DD"),
      DateTo: moment(new Date(p[0].DateTo), "YYYY-MM-DD"),
      Diagnosis: p[0].Diagnosis,
      Remarks: p[0].Remarks,
    });
    setIsNew(true);
  };

  const handleEmail = async () => {
    if (emailAddress === "") {
      return message.error("Patient's Email address is required!");
    }
  setIsProcessing(true);
    downloadReport({
      reportName: "medical_certificate.rpt",
      param1: Id,
      param2: "",
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: true,
      emailAddress: emailAddress,
    }).then((res) => {
      message.success(
        "Medical certificate successfully emailed to the patient!"
      ); setIsProcessing(false);
    });
  };

  const handleDelete = async (e) => {
    console.log(e);
    await confirm({
      title: "Do you want to delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deleteMedicalCertificate",
          valuestosave: {
            Id: e,
            emailaddress: settings.UserName,
          },
        }).then((res) => {
          handleResetFields();
        });
      },
      onCancel() {},
    });
  };
  return (
    <div>
      <Spin spinning={isProcessing} tip="Please wait...">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row>
              <Col span={24}>
                <Space>
                  <Button
                    disabled={isNew}
                    type="primary"
                    onClick={() => handleNew()}
                  >
                    New
                  </Button>
                  <Button type="primary" onClick={() => handleRefresh()}>
                    Refresh
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Menu
                onClick={handleMenuDates}
                defaultSelectedKeys={Id}
                mode={"vertical"}
                theme={"light"}
              >
                {dataDates.map((value, index) => {
                  return (
                    <Menu.Item onSelect={handleMenuDates} key={value.Id}>
                      <div>
                        {value.TransDateStr + "    "}

                        <Tooltip title="Delete">
                          <Button
                            onClick={(e) => handleDelete(e.target.value)}
                            value={value.Id}
                            icon={<DeleteOutlined />}
                          ></Button>
                        </Tooltip>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={() => {
                handleSubmitForm();
              }}
            >
              <Form.Item label="Date" name="Date">
                <DatePicker
                  format={"MM/DD/YYYY"}
                  onChange={handleChangeTransDate}
                />
              </Form.Item>
              <Form.Item label="Date From" name="DateFrom">
                <DatePicker
                  format={"MM/DD/YYYY"}
                  onChange={handleChangeDateFrom}
                />
              </Form.Item>
              <Form.Item label="Date To" name="DateTo">
                <DatePicker
                  format={"MM/DD/YYYY"}
                  onChange={handleChangeDateTo}
                />
              </Form.Item>
              <Form.Item
                label="Diagnosis"
                name="Diagnosis"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                  {
                    max: 500,
                    message: "Maximum characters 500!",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  disabled={!isNew}
                  type="primary"
                  onClick={() => handleShowDiagnosis()}
                >
                  <SearchOutlined /> Search Diagnosis
                </Button>
              </Form.Item>
              <Form.Item
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: false,
                    message: "Required field!",
                  },
                  {
                    max: 500,
                    message: "Maximum characters 500!",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Space>
                  <Button
                    onClick={() => handleSubmitForm()}
                    disabled={!isNew}
                    type="primary"
                  >
                    <SaveOutlined />
                    Save
                  </Button>

                  <Button
                    disabled={Id === ""}
                    type="primary"
                    onClick={() => setShowModalPrintWithSignature(true)}
                  >
                    <PrinterOutlined /> Print
                  </Button>
                  <Button
                    disabled={Id === ""}
                    type="primary"
                    onClick={() => handleEmail()}
                  >
                    <MailOutlined /> Email Medical Certificate
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Modal
          visible={showModalDiagnosis}
          onOk={handleOk}
          onCancel={handleCloseDiagnosis}
          width={800}
        >
          <div style={{ marginTop: 30 }}>
            <Row gutter={[16, 16]}>
              <Table
                rowKey={(record) => record.Id}
                size="small"
                columns={columns}
                dataSource={diagnosis}
                pagination={false}
                scroll={{ y: 540 }}
                rowSelection={{
                  type: "check",
                  ...rowSelection,
                }}
              />
            </Row>
          </div>
        </Modal>
      </Spin>
      <Modal
        title="Print medical certificate with doctor's signature?"
        visible={showModalPrintWithSignature}
        onOk={() => handlePrint(true)}
        onCancel={() => handlePrint(false)}
        okText="Yes"
        cancelText="No"
      ></Modal>
    </div>
  );
};

export default MedicalCertificate;
