import React, { useState, useEffect, useContext } from "react";
   import { SettingsContext } from "../../settingsContext.js";
   import { postData, getListOfRecords } from "../DataService.js";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  message,
  Table,
  Menu,
  Dropdown,
  Select,
  DatePicker,
} from "antd";
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { confirm } = Modal;
const PaymentStatus = (props) => {
  const [form] = Form.useForm();
  const [patientId, setPatientId] = useState(props.patientId);
  const [caseId, setCaseId] = useState(props.caseId);
  const [showModal, setShowModal] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [data, setData] = useState([]);
  const [Id, setId] = useState(0);
  const [record, setRecord] = useState([]);

  const handleOk = () => {
    setShowModal(false);
  };
  useEffect(() => {
    /*     setPatientId(props.patientId);
    setCaseId(props.caseId); */
    handleGetPaymentStatus();
  }, [props.patientId, patientId]);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleShowModal = () => {
    setId(0);
    form.setFieldsValue({
      TransDate: null,
      PaymentStatus: null,
      PaymentPlan: null,
      AmountPaid: 0,
      ReferenceNo: "",
    });
    setShowModal(true);
  };
  const handleSubmitForm = async () => {
    const values = await form.validateFields();
   

    const valuestosave = {
      ...values,
      emailaddress: settings.UserName,
      FkIdtblAppointments: caseId,
      Id: Id,
 
    };
    const payLoad = {
      endPoint: "savePaymentStatus",
      valuestosave: valuestosave,
    };
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
          handleGetPaymentStatus();
          setShowModal(false);
        } else {
          message.error(res.message);
        }
      }
    });
  };
  const handleEditVitals = () => {
    console.log(record);
    setId(record.Id);
    form.setFieldsValue({
      Weight: record.Weight,
      Height: record.Height,
      Bmi: record.Bmi,
      Systolic: record.Systolic,
      Diastolic: record.Diastolic,
      OxygenSaturation: record.OxygenSaturation,
      Rr: record.Rr,
      Hr: record.Hr,
      BodyTemperature: record.BodyTemperature,
      CapillaryBloodGlucose: record.CapillaryBloodGlucose,
    });
    setShowModal(true);
  };
  const handleDelete = async () => {
    await confirm({
      title: "Are you sure delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const valuestosave = {
          Id: record.Id,
          emailaddress: settings.UserName,
        };
        const payLoad = {
          endPoint: "deletevitalsigns",
          valuestosave: valuestosave,
        };

        postData(payLoad).then((res) => {
          if (res) {
            if (res.status === 1) {
              message.success("Sucessfully deleted!");
              handleGetPaymentStatus();
              setId(0);
            } else {
              message.error(res.message);
            }
          }
        });
      },
      onCancel() {
        setId(0);
      },
    });
  };
  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEditVitals()}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleDelete()}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
   /*  {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => setRecord(record)}
              trigger={["click"]}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    }, */
    {
      title: "Trans Date",
      dataIndex: "TransDateStr",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
    },
    {
      title: "Amount Paid",
      dataIndex: "AmountPaid",
    },
    {
      title: "Reference No.",
      dataIndex: "ReferenceNo",
    },
    {
      title: "Added By",
      dataIndex: "AddedBy",
    },
    {
      title: "Date Added",
      dataIndex: "DateAdded",
    },
    {
      title: "Updated By",
      dataIndex: "UpdatedBy",
    },
    {
      title: "Date Updated",
      dataIndex: "DateUpdated",
    },
  ];

  const handleGetPaymentStatus = async () => {
    const payLoad = {
      sp: "udp_GetPaymentStatus",
      param1: caseId,
      param2: "",
      param3: "",
      param4: "",
    };

    await getListOfRecords(payLoad).then((res) => {
      if (res) {
        console.log(res);
        setData(res);
      }
    });
  };
  return (
    <div>
      <h1>Payment Transactions</h1>

      <Card hoverable="true">
        <Row gutter={[16, 16]}>
          <Col>
            <Button type="primary" onClick={() => handleShowModal()}>
              <FileAddOutlined />
              Add
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 340 }}
          /> 
        </Row>
      </Card>
      <Modal
        title="Add Payment Status"
        visible={showModal}
        onOk={handleSubmitForm}
        onCancel={handleOk}
        width={800}
        okText="Save"
      >
        <Form
          {...formItemLayout}
          form={form}
          onFinish={() => {
            handleSubmitForm();
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Date" name="TransDate">
                <DatePicker
                  format={"MM/DD/YYYY"}
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Payment Mode"
                name="PaymentPlan"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <Select allowClear>
                  <Option value="CASH">Cash</Option>
                  <Option value="GCASH">G-Cash</Option>
                  <Option value="CREDITCARD">CreditCard</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Payment Status"
                name="PaymentStatus"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <Select allowClear>
                  <Option value="PENDING">Pending</Option>
                  <Option value="PAID">Paid</Option>
                  <Option value="CANCELLED">Cancelled</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Reference No"
                name="ReferenceNo"
                rules={[
                  {
                    required: false,
                    message: "Required field!",
                  },
                  {
                    max: 20,
                    message: "Maximum characters 20!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Amount"
                name="AmountPaid"
                rules={[
                  {
                    required: false,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
 
export default PaymentStatus;