import React, { useEffect } from "react";

import io from "socket.io-client";

const VideoCon = () => {
  const localVideoref = React.useRef();
  const remoteVideoref = React.useRef();
  const textref = React.useRef();
  let pc = new RTCPeerConnection(null);
  useEffect(() => {
    pc.onicecandidate = (e) => {
      if (e.candidate) console.log(JSON.stringify(e.candidate));
    };
    pc.oniceconnectionstatechange = (e) => {
      console.log(e);
    };

    pc.onaddstream = (e) => {
      remoteVideoref.current.srcObject = e.stream;
    };
    const constraints = {
      audio: false,
      video: true,
      // video: {
      //   width: 1280,
      //   height: 720
      // },
      // video: {
      //   width: { min: 1280 },
      // }
    };
    const pc_config = {
      iceServers: [
        // {
        //   urls: 'stun:[STUN_IP]:[PORT]',
        //   'credentials': '[YOR CREDENTIALS]',
        //   'username': '[USERNAME]'
        // },
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    };
    const success = (stream) => {
      localVideoref.current.srcObject = stream;
      pc.addStream(stream);
    };
    const failure = (e) => {
      console.log("getUserMedia Error: ", e);
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(success)
      .catch(failure);
  }, []);

  const createOffer = () => {
    console.log("Offer");

    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createOffer
    // initiates the creation of SDP
    pc.createOffer({ offerToReceiveVideo: 1 }).then((sdp) => {
      console.log(JSON.stringify(sdp));

      // set offer sdp as local description
      pc.setLocalDescription(sdp);

      //sendToPeer("offerOrAnswer", sdp);
    });
  };

  const setRemoteDescription = () => {
    // retrieve and parse the SDP copied from the remote peer

    console.log(textref.current.value);
    const desc = JSON.parse(textref.current.value);

    // set sdp as remote description
    pc.setRemoteDescription(new RTCSessionDescription(desc));
  };

  /*   const  sendToPeer = (messageType, payload) => {
      this.socket.emit(messageType, {
        socketID: this.socket.id,
        payload,
      });
    };
 */

  const createAnswer = () => {
    console.log("Answer");
    pc.createAnswer({ offerToReceiveVideo: 1 }).then((sdp) => {
      console.log(JSON.stringify(sdp));

      // set answer sdp as local description
      pc.setLocalDescription(sdp);

      // sendToPeer("offerOrAnswer", sdp);
    });
  };

  const addCandidate = () => {
    // retrieve and parse the Candidate copied from the remote peer
    const candidate = JSON.parse(textref.current.value);
    console.log("Adding candidate:", candidate);

    // add the candidate to the peer connection
    pc.addIceCandidate(new RTCIceCandidate(candidate));
  };

  return (
    <div>
      <video
        style={{
          width: 240,
          height: 240,
          margin: 5,
          backgroundColor: "black",
        }}
        ref={localVideoref}
        autoPlay
      ></video>
      <video
        style={{
          width: 240,
          height: 240,
          margin: 5,
          backgroundColor: "black",
        }}
        ref={remoteVideoref}
        autoPlay
      ></video>
      <textarea style={{ width: 450, height: 40 }} ref={textref} />
      <button onClick={() => createOffer()}>Offer</button>
      <button onClick={createAnswer}>Answer</button>
      <button onClick={setRemoteDescription}>Set Remote Desc</button>
      <button onClick={addCandidate}>Add Candidate</button>
    </div>
  );
};

export default VideoCon;
