import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { SettingsContext } from "../../settingsContext.js";
import appJson from "../../assets/app.json";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  getRecord,
  getListOfSettings,
  postData,
  getListOfRecords,
} from "../DataService.js";

import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Checkbox,
  Tabs,
  message,
  AutoComplete,
  Breadcrumb,
  Avatar,
  Select,
  TimePicker,
  Table,
  Space,
  Modal,
  Popconfirm,
} from "antd";

import {
  SaveOutlined,
  FileImageOutlined,
  CloudUploadOutlined,
  EditOutlined,
  UndoOutlined,
  DeleteOutlined,
  HomeOutlined,
  FormOutlined,PlusOutlined 
} from "@ant-design/icons";
import "./users.css";
import { Link } from "react-router-dom";
const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const RegisterUser = (props) => {
  const [form] = Form.useForm();
  const [formSchedule] = Form.useForm();
  const [Id, setId] = useState(props.location.state.Id);
  const returnUrl = props.location.state.returnUrl;
  const [updateUserProfile, setUpdateUserProfile] = useState(
    props.location.state.updateUserProfile
  );
  const { settings } = useContext(SettingsContext);
  const [firstLoad, setFirstLoad] = useState(true);
  const [specialty, setSpecialty] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [imgSource, setImgSource] = useState("");
  const [imgSourceHeader, setImgSourceHeader] = useState("");
  const [imgSourceSignature, SetImgSourceSignature] = useState("");
  const [fileList, setFileList] = useState(null);
  const [fileListHeader, setFileListHeader] = useState(null);
  const [fileListSignature, setFileListSignature] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const fileInput = useRef(null);
  const fileInputHeader = useRef(null);
  const fileInputSignature = useRef(null);
  const [profilePic, setProfilePic] = useState("");
  const isAdministrator = settings.isAdministrator;
  const [skedData, setSkedData] = useState([]);
  const [skedId, setSkedId] = useState(0);
  const [addedDoctors,setAddedDoctors] = useState([])
  const [disableSave,setDisableSave] = useState(true)
  const [isDoctor,setIsDoctor] = useState(false)

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        skedData.length >= 1 ? (
          <Button type="link" onClick={() => handleEditSked(record.Id)}>
            {<EditOutlined />}
            Edit
          </Button>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        skedData.length >= 1 ? (
          <Button type="link" onClick={() => handleDeleteSked(record.Id)}>
            {<DeleteOutlined />}
            Delete
          </Button>
        ) : null,
    },
    {
      title: "Day",
      dataIndex: "DayStr",
    },
    {
      title: "Time Start",
      dataIndex: "TimeStartStr",
    },
    {
      title: "Time End",
      dataIndex: "TimeEndStr",
    },
  ];

  const routes = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "users",
      breadcrumbName: "Users",
    },
    {
      path: "second",
      breadcrumbName: "User Registration",
    },
  ];
  const breadCrumbs = () => {
    return <Breadcrumb itemRender={itemRender} routes={routes} />;
  };
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  }

const columnsAddDoctors = [
   {
      title: "",
      dataIndex: "operation",
      render: (text, record) =>
        addedDoctors.length >= 1 ? (
          <div>
            <Popconfirm
              title="Delete this record?"
              onConfirm={() => handleDeleteAddDoctor(record)}
              okText="Yes"
              cancelText="No"
            >
              <a>Delete</a>
            </Popconfirm>
          </div>
        ) : null,
    },
  {
    title: "Doctor",
    dataIndex: "DoctorName",
  },
  {
    title: "Added by",
    dataIndex: "AddedBy",
  },
  {
    title: "Date Added",
    dataIndex: "DateAdded",
  },
];
  const onFilePicked = (files) => {
    console.log(files[0]);

    if (files[0] === undefined) return false;

    setFileList(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileList(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setImgSource(fileReader.result);
    });
    fileReader.readAsDataURL(files[0]);
  };
  const onFilePickedHeader = (files) => {
    console.log(files[0]);

    if (files[0] === undefined) return false;

    setFileListHeader(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileListHeader(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setImgSourceHeader(fileReader.result);
    });
    fileReader.readAsDataURL(files[0]);
  };
  const onFilePickedSignature = (files) => {
    console.log(files[0]);

    if (files[0] === undefined) return false;

    setFileListSignature(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileListSignature(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      SetImgSourceSignature(fileReader.result);
    });
    fileReader.readAsDataURL(files[0]);
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleClickHeader = () => {
    fileInputHeader.current.click();
  };
  const handleClickSignature = () => {
    fileInputSignature.current.click();
  };

  const handleUploadProfilePicture = async () => {
    if (fileList === null) {
      return message.error("Please select a file to be uploaded!");
    }

    let filename = fileList.name;
    const fd = new FormData();
    fd.append("Id", Id);
    fd.append("image", fileList);
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(apiUrl + "api/uploadimage", fd, filename, {
        onUploadProgress: (uploadEvent) => {
          console.log(
            "Upload progress: " +
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
              "%"
          );
        },
      })
      .then((res) => {
        message.success(res.data.message);
      });
  };

  const handleUploadHeader = async () => {
    if (fileListHeader === null) {
      return message.error("Please select a file to be uploaded!");
    }

    let filename = fileListHeader.name;

    const fd = new FormData();
    fd.append("Id", Id);
    fd.append("image", fileListHeader);
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(apiUrl + "api/uploadDoctorHeader", fd, filename, {
        onUploadProgress: (uploadEvent) => {
          console.log(
            "Upload progress: " +
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
              "%"
          );
        },
      })
      .then((res) => {
        message.success(res.data.message);
      });
  };

  const handleUploadSignature = async () => {
    if (fileInputSignature === null) {
      return message.error("Please select a file to be uploaded!");
    }

    let filename = fileListSignature.name;

    const fd = new FormData();
    fd.append("Id", Id);
    fd.append("image", fileListSignature);
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(apiUrl + "api/uploadSignature", fd, filename, {
        onUploadProgress: (uploadEvent) => {
          console.log(
            "Upload progress: " +
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
              "%"
          );
        },
      })
      .then((res) => {
        message.success(res.data.message);
      });
  };

  const handleUserProfileImage = useCallback(async () => {
  
     let apiUrl = appJson.apiUrl;
   

    setImgSource(apiUrl + "api/getprofilepicture?id=" + Id);
    setImgSourceHeader(apiUrl + "api/getdoctorheader?id=" + Id);
    SetImgSourceSignature(apiUrl + "api/getsignature?id=" + Id);
  }, [profilePic]);

  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();

      if (values.isDoctor === true) {
        if (!values.MobileNo) {
          return message.error("Mobile no. is required!");
        }
        if (!values.Title) {
          return message.error("Title is required!");
        }
      }

      if (Id === "" && !values.Password) {
        return message.error("Please encode password!");
      }

      if (Id === "" && !values.ConfirmPassword) {
        return message.error("Please confirm your password!");
      }

      if (Id === "" && values.Password.length < 5) {
        return message.error("Minimum characters for password field is 5!");
      }

      if (Id === "") {
        if (values.Password !== values.ConfirmPassword) {
          return message.error("Passwords do not match!");
        }
      }

      setIsSaving(true);

      const valuestosave = {
        ...values,
        emailaddress: settings.UserName,
        Id: Id,
        updateUserProfile: updateUserProfile,
      };
      const payLoad = {
        endPoint: "saveuserinformation",
        valuestosave: valuestosave,
      };
      postData(payLoad).then((res) => {
        if (res.status === 1) {
          /*        updateSettings({
            ...settings,

            isAdministrator: values.isAdministrator,
            AccessToHimsPatients: values.AccessToHimsPatients,
          }); */

          message.success("Successfully saved!");

          setId(res.param1);
        } else {
          message.error(res.message);
        }
      });
      setIsSaving(false);
    } catch (errorInfo) {}
  };
  useEffect(() => {
    handleUserProfileImage();
  }, [handleUserProfileImage]);

  useEffect(() => {
    if (firstLoad) {
      getListOfSettings("SPECIALTY").then((res) => {
        setSpecialty(res);
      });

      let payLoad = {
        sp: "udp_GetListOfDoctors",
        param1: "",
      };
      getListOfRecords(payLoad).then((res) => {
        setDoctors(res);
      });

      payLoad = {
        sp: "udp_GetUserDetails",
        param1: Id,
        param2: "",
        param3: "",
        param4: "",
      };

      if (Id !== "") {
        getRecord(payLoad).then((res) => {
          if (res) {
    
            let doctorId = null;

            if (settings.isHospitalAdmin)
            {
                if (res.isAdministrator===true){
                  setDisableSave(true)
                }
                else
                {
                  setDisableSave(false)
                }
            }else
            {
               setDisableSave(false);
            }

            if (settings.isAdministrator){
                setDisableSave(false);
            }

            if (res.DoctorId !== "00000000-0000-0000-0000-000000000000") {
              doctorId = res.DoctorId;
            }

           
            form.setFieldsValue({
              EmailAddress: res.EmailAddress,
              Lastname: res.Lastname,
              Firstname: res.Firstname,
              Middlename: res.Middlename,
              Suffix: res.Suffix,
              Title: res.Title,
              MobileNo: res.MobileNo,
              OtherContactNos: res.OtherContactNos,
              RoomNo: res.RoomNo,
              isDoctor: res.isDoctor,
              MainSpecialty: res.MainSpecialty,
              SubSpecialty1: res.SubSpecialty1,
              SubSpecialty2: res.SubSpecialty2,
              PrcNo: res.PrcNo,
              PtrNo: res.PtrNo,
              S2No: res.S2No,
              PhicNo: res.PhicNo,
              Facebook: res.Facebook,
              Twitter: res.Twitter,
              GooglePlus: res.GooglePlus,
              LinkedIn: res.LinkedIn,
              isAdministrator: res.isAdministrator,
              AccessToHimsPatients: res.AccessToHimsPatients,
              isHospitalAdmin : res.isHospitalAdmin
            
            });

            setProfilePic(res.ProfilePictureUrl);
          }
        });
      } else {
        setDisableSave(false)
        form.setFieldsValue({
          EmailAddress: "",
          Lastname: "",
          Firstname: "",
          Middlename: "",
          Suffix: "",
          Title: "",
          MobileNo: "",
          isDoctor: false,
          Password: "",
          ConfirmPassword: "",
          MainSpecialty: "",
          SubSpecialty1: "",
          SubSpecialty2: "",
          PrcNo: "",
          PtrNo: "",
          S2No: "",
          PhicNo: "",
          Facebook: "",
          Twitter: "",
          GooglePlus: "",
          LinkedIn: "",
          isAdministrator: false,
          AccessToHimsPatients: false,
        });
      }

      handleGetDoctorSked();
      handleGetAddedDoctors();
    }
    setFirstLoad(false);
  }, [firstLoad, Id, form]);

  const handleGetDoctorSked = async () => {
    let payLoad = {
      sp: "udp_GetSchedulesPerDoctor",
      param1: Id,
    };
    await getListOfRecords(payLoad).then((res) => {
      setSkedData(res);
    });
  };
  const handleSaveSchedule = async () => {
    const values = await formSchedule.validateFields();
    const timeStart = moment(values.TimeStart).format("hh:mm A");
    const timeEnd = moment(values.TimeEnd).format("hh:mm A");

    const valuestosave = {
      Day: values.Day,
      TimeStart: timeStart,
      TimeEnd: timeEnd,
      FktblUsersId: Id,
      emailaddress: settings.UserName,
      Id: skedId,
    };
    const payLoad = {
      endPoint: "saveschedule",
      valuestosave: valuestosave,
    };
    postData(payLoad).then((res) => {
      if (res.status === 1) {
        message.success("Successfully saved!");
        formSchedule.setFieldsValue({
          Day: null,
        });
        handleGetDoctorSked();
        setSkedId(0);
      } else {
        message.error(res.message);
      }
    });
  };
  const handleEditSked = (id) => {
    setSkedId(id);
    var s = skedData.filter((f) => f.Id === id);

    formSchedule.setFieldsValue({
      Day: s[0].Day,
      TimeStart: moment(s[0].TimeStart, "hh:mm A"),
      TimeEnd: moment(s[0].TimeEnd, "hh:mm A"),
    });
  };

  const handleDeleteSked = (id) => {
    setSkedId(id);
  };
  const handleRefreshSked = () => {
    setSkedId(0);
    formSchedule.setFieldsValue({
      Day: null,
      TimeStart: null,
      TimeEnd: null,
    });
  };

  const handleGetAddedDoctors = async () => {
    let payLoad = {
      sp: "udp_GetAddedDoctorsPerUser",
      param1: Id,
    };
    await getListOfRecords(payLoad).then((res) => {
      setAddedDoctors(res);
    });
  };

  const handleSubmitAddDoctor=()=>
  {
   const doctorId= form.getFieldValue("DoctorId");
    if (doctorId===undefined){
      return message.error("Please select a doctor!")
    }


    const valuestosave = {
      doctorId: doctorId,
      userId: Id,
      emailaddress: settings.UserName
    };
    const payLoad = {
      endPoint: "saveSecretaryToDoctor",
      valuestosave: valuestosave,
    };
    postData(payLoad).then((res) => {
      if (res.status === 1) {
        message.success("Successfully saved!");
        form.setFieldsValue({
          DoctorId:null
        })
       handleGetAddedDoctors();

      } else {
        message.error(res.message);
      }
    });

  }

  const handleDeleteAddDoctor=(x)=>{
    const valuestosave = {
      id: x.Id,
      emailaddress: settings.UserName,
    };
    const payLoad = {
      endPoint: "deleteSecretaryToDoctor",
      valuestosave: valuestosave,
    };
    postData(payLoad).then((res) => {
      if (res.status === 1) {
        message.success("Successfully deleted!");
        form.setFieldsValue({
          DoctorId: null,
        });
        handleGetAddedDoctors();
      } else {
        message.error(res.message);
      }
    });
  }
  
    return (
      <div>
        {/*    <Layout>
          <Header
            className="site-layout-background"
            style={{ width: "100%" }}
          ></Header>
        </Layout> */}
        {returnUrl !== "/" && breadCrumbs()}

        <Content>
          <Row gutter={16}>
            <Col></Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Form
                {...formItemLayout}
                form={form}
                onFinish={() => {
                  handleSubmitForm();
                }}
              >
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Personal Information" key="1">
                    <div>
                      <Form.Item
                        label="Email Address"
                        name="EmailAddress"
                        rules={[
                          {
                            required: true,
                            message: "Required field!",
                          },
                          {
                            max: 100,
                            message:
                              "Maximum characters for email address is 100!",
                          },
                          { type: "email", message: "Invalid email address!" },
                        ]}
                      >
                        <Input disabled={Id !== ""} />
                      </Form.Item>
                      <Form.Item
                        label="Lastname"
                        name="Lastname"
                        rules={[
                          {
                            required: true,
                            message: "Required field!",
                          },
                          {
                            max: 50,
                            message: "Maximum characters 50!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Firstname"
                        name="Firstname"
                        rules={[
                          {
                            required: true,
                            message: "Required field!",
                          },
                          {
                            max: 50,
                            message: "Maximum characters 50!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Middlename"
                        name="Middlename"
                        rules={[
                          {
                            required: true,
                            message: "Required field!",
                          },
                          {
                            max: 50,
                            message: "Maximum characters 50!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Suffix"
                        name="Suffix"
                        rules={[
                          {
                            required: false,
                            message: "Required field!",
                          },
                          {
                            max: 50,
                            message: "Maximum characters 50!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Title"
                        name="Title"
                        rules={[
                          {
                            required: false,
                            message: "Required field!",
                          },
                          {
                            max: 50,
                            message: "Maximum characters 50!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Mobile No."
                        name="MobileNo"
                        rules={[
                          {
                            required: false,
                            message: "Required field!",
                          },
                          {
                            max: 20,
                            message: "Maximum characters 20!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Other Contact Nos."
                        name="OtherContactNos"
                        rules={[
                          {
                            required: false,
                            message: "Required field!",
                          },
                          {
                            max: 100,
                            message: "Maximum characters 100!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Room No."
                        name="RoomNo"
                        rules={[
                          {
                            required: false,
                            message: "Required field!",
                          },
                          {
                            max: 100,
                            message: "Maximum characters 100!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      {updateUserProfile === false && (
                        <div>
                          <Form.Item
                            label="Secretary to Dr?"
                            name="DoctorId"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                            ]}
                          >
                            <Select allowClear>
                              {doctors.map((d) => (
                                <Option key={d.Id}>{d.Name}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...tailFormItemLayout}
                            name="listOfDoctors"
                            label=""
                          >
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              onClick={() => handleSubmitAddDoctor()}
                              loading={isSaving}
                              disabled={Id === ""}
                            >
                              Add
                            </Button>
                          </Form.Item>

                          <Form.Item
                            {...tailFormItemLayout}
                            name="listOfDoctors"
                            label=""
                          >
                            <Table
                              rowClassName={() => "editable-row"}
                              bordered
                              dataSource={addedDoctors}
                              columns={columnsAddDoctors}
                              pagination={false}
                            />
                          </Form.Item>
                        </div>
                      )}

                      {Id === "" && (
                        <div>
                          <Form.Item
                            label="Password"
                            name="Password"
                            rules={[
                              {
                                required: false,
                                message: "Password is required!",
                              },
                              {
                                max: 50,
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>

                          <Form.Item
                            label="Confirm Password"
                            name="ConfirmPassword"
                            rules={[
                              {
                                required: false,
                                message: "Please confirm your password!",
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                        </div>
                      )}

                      {isAdministrator && (
                        <div>
                          <Form.Item
                            {...tailFormItemLayout}
                            name="isDoctor"
                            valuePropName="checked"
                            disabled={updateUserProfile}
                          >
                            <Checkbox>Check if user is a doctor!</Checkbox>
                          </Form.Item>

                          <Form.Item
                            {...tailFormItemLayout}
                            name="isAdministrator"
                            valuePropName="checked"
                            disabled={updateUserProfile}
                          >
                            <Checkbox>Check if system administrator!</Checkbox>
                          </Form.Item>
                          <Form.Item
                            {...tailFormItemLayout}
                            name="isHospitalAdmin"
                            valuePropName="checked"
                            disabled={settings.isHospitalAdmin === false}
                          >
                            <Checkbox>Check if Hospital Admin!</Checkbox>
                          </Form.Item>
                          {/*            <Form.Item
                          {...tailFormItemLayout}
                          name="AccessToHimsPatients"
                          valuePropName="checked"
                        >
                          <Checkbox>With Access to Hims Patients</Checkbox>
                        </Form.Item> */}
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane
                    tab="Specialties"
                    key="2"
                    disabled={form.getFieldValue("isDoctor") === false}
                  >
                    <Form.Item label="Main Specialty" name="MainSpecialty">
                      <AutoComplete
                        options={specialty}
                        placeholder="Please select"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>

                    <Form.Item label="Sub. Specialty 1" name="SubSpecialty1">
                      <AutoComplete
                        options={specialty}
                        placeholder="Please select"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>

                    <Form.Item label="Sub. Specialty 2" name="SubSpecialty2">
                      <AutoComplete
                        options={specialty}
                        placeholder="Please select"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane
                    tab="License Information"
                    key="3"
                    disabled={form.getFieldValue("isDoctor") === false}
                  >
                    <Form.Item
                      label="Prc No."
                      name="PrcNo"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 20,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Ptr No."
                      name="PtrNo"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 20,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="S2 No."
                      name="S2No"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 20,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Phic No."
                      name="PhicNo"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 20,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Schedule" key="7">
                    <Form
                      {...formItemLayout}
                      form={formSchedule}
                      onFinish={() => {
                        handleSaveSchedule();
                      }}
                    >
                      <Form.Item
                        label="Day"
                        name="Day"
                        rules={[
                          {
                            required: true,
                            message: "Day is required!",
                          },
                        ]}
                      >
                        <Select placeholder="Please select!" allowClear>
                          <Option value="1">Monday</Option>
                          <Option value="2">Tuesday</Option>
                          <Option value="3">Wednesday</Option>
                          <Option value="4">Thursday</Option>
                          <Option value="5">Friday</Option>
                          <Option value="6">Saturday</Option>
                          <Option value="7">Sunday</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Time Start"
                        name="TimeStart"
                        rules={[
                          {
                            required: true,
                            message: "Time start is required!",
                          },
                        ]}
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>

                      <Form.Item
                        label="Time End"
                        name="TimeEnd"
                        rules={[
                          {
                            required: true,
                            message: "Time end is required!",
                          },
                        ]}
                      >
                        <TimePicker use12Hours format="h:mm A" />
                      </Form.Item>
                      <Form.Item {...tailFormItemLayout}>
                        <Space>
                          <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SaveOutlined />}
                            disabled={Id === ""}
                          >
                            Save
                          </Button>
                          <Button
                            type="primary"
                            icon={<UndoOutlined />}
                            disabled={Id === ""}
                            onClick={() => handleRefreshSked()}
                          >
                            Refresh
                          </Button>
                        </Space>
                      </Form.Item>
                    </Form>

                    <Table
                      rowKey={(record) => record.Id}
                      size="small"
                      columns={columns}
                      dataSource={skedData}
                      pagination={null}
                    />
                  </TabPane>
                  <TabPane
                    tab="Prescription Header"
                    key="5"
                    disabled={form.getFieldValue("isDoctor") === false}
                  >
                    <div style={{ textAlign: "center" }}>
                      <img size={256} src={imgSourceHeader} />
                      <br />
                      <br />
                      <div>
                        <Button
                          disabled={Id === ""}
                          block
                          type="primary"
                          onClick={() => handleClickHeader()}
                        >
                          {<FileImageOutlined />} Pick a file
                        </Button>
                      </div>
                      <br />
                      <div>
                        <Button
                          block
                          type="primary"
                          onClick={() => handleUploadHeader()}
                          disabled={Id === ""}
                        >
                          {<CloudUploadOutlined />} Upload
                        </Button>
                      </div>

                      <input
                        style={{ display: "none" }}
                        ref={fileInputHeader}
                        type="file"
                        accept="image/*"
                        onChange={(e) => onFilePickedHeader(e.target.files)}
                      />
                    </div>
                  </TabPane>

                  <TabPane
                    tab="Signature"
                    key="6"
                    disabled={form.getFieldValue("isDoctor") === false}
                  >
                    <div style={{ textAlign: "center" }}>
                      <img size={256} src={imgSourceSignature} />
                      <br />
                      <br />
                      <div>
                        <Button
                          disabled={Id === ""}
                          block
                          type="primary"
                          onClick={() => handleClickSignature()}
                        >
                          {<FileImageOutlined />} Pick a file
                        </Button>
                      </div>
                      <br />
                      <div>
                        <Button
                          block
                          type="primary"
                          onClick={() => handleUploadSignature()}
                          disabled={Id === ""}
                        >
                          {<CloudUploadOutlined />} Upload
                        </Button>
                      </div>

                      <input
                        style={{ display: "none" }}
                        ref={fileInputSignature}
                        type="file"
                        accept="image/*"
                        onChange={(e) => onFilePickedSignature(e.target.files)}
                      />
                    </div>
                  </TabPane>
                  {/* <TabPane tab="Templates" key="8"></TabPane> */}
                  <TabPane tab="Social Media" key="4">
                    <Form.Item
                      label="Facebook"
                      name="Facebook"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Twitter"
                      name="Twitter"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Google Plus"
                      name="GooglePlus"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="LinkedIn"
                      name="LinkedIn"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </TabPane>
                </Tabs>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <div>
                <Avatar size={256} src={imgSource} />
                <br />
                <br />
                <div>
                  <Button
                    disabled={Id === ""}
                    block
                    type="primary"
                    onClick={() => handleClick()}
                  >
                    {<FileImageOutlined />} Pick a file
                  </Button>
                </div>
                <br />
                <div>
                  <Button
                    block
                    type="primary"
                    onClick={() => handleUploadProfilePicture()}
                    disabled={Id === ""}
                  >
                    {<CloudUploadOutlined />} Upload
                  </Button>
                </div>

                <input
                  style={{ display: "none" }}
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(e) => onFilePicked(e.target.files)}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col offset={5}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => handleSubmitForm()}
                loading={isSaving}
                disabled={disableSave}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Content>
      </div>
    );
};

export default withRouter(RegisterUser);
