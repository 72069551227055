import React, { useEffect, useState, useRef } from "react";
   import {
     Button,
     Input,
     Typography,
     Space,
     message,
     Select,
     Modal,
   } from "antd";
import CanvasDraw from "react-canvas-draw";
function Canvas(props) {
     const canvasArea = useRef(null);
  const [url,setUrl] = useState("")
    useEffect(() => {
        
        setUrl(props.url)

        return () => {
            
        }
    }, [props])


    const handleClear=()=>
    {
        console.log(canvasArea.current)
        canvasArea.current.clear()
    }


    return (
      <>
       

        <div>
          <CanvasDraw
            ref={canvasArea}
            canvasWidth={700}
            canvasHeight={500}
            brushRadius={8}
            lazyRadius={0}
            //  imgSrc={require({imgSource})}
            imgSrc={url}
          />
        </div>
        
      </>
    );
}

export default Canvas
