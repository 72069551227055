import React, { useContext, useEffect, useState, useForm } from "react";
import { SettingsContext } from "../../settingsContext.js";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  postData,
  getHimsPatientInfo,
  getRecord,
  getListOfRecords,
  getListOfRecords2,
} from "../DataService.js";

import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Checkbox,
  Tabs,
  message,
  AutoComplete,
  Breadcrumb,
  Avatar,
  Card,
  Typography,
  DatePicker,
  Select,
  Space,
  Modal,
  Menu,
  Dropdown,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import {
  SaveOutlined,
  FileImageOutlined,
  CloudUploadOutlined,
  FormOutlined,
  HomeOutlined,
  FileDoneOutlined,
  MedicineBoxOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const AppointmentDetails = (props) => {
  const [form] = Form.useForm();
  const { settings, updateSettings } = useContext(SettingsContext);
  const [Id, setId] = useState(props.location.state.Id);
  const [patientId, setPatientId] = useState(props.location.state.patientId);
  const [isSaving, setIsSaving] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [AppointmentDate, setAppointmentDate] = useState("");
  const returnUrl = props.location.state.returnUrl;
  const [reasonForVisit, setReasonForVisit] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [sex, setSex] = useState("");
  const [typeOfConsultation, setTypeOfConsultation] = useState([]);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };

  const handleGetAppointmentDetails = async () => {
    const payLoad = {
      sp: "udp_GetAppointmentDetails",
      param1: Id,
    };
    await getRecord(payLoad).then((res) => {
      setPatientName(res.PatientName);
      setAppointmentDate(res.TransDateStr);
      setDob(res.DobStr);
      setAge(res.Age);
      setSex(res.Gender);

      form.setFieldsValue({
        TransDate: moment(new Date(res.TransDateStr), "MM/DD/YYYY hh:mm A"),
        ReasonForVisit: res.ReasonForVisit,
        FktblUsersId: res.FktblUsersId,
        ChiefComplaint: res.ChiefComplaint,
        Notes: res.Notes,
        TypeOfConsultation: res.TypeOfConsultation,
      });
    });
  };
  const handleInitialValues = async () => {
    let payLoad = {
      sp: "udp_GetListOfAllowedDoctors",
      param1: settings.userId,
    };


    
    await getListOfRecords(payLoad).then((res) => {
      setDoctors(res);
    });

    await getListOfRecords2(
      "select * from tblSettings where code='TYPE_OF_CONSULTATION' order by description"
    ).then((res) => {
      if (res) {
        setTypeOfConsultation(res);
      }
    });
    await getListOfRecords2(
      "select * from tblSettings where code='REASONFORVISIT' order by description"
    ).then((res) => {
      if (res) {
        setReasonForVisit(res);
      }
    });
  };
  useState(() => {
    handleInitialValues();
    if (Id !== "") {
      handleGetAppointmentDetails();
    } else {
      setAppointmentDate(moment(new Date()).format("MM/DD/YYYY hh:mm A"));

      form.setFieldsValue({
        TransDate: moment(new Date(), "MM/DD/YYYY hh:mm A"),
      });

      const payLoad = {
        sp: "udp_GetPatientInformation",
        param1: patientId,
      };

      getRecord(payLoad).then((res) => {
        setPatientName(res.Name);
        setDob(res.dobstr);
        setAge(res.Age);
        setSex(res.Gender);
      });
    }
  }, []);


  const handleChangeAppointmentDate = (date, dateString) => {
    setAppointmentDate(dateString);
  };

  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();

      setIsSaving(true);

      const valuestosave = {
        ...values,
        emailaddress: settings.UserName,
        Id: Id,
        FktblPatientInformationId: patientId,
        AppointmentDate: AppointmentDate,
        dob: dob,
      };
      let payLoad = {
        endPoint: "saveAppointment",
        valuestosave: valuestosave,
      };
      postData(payLoad).then((res) => {
        if (res.status === 1) {
          setId(res.param1);

          Modal.success({
            centered: true,
            title: settings.appName,
            content: res.message,
            onOk: () => {},
          });

          setId(res.param1);
        } else {
          console.log(res);
          Modal.error({
            centered: true,
            title: settings.appName,
            content: res.message,
            onOk: () => {},
          });
        }
      });

      setIsSaving(false);
    } catch (errorInfo) {}
  };
  const handleSoap = () => {};
  const handleVitalSigns = () => {};
  const menu = (
    <Menu>
      <Menu.Item icon={<MedicineBoxOutlined />} onClick={() => handleSoap()}>
        Soap
      </Menu.Item>
      <Menu.Item
        icon={<MedicineBoxOutlined />}
        onClick={() => handleVitalSigns()}
      >
        Vital Signs
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Spin spinning={isSaving} tip="Saving Please wait...">
        <Layout>
          <Content>
            <Row>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Space>
                  <Button
                    type="link"
                    icon={<HomeOutlined />}
                    onClick={() => props.history.push("/")}
                    loading={isSaving}
                  >
                    Home
                  </Button>
                  <Button
                    type="link"
                    icon={<FormOutlined />}
                    onClick={() => props.history.push("/clinicpatients")}
                    loading={isSaving}
                  >
                    Clinic Patients
                  </Button>

                  <Button
                    type="link"
                    icon={<FormOutlined />}
                    onClick={() => props.history.push("/appointments")}
                    loading={isSaving}
                  >
                    Appointments
                  </Button>
                </Space>
                <Card
                  hoverable={true}
                  style={{ width: "100%" }}
                  title="Appointment Details"
                  title={
                    <Title level={4}>
                      Name:{patientName} Dob:{dob} Age:{age} Sex:{sex}
                    </Title>
                  }
                >
                  <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={() => {
                      handleSubmitForm();
                    }}
                  >
                    {/*           <Form.Item label="Case Id" name="CaseId">
                    <h1>{Id}</h1>
                  </Form.Item> */}

                    <Form.Item label="Appointment Date" name="TransDate">
                      <DatePicker
                        format={"MM/DD/YYYY hh:mm A"}
                        onChange={handleChangeAppointmentDate}
                        //  showTime={{ format: "hh:mm" }}
                        showTime={{ user12hours: true }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Chief Complaint"
                      name="ChiefComplaint"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 500,
                          message: "Maximum characters 500!",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                      label="Reason for visit"
                      name="ReasonForVisit"
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                      ]}
                    >
                      <Select>
                        {reasonForVisit.map((d) => (
                          <Option key={d.Valuex}>{d.Description}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Doctor"
                      name="FktblUsersId"
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                      ]}
                    >
                      <Select>
                        {doctors.map((d) => (
                          <Option key={d.Id}>{d.Name}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Type of consultation"
                      name="TypeOfConsultation"
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                      ]}
                    >
                      <Select>
                        {typeOfConsultation.map((d) => (
                          <Option key={d.Valuex}>{d.Description}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Notes"
                      name="Notes"
                      rules={[
                        {
                          required: false,
                          message: "Required field!",
                        },
                        {
                          max: 500,
                          message: "Maximum characters 500!",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Form>
                  <Row style={{ marginTop: 10 }}>
                    <Col offset={7}>
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={() => handleSubmitForm()}
                        loading={isSaving}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </>
  );
};

export default withRouter(AppointmentDetails);
