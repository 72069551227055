import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  message,
  Table,
  Menu,
  Dropdown,
  AutoComplete,
  Popconfirm,
} from "antd";
import { SettingsContext } from "../../settingsContext.js";
import {
  postData,
  getListOfRecords,
  getListOfRecords2,
} from "../DataService.js";
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;
const Allergies = (props) => {
  const [form] = Form.useForm();
  const [patientId, setPatientId] = useState(props.patientId);
  const [caseId, setCaseId] = useState(props.caseId);
  const [showModal, setShowModal] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [data, setData] = useState([]);
  const [Id, setId] = useState(0);
  const [record, setRecord] = useState([]);
  const [descriptions,setDescriptions] = useState([])
  const [allergy, setAllergy] = useState("")
  useEffect(() => {
    /*     setPatientId(props.patientId);
    setCaseId(props.caseId); */
    handleGetAllergies();
  }, []);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 10,
      },
      md: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 10,
      },
      sm: {
        span: 24,
        offset: 10,
      },
    },
  };
  const handleOk = () => {
    setShowModal(false);
  };


  const handleSubmitForm = async () => {

    try {
      
    const values = await form.validateFields();

    if (!values.Description)
    {
      return message.error("Description is required!");
    }

    const valuestosave = {
      ...values,
      emailaddress: settings.UserName,
      FktblPatientInformationId: patientId,
    };
    const payLoad = {
      endPoint: "saveAllergy",
      valuestosave: valuestosave,
    };
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
           handleGetAllergies();
          form.setFieldsValue({
            Description: "",
          });
        } else {
          message.error(res.message);
        }
      }
    });
    } catch (error) {
       message.error(error);
    }

  };

  const handleDeleteAllergy=async(x)=>
  {
     try {
      
    const values = await form.validateFields();

   

    const valuestosave = {
      ...values,
      emailaddress: settings.UserName,
      Id: x.Id,
    };
    const payLoad = {
      endPoint: "deleteAllergy",
      valuestosave: valuestosave,
    };
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
           handleGetAllergies();
         
        } else {
          message.error(res.message);
        }
      }
    });
    } catch (error) {
       message.error(error);
    }
  }



  const columns = [
    {
      title: "",
      dataIndex: "operation",
      render: (text, record) =>
        allergy.length >= 1 ? (
          <div>
            <Popconfirm
              title="Delete"
              onConfirm={() => handleDeleteAllergy(record)}
              okText="Yes"
              cancelText="No"
            >
              <a>Delete</a>
            </Popconfirm>
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
  ];

  const handleGetAllergies = async () => {

    const  query=
        "select * from tblAllergies where FktblPatientInformationId = '" +
        patientId +
        "' and deletedby is null"
    

    await getListOfRecords2(query).then((res) => {
      if (res) {
       
        setAllergy(res);
      }
    });
  };
  return (
    <div>
      <h1>Allergies</h1>

      <Card hoverable="true">
        <Row>
          <Col>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={() => {
                handleSubmitForm();
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="Description" name="Description">
                    <AutoComplete
                      value="Description"
                      options={descriptions}
                      placeholder="Please select"
                      style={{ width: 300 }}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      rules={[
                        {
                          required: true,
                          message: "Required field!",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item {...tailFormItemLayout}>
                    <Button onClick={() => handleSubmitForm()} type="primary">
                      <PlusOutlined />
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record.Id}
              columns={columns}
              size="small"
              width={400}
              dataSource={allergy}
              pagination={{ pageSize: 50 }}
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    <p style={{ margin: 0 }}>Added By:{record.AddedBy}</p>
                    <p style={{ margin: 0 }}>Date Added:{record.DateAdded}</p>
                  </div>
                ),
                rowExpandable: (record) => record.Id !== "Not Expandable",
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Allergies;
