import React, { useState, useEffect } from "react";
import { Row, Col, Table, Typography } from "antd";
import { getPatientHimsResults } from "../DataService.js";
import "./style.css";
const { Text } = Typography;
const HimsResults = (props) => {
  const [himsPatientNo] = useState(props.himsPatientNo);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [result, setResult] = useState("");
  useEffect(() => {
    setIsLoading(true);
    getPatientHimsResults({
      himsPatientNo: himsPatientNo,
    }).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [himsPatientNo]);

  const columns = [
    {
      title: "Test Name",
      dataIndex: "TestName",
    },
    {
      title: "Date",
      dataIndex: "TransDateStr",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const setRowClassName = (record) => {
    return record.RowId === rowId ? "clickRowStyl" : "";
  };
  const createMarkup = () => {
    return { __html: result };
  };
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Text strong>Results</Text>
          <Table
            rowKey={(record) => record.RowId}
            size="small"
            columns={columns}
            dataSource={data}
            scroll={{ y: 340 }}
            loading={isLoading}
            pagination={false}
            /*     rowSelection={{
              type: "radio",
              ...rowSelection,
            }} */
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setResult(record.Result);
                  setRowId(record.RowId);
                }, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {}, // mouse enter row
                onMouseLeave: (event) => {}, // mouse leave row
              };
            }}
            rowClassName={setRowClassName}
          />
        </Col>

        <Col span={18}>
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </Col>
      </Row>
    </div>
  );
};

export default HimsResults;
