import React, { useState, useContext, useEffect } from "react";
import appJson from "../../assets/app.json";
import { SettingsContext } from "../../settingsContext.js";
import { getListOfRecords, getRecord, postData } from "../DataService.js";
import { Jutsu } from "react-jutsu";
import {
  Row,
  Col,
  List,
  Menu,
  Typography,
  Form,
  Input,
  Button,
  message,
  Card,
  Table,
  Space,
  Modal,
  Tooltip,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Text } = Typography;
const { TextArea } = Input;



const Teleconsultation = (props) => {
      const { settings, updateSettings } = useContext(SettingsContext);
      const [patientId, setPatientId] = useState(props.patientId);
      const [caseId, setCaseId] = useState(props.caseId);

        const [form] = Form.useForm();
        const [isSaving,setIsSaving] = useState(false)
          

      const handleSubmitForm = async () => {
        const values = await form.validateFields();
        const valuestosave = {
          ...values,
          Id: caseId,
          emailaddress: settings.UserName,
        };

        const payLoad = {
          endPoint: "savesoap",
          valuestosave: valuestosave,
        };

        await postData(payLoad).then((res) => {
          if (res) {
            if (res.status === 1) {
              message.success("Successfully saved!");
            } else {
              message.error(res.message);
            }
          }
        });
      };
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 20,
          },
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 4,
          },
        },
      };

        const handleGetAppointmentDetails =async () => {
          const payLoad = {
            sp: "udp_GetAppointmentDetails",
            param1: caseId,
            param2: "",
            param3: "",
            param4: "",
          };
    console.log(caseId)
       await   getRecord(payLoad).then((res) => {
            if (res) {
              form.setFieldsValue({
                ChiefComplaint: res.ChiefComplaint,
                HistoryOfPresentIllness: res.HistoryOfPresentIllness,
                PhysicalExamination: res.PhysicalExamination,
                PlanManagement: res.PlanManagement,
                emailaddress: settings.UserName,
                Diagnosis:res.Diagnosis
              });
            }
          });
        };
  




      useEffect(() => {

        handleGetAppointmentDetails();
      }, [])

        useEffect(() => {
          const interval = setInterval(() => {
                handleSubmitForm();
          }, 10000);
          return () => clearInterval(interval);
        }, []);


  return (
    <>
      <Form
        form={form}
        layout="vertical"
        //   {...formItemLayout}
        onFinish={() => {
          handleSubmitForm();
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="Chief Complaint" name="ChiefComplaint">
              <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>

            <Form.Item
              label="History Of Present Illness"
              name="HistoryOfPresentIllness"
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>

            <Form.Item label="Physical Examination" name="PhysicalExamination">
              <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>
            <Form.Item label="Plan Management" name="PlanManagement">
              <TextArea autoSize={{minRows: 2, maxRows: 2}} />
            </Form.Item>
            <Form.Item label="Diagnosis" name="Diagnosis">
              <TextArea autoSize={{minRows: 2, maxRows: 2}} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => handleSubmitForm()}
                loading={isSaving}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Jutsu
              subject={caseId}
              roomName={caseId}
              password=""
              displayName="MJH-AnywhereMed"
              onMeetingEnd={() => console.log("Meeting has ended")}
              containerStyles={{ width: "100%", height: "600px" }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Teleconsultation;
