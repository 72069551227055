import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getListOfRecords, downloadReport } from "../DataService.js";
import { Row, Col, Button, Table, Form, DatePicker, Checkbox } from "antd";
import "./style.css";
import moment from "moment";
import { PrinterOutlined } from "@ant-design/icons";
const Reports = () => {
  const [form] = Form.useForm();
  const [reports, setReports] = useState([]);
  const [reportName, setReportName] = useState("");
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("MM/DD/YYYY"));
  useEffect(() => {
    let payLoad = {
      sp: "udp_GetListOfSettings",
      param1: "REPORTS",
    };
    getListOfRecords(payLoad).then((res) => {
      setReports(res);
    });

    form.setFieldsValue({
      DateFrom: moment(new Date(), "MM/DD/YYYY"),
      DateTo: moment(new Date(), "MM/DD/YYYY"),
      ExportToExcel: false,
    });
  }, []);
  const columns = [
    {
      title: "Description",
      dataIndex: "Description",
      width: 200,
    },
  ];
  const setRowClassName = (record) => {
    return record.Valuex === reportName ? "clickRowStyl" : "";
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString);
  };

  const handleChangeDateTo = (date, dateString) => {
    setDateTo(dateString);
  };

  const handlePrint = async () => {
    const values = await form.validateFields();

    downloadReport({
      reportName: reportName,
      param1: dateFrom,
      param2: dateTo,
      param3: "",
      param4: "",
      isExcel: values.ExportToExcel,
      forEmail: false,
    }).then((res) => {});
  };

  return (
    <div>
      <h1>Reports Center</h1>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns}
            dataSource={reports}
            pagination={false}
            scroll={{ y: 540 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setReportName(record.Valuex);
                }, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {}, // mouse enter row
                onMouseLeave: (event) => {}, // mouse leave row
              };
            }}
            rowClassName={setRowClassName}
          />
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form {...formItemLayout} form={form} onFinish={() => {}}>
            <Form.Item label="Date From" name="DateFrom">
              <DatePicker
                format={"MM/DD/YYYY"}
                onChange={handleChangeDateFrom}
                //  showTime={{ format: "hh:mm" }}
              />
            </Form.Item>
            <Form.Item label="Date To" name="DateTo">
              <DatePicker
                format={"MM/DD/YYYY"}
                onChange={handleChangeDateTo}
                //  showTime={{ format: "hh:mm" }}
              />
            </Form.Item>
            <Form.Item
              {...tailFormItemLayout}
              name="ExportToExcel"
              valuePropName="checked"
            >
              <Checkbox>Export to excel</Checkbox>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                disabled={reportName === ""}
                type="primary"
                onClick={() => handlePrint()}
              >
                <PrinterOutlined /> Print
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Reports);
