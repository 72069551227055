import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { Upload, Modal, message, Button } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { SettingsContext } from "../../settingsContext.js";
import appJson from "../../assets/app.json";
import { getListOfRecords, postData } from "../DataService.js";

const { confirm } = Modal;
const ImagesDocuments = (props) => {
  const { settings } = useContext(SettingsContext);
  const [patientId] = useState(props.patientId);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    getListOfRecords({
      sp: "patientFiles",
      param1: patientId,
    }).then((res) => {
      res.forEach((element) => {
        setFileList((fileList) => [
          ...fileList,
          {
            uid: element.Id,
            name: element.Filename,
            status: "done",
            url:
              apiUrl +
              "api/patientfiles?patientId=" +
              patientId +
              "&filename=" +
              element.Filename,
            size: element.Size,
          },
        ]);
        /*  fileList.push({
          uid: element.Id,
          name: element.Filename,
          status: "done",
          url: apiUrl + element.Id,
          size: element.size,
        }); */
      });
    });
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    console.log(file.preview);
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList }) => {
    const f = fileList.filter((i) => i.size <= 2000000);
    setFileList(fileList);
  };

  const uploadButton2 = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleUploadImage = async (options) => {
    const { onError } = options;
    /* const { onSuccess, onError, file, onProgress } = options;

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      onError("You can only upload JPG/PNG file!");
      return message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      options.file = null;
      onError("Image must be smaller than 2MB!");
      return message.error("Image must be smaller than 2MB!");
    } */

    const fd = new FormData();
    fd.append("patientId", patientId);
    fd.append("emailaddress", settings.UserName);
    fd.append("size", options.file.size);
    fd.append("image", options.file);
    const filename = "xxxx.png";
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    try {
      await axios
        .post(apiUrl + "api/uploadpatientimage", fd, filename, {
          onUploadProgress: (uploadEvent) => {
            console.log(
              "Upload progress: " +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                "%"
            );
          },
        })
        .then((res) => {
          if (res) {
            options.onSuccess(res.data, options.file);
            message.success(res.data.message);
          }
        });
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
  };

  const handleOnRemove = async (file) => {
    /*     await confirm({
      title: "Do you want to remove this file?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deletePatientFile",
          valuestosave: {
            id: file.uid,
            emailaddress: settings.UserName,
          },
        }).then((res) => {});
      },
      onCancel() {},
    }); */

    return new Promise((resolve, reject) => {
      confirm({
        title: "Are you sure you want to Delete ?",
        onOk: () => {
          postData({
            endPoint: "deletePatientFile",
            valuestosave: {
              id: file.uid,
              emailaddress: settings.UserName,
              patientId: props.patientId,
              filename:file.name
            },
          }).then((res) => {});
          resolve(true);
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  return (
    <div>
      <div className="clearfix">
        <Upload
          //  action={() => handleUploadImage()}

          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          customRequest={(options) => {
            handleUploadImage(options);
          }}
          beforeUpload={beforeUpload}
          onRemove={(file) => handleOnRemove(file)}
        >
          {/* {fileList.length >= 8 ? null : uploadButton2} */}
          {uploadButton2}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
          width={1200}
          centered={true}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    </div>
  );
};

export default ImagesDocuments;
