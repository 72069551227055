import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  message,
  Table,
  Menu,
  Dropdown,
} from "antd";
import { SettingsContext } from "../../settingsContext.js";
import { postData, getListOfRecords } from "../DataService.js";
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;
const VitalSigns = (props) => {
  const [form] = Form.useForm();
  const [patientId, setPatientId] = useState(props.patientId);
  const [caseId, setCaseId] = useState(props.caseId);
  const [showModal, setShowModal] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [data, setData] = useState([]);
  const [Id, setId] = useState(0);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    /*     setPatientId(props.patientId);
    setCaseId(props.caseId); */
    handleGetVitalSigns();
  }, [props.patientId, patientId]);
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleOk = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
     setId(0);
    form.setFieldsValue({
      Weight: "",
      Height: "",
      Bmi: "",
      Systolic: "",
      Diastolic: "",
      OxygenSaturation: "",
      Rr: "",
      Hr: "",
      BodyTemperature: "",
      CapillaryBloodGlucose: "",
    });
    setShowModal(true);
  };
  const handleComputeBmi = () => {
    const values = form.getFieldsValue();

    const w = values.Weight;
    const h = values.Height;
    let bmi = 0;
    if (w && h) {
      bmi = w / (h * h);
      if (bmi) {
        bmi = bmi.toFixed(1);
      } else {
        bmi = "";
      }
      form.setFieldsValue({
        Bmi: bmi,
      });
    }
  };
  const handleSubmitForm = async () => {
    const values = await form.validateFields();
    const bmi = values.Bmi;
    let bmiDesc = "";
    if (bmi < 16) {
      bmiDesc = "Severe Thickness";
    } else if (bmi >= 16 && bmi <= 17) {
      bmiDesc = "Moderate Thinness	";
    } else if (bmi > 17 && bmi <= 18.5) {
      bmiDesc = "Mild Thinness";
    } else if (bmi > 18.5 && bmi <= 25) {
      bmiDesc = "Normal";
    } else if (bmi > 25 && bmi <= 30) {
      bmiDesc = "Overweight";
    } else if (bmi > 30 && bmi <= 35) {
      bmiDesc = "Obese Class I";
    } else if (bmi > 35 && bmi <= 40) {
      bmiDesc = "Obese Class II";
    } else if (bmi > 40) {
      bmiDesc = "Obese Class III";
    }

    const valuestosave = {
      ...values,
      emailaddress: settings.UserName,
      FkIdtblAppointments: caseId,
      Id: Id,
      BmiDesc: bmiDesc,
    };
    const payLoad = {
      endPoint: "savevitalsigns",
      valuestosave: valuestosave,
    };
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
          handleGetVitalSigns();
          setShowModal(false);
        } else {
          message.error(res.message);
        }
      }
    });
  };
  const handleEditVitals = () => {
    console.log(record);
    setId(record.Id);
    form.setFieldsValue({
      Weight: record.Weight,
      Height: record.Height,
      Bmi: record.Bmi,
      Systolic: record.Systolic,
      Diastolic: record.Diastolic,
      OxygenSaturation: record.OxygenSaturation,
      Rr: record.Rr,
      Hr: record.Hr,
      BodyTemperature: record.BodyTemperature,
      CapillaryBloodGlucose: record.CapillaryBloodGlucose,
    });
    setShowModal(true);
  };
  const handleDelete = async () => {
    await confirm({
      title: "Are you sure delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const valuestosave = {
          Id: record.Id,
          emailaddress: settings.UserName,
        };
        const payLoad = {
          endPoint: "deletevitalsigns",
          valuestosave: valuestosave,
        };

        postData(payLoad).then((res) => {
          if (res) {
            if (res.status === 1) {
              message.success("Sucessfully deleted!");
              handleGetVitalSigns();
              setId(0)
            } else {
              message.error(res.message);
            }
          }
        });
      },
      onCancel() { setId(0);
     
      },
    });
  };
  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEditVitals()}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleDelete()}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => setRecord(record)}
              trigger={["click"]}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
    {
      title: "Weight",
      dataIndex: "Weight",
    },
    {
      title: "Height",
      dataIndex: "Height",
    },
    {
      title: "Bmi",
      dataIndex: "Bmi2",
    },
    {
      title: "Bp",
      dataIndex: "Bp",
    },
    {
      title: "BodyTemperature",
      dataIndex: "BodyTemperature",
    },
    {
      title: "Rr",
      dataIndex: "Rr",
    },
    {
      title: "Hr",
      dataIndex: "Hr",
    },
    {
      title: "Oxygen Saturation",
      dataIndex: "OxygenSaturation",
    },
    {
      title: "Capillary Blood Glucose",
      dataIndex: "CapillaryBloodGlucose",
    },
    {
      title: "Added By",
      dataIndex: "AddedBy",
    },
    {
      title: "Date Added",
      dataIndex: "DateAdded",
    },
    {
      title: "Updated By",
      dataIndex: "UpdatedBy",
    },
    {
      title: "Date Updated",
      dataIndex: "DateUpdated",
    },
  ];

  const handleGetVitalSigns = async () => {
    const payLoad = {
      sp: "udp_GetListOfVitalSignsPerPatient",
      param1: patientId,
      param2: "",
      param3: "",
      param4: "",
    };

    await getListOfRecords(payLoad).then((res) => {
      if (res) {
        console.log(res);
        setData(res);
      }
    });
  };
  return (
    <div>
      <h1>Vital Signs</h1>

      <Card hoverable="true">
        <Row gutter={[16, 16]}>
          <Col>
            <Button type="primary" onClick={() => handleShowModal()}>
              <FileAddOutlined />
              Add
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
           
          />
        </Row>
      </Card>
      <Modal
        title="Add Vital Signs"
        visible={showModal}
        onOk={handleSubmitForm}
        onCancel={handleOk}
        width={800}
        okText="Save"
      >
        <Form
          {...formItemLayout}
          form={form}
          onFinish={() => {
            handleSubmitForm();
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Weight (kg)"
                name="Weight"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber onChange={() => handleComputeBmi()} />
              </Form.Item>
              <Form.Item
                label="Height (m)"
                name="Height"
                onChange={() => handleComputeBmi()}
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item label="Bmi (kg/m2)" name="Bmi">
                <InputNumber disabled />
              </Form.Item>
              <Form.Item
                label="Systolic"
                name="Systolic"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Diastolic"
                name="Diastolic"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Body Temperature (C)"
                name="BodyTemperature"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item label="Oxygen Saturation (%)" name="OxygenSaturation">
                <InputNumber />
              </Form.Item>
              <Form.Item label="RR (breaths/min)" name="Rr">
                <InputNumber />
              </Form.Item>
              <Form.Item label="HR (bpm)" name="Hr">
                <InputNumber />
              </Form.Item>

              <Form.Item
                label="Capillary Blood Glucose"
                name="CapillaryBloodGlucose"
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VitalSigns;
