import React, { useState, useRef, useContext } from "react";
import appJson from "../../assets/app.json";
import CanvasDraw from "react-canvas-draw";
import ImageRecord from "./ImageRecord";
import { CompactPicker } from "react-color";
import { SettingsContext } from "../../settingsContext.js";
import { Button, Input, Typography, Space, message, Select, Modal } from "antd";
import {
ExclamationCircleOutlined,
  DeleteOutlined,

} from "@ant-design/icons";
import Templates from "./Templates";
import Canvas from "./Canvas";
import {
  getListOfRecords2,
  postData,
  getListOfRecords} from "../DataService.js";
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
function ImageTemplate(props) {
const { settings } = useContext(SettingsContext);
    
  const fileInput = useRef(null);
  const templatesSelect = useRef(null)
  const [brushRadius, setBrushRadius] = useState(5);
  const [color, setColor] = useState();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [url,setUrl] = useState("")
  const [imgSource, setImgSource] = useState("")
  const [remountKey,setRemountKey] = useState("")
  const [templates, setTemplates] = useState([]);
  const [caseId,seCaseId] = useState(props.caseId);
  const [patientId,setPatientId] =useState (props.location.state.patientId);
  const [templateId,setTemplateId] = useState(0)
  const [templateName,setTemplateName] = useState("")
  const [showTemplate, setShowTemplate] = useState(false);
  const [selectValue,setSelectValue] = useState(null)
  const [imageRecordId,setImageRecordId] = useState("")
  const [imageRecord, setImageRecord] = useState([]);
  const handleChangeComplete = (color) => {

    setColor(color.hex);
    setDisplayColorPicker(false);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "20px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const handlePickFile = () => {
    fileInput.current.click();
  };
  const onFilePicked = (files) => {
    console.log(files[0]);

    if (files[0] === undefined) return false;
    setFileList(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileList(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      let url = fileReader.result;
      var img = new Image();
      img.src = url;

      setImgSource(img.src);
    });
    fileReader.readAsDataURL(files[0]);
  };
 const handleShowAddTemplate = () => {
    setShowTemplate(true);
  };

const handleCloseTemplates = ()=>
{
    setShowTemplate(false)
    handleGetTemplates();
}

const handleGetTemplates = async()=>
{


      const query =
        "select * from tblImageTemplates where doctorid = '" +
        props.location.state.doctorId +
        "' order by templatename";
    

    await getListOfRecords2(query).then((res) => {
      if (res) {
          console.log(res)
        setTemplates(res);
      }
    });

}


const handleGetPreviousRecords = async () => {
  //udp_GetListOfImageRecord


  const payLoad = {
    sp: "udp_GetListOfImageRecord",
    param1: patientId,
  };

  getListOfRecords(payLoad).then((res) => {
    if (res) {
      setImageRecord(res);
    }
  });
};

useState(()=>{

     if (!settings.isDoctor) {
      return  props.history.push("/accessdenied");
     }

    handleGetTemplates();
     handleGetPreviousRecords();
},[])



const handleChangeTemplate = (e)=>
{
     let apiUrl = appJson.apiUrl;


    const t = templates.filter(f=>f.Id==e)

 
    setTemplateName(t[0].TemplateName)


    setTemplateId(e)
    setUrl(apiUrl + "api/getTemplate?id=" + e );


   setRemountKey( new Date().getTime());
    
}

const handleCreateNewRecord=()=>
{
    if (url==""){
     return   message.error("Please select a template!")
    }
 props.history.push({
          pathname: "/imagerecord",
          state: {
            doctorId: props.location.state.doctorId,
            url:url
          },
        });
      


}

const handleDelete = async (e) => {
  await confirm({
    title: "Do you want to delete this template?",
    icon: <ExclamationCircleOutlined />,
    okText: "Yes",
    cancelText: "No",
    onOk() {
      postData({
        endPoint: "deleteDiagnosis",
        valuestosave: {
          Id: e,
          emailaddress: settings.UserName,
        },
      }).then((res) => {
        handleGetTemplates();
      });
    },
    onCancel() {},
  });
};

const handleChangePreviousRecord = (id) => {


     let apiUrl = appJson.apiUrl;
     const t = imageRecord.filter((f) => f.Id ==id);

     const url = apiUrl + "api/getTemplate?id=" + t[0].FkIdtblImageTemplates;
     const savedData = t[0].SavedData;

    console.log(url)
    console.log(savedData)
    localStorage.setItem('savedData',savedData)
      props.history.push({
        pathname: "/imagerecord",
        state: {
          url: url,
          savedData:savedData,
          Id:id
         
        },
      }); 
    
};

  return (
    <div>
      <div>
        <Text strong>Templates</Text>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Select
          allowClear
          style={{ width: 400 }}
          onChange={handleChangeTemplate}
          // ref={templatesSelect}
          // value={templateName}
        >
          {templates.map((d) => (
            <Option key={d.Id}>{d.TemplateName}</Option>
          ))}
        </Select>
      </div>
      <div>
        <Space>
          <Button type="primary" onClick={handleShowAddTemplate}>
            Add Template
          </Button>
          <Button type="danger" onClick={handleDelete}>
            Delete Template
          </Button>
        </Space>
      </div>

      <div style={{ marginTop: 10 }}>
        <Text strong>Previous Record</Text>
      </div>
      <div style={{ marginTop: 10 }}>
        <Select
          style={{ width: 400 }}
          onSelect={(e) => handleChangePreviousRecord(e)}
        >
          {imageRecord.map((d) => (
            <Option key={d.Id}>{d.Description}</Option>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: 20 }}>
        <Canvas
          key={remountKey}
          url={url}
          /*    caseId={props.location.state.caseId}
          patientId={props.location.state.patientId}
          templateId={templateId}
          templateName={templateName}
          handleChangePreviousRecord={handleChangePreviousRec}
          imageRecordId={imageRecordId} */
        />
      </div>

      <Modal
        visible={showTemplate}
        onOk={handleCloseTemplates}
        onCancel={handleCloseTemplates}
        width={700}
        destroyOnClose={true}
      >
        <Templates doctorId={settings.userId} />
      </Modal>
    </div>
  );
}

export default ImageTemplate;
