import React, { useState, useCallback, useEffect, useContext } from "react";
import moment from "moment";
import { getListOfRecords } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Space,
  Input,
  Spin,
  Popover,
  Menu,
  Dropdown,
  DatePicker,
  Typography,
} from "antd";
import {
  UserAddOutlined,
  SelectOutlined,
  SearchOutlined,
  EditOutlined,
  CreditCardOutlined,
  ClockCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
const { Text } = Typography;
const { Search } = Input;
const DocSchedule = (props) => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { settings, updateSettings } = useContext(SettingsContext);
  const AccessToHimsPatients = settings.AccessToHimsPatients;
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [patientid, setPatientId] = useState("");
  const [Id, setId] = useState("");
  const [doctorId,setDoctorId] = useState("")
  const [searchStr,setSearchStr] = useState("")
  const [dateFrom, setDateFrom] = useState(
      moment(new Date()).format("MM/DD/YYYY")
    );
    const [dateTo, setDateTo] = useState(
      moment(new Date()).format("MM/DD/YYYY")
    );
  const handleNew = () => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        Id: "",
        himsPatientNo: "",
        returnUrl: "/clinicpatients",
      },
    });
  };

  const handleAppointment = (x) => {
    props.history.push({
      pathname: "/appointmentdetails",
      state: {
        patientId: x,
        Id: "",
        returnUrl: "/clinicpatients",
      },
    });
  };
  const fetchData = () => {
    const payLoad = {
      sp: "udp_GetDoctorSchedules",
      param1: searchStr,
      param2: dateFrom,
      param3: dateTo
    };

    getListOfRecords(payLoad).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
   
    fetchData();
  }, [reload]);

  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit(Id)}>
        Edit
      </Menu.Item>
      <Menu.Item
        icon={<ClockCircleOutlined />}
        onClick={() => handleAppointment(Id)}
      >
        Set an appointment
      </Menu.Item>
    </Menu>
  );



  const columns = [
    {
      title: "Name",
      dataIndex: "DoctorName",
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Specialty",
      dataIndex: "Specialty",
    },
    {
      title: "Date",
      dataIndex: "Datex",
    },
    {
      title: "No. of patients",
      dataIndex: "operation",
     
      render: (text, record) =>
        data.length >= 1 ? (
          <span>
            {settings.userId === record.doctorId ? (
              <Button
                onClick={() => {
                  handleClickNoOfPatients(record);
                }}
                type="link"
              >
                {record.noOfPatients}
              </Button>
            ) : settings.isDoctor ? null : (
              <Text strong>{record.noOfPatients}</Text>
            )}
          </span>
        ) : null,
    },
    {
      title: "Schedule",
      dataIndex: "TimeStart",
    },
    {
      title: "Room No.",
      dataIndex: "roomno",
    },
    {
      title: "Day",
      dataIndex: "DayStr",
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileno",
    },
    {
      title: "Other Nos.",
      dataIndex: "othercontactnos",
    },
  ];

  const handleClickNoOfPatients = (x)=>
  {

     props.history.push({
       pathname: "/appointments",
       state: {
         dFrom: x.Datex,
         dTo: x.Datex,
       },
     });

  }
  const handleEdit = (x) => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        himsPatientNo: "",
        Id: x,
        returnUrl: "/clinicpatients",
      },
    });
  };
  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, fetchData]);

  /*  useEffect(() => {
    if (!AccessToHimsPatients) {
      props.history.push("/accessdenied");
    }
  }, [AccessToHimsPatients]); */

  const handleSearch = () => {
   fetchData();
  };
  return (
    <div>
      <Spin spinning={reload} tip="Loading...">
        <h1>Doctors' Schedule</h1>

        <Card hoverable="true">
          <Row gutter={[16, 16]}>
            <Col>
              <Space>
                <Input
                  placeholder="Encode string to search!"
                  onChange={(e) => setSearchStr(e.target.value)}
                  //  onPressEnter={(e) => handleSearch(e)}
                  style={{ width: 250 }}
                />

                <DatePicker
                  placeholder="Date From"
                  defaultValue={moment(new Date(), "MM/DD/YYYY")}
                  format={"MM/DD/YYYY"}
                  onChange={(date, dateString) => {
                    let ddate = moment(new Date(dateString)).format("MM/DD/YYYY");
                    setDateFrom(dateString);
                  }}
                />

                <DatePicker
                  placeholder="Date To"
                  defaultValue={moment(new Date(), "MM/DD/YYYY")}
                  format={"MM/DD/YYYY"}
                  onChange={(date, dateString) => {
                    let ddate = moment(new Date(dateString)).format("MM/DD/YYYY");
                    setDateTo(dateString);
                  }}
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Table
              rowKey={(record) => record.id}
             
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 50 }}
            
            />
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default withRouter(DocSchedule);
