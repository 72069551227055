import React, { useState, useCallback, useEffect, useContext } from "react";
import { getListOfRecords } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Space,
  Input,
  Spin,
  Popconfirm,
} from "antd";
import {
  UserAddOutlined,
  EditOutlined,
  CheckOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const Users = (props) => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { settings, updateSettings } = useContext(SettingsContext);
  const isAdministrator = settings.isAdministrator;
  const handleNew = () => {
    props.history.push({
      pathname: "/registeruser",
      state: {
        Id: "",
        returnUrl: "/users",
        updateUserProfile: false,
      },
    });
  };
  const fetchData = useCallback(() => {
    const payLoad = {
      sp: "udp_GetListOfUsers",
      param1: searchText,
    };
    getListOfRecords(payLoad).then((res) => {
      setData(res);
    });
  }, [searchText]);

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        data.length >= 1 ? (
          <Button type="link" onClick={() => handleEdit(record.Id)}>
            {<EditOutlined />}
            Edit
          </Button>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text, record) =>
        data.length >= 1 ? (
          <div>
            {settings.isAdministrator && (
              <Popconfirm
                title="Change Password"
                onConfirm={() => handleChangePassword(record)}
                okText="Yes"
                cancelText="No"
              >
                <a> {<SecurityScanOutlined />}Change Password</a>
              </Popconfirm>
            )}
          </div>
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text, record) =>
        data.length >= 1 ? (
          <div>
            {settings.isAdministrator && (
              <Popconfirm
                title="Activate this account?"
                onConfirm={() => handleActivateAccount(record)}
                okText="Yes"
                cancelText="No"
              >
                <a> {<CheckOutlined />} Activate</a>
              </Popconfirm>
            )}
          </div>
        ) : null,
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email Address",
      dataIndex: "EmailAddress",
      sorter: (a, b) => a.EmailAddress.length - b.EmailAddress.length,
      sortDirections: ["descend", "ascend"],
    },
   
  ];

  const handleActivateAccount =(rec)=>
  {
    console.log(rec)
  }

    const handleChangePassword = (rec) => {
          

 props.history.replace({
   pathname: "/changepassword2",
   state: {
     Id: rec.Id
   },
 });

    };

  const handleEdit = (x) => {
    props.history.push({
      pathname: "/registeruser",
      state: {
        Id: x,
        returnUrl: "/users",
        updateUserProfile: false
      },
    });
  };
  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, fetchData]);

  useEffect(() => {
    if (!isAdministrator && !settings.isHospitalAdmin) {
      props.history.push("/accessdenied");
    }
  }, [isAdministrator]);

  return (
    <div>
      <Spin spinning={reload} tip="Loading...">
        <h1>List of users</h1>

        <Card hoverable="true">
          <Row gutter={[16, 16]}>
            <Col>
              <Space>
                <Button
                  type="primary"
                  icon={<UserAddOutlined />}
                  onClick={() => handleNew()}
                  disabled={
                    isAdministrator === false &&
                    settings.isHospitalAdmin === false
                  }
                >
                  New
                </Button>
                <Search
                  placeholder="Input search text"
                  onSearch={(value) => {
                    setSearchText(value);
                    setReload(true);
                    //  fetchData();
                  }}
                  style={{ width: 200 }}
                />{" "}
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Table
              rowKey={(record) => record.Id}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 50 }}
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    <p style={{ margin: 0 }}>
                      Doctor?: <strong>{record.isDoctorStr}</strong>
                    </p>
                    <p style={{ margin: 0 }}>Added By:{record.AddedBy}</p>
                    <p style={{ margin: 0 }}>Date Added:{record.DateAdded}</p>
                  </div>
                ),
                rowExpandable: (record) => record.Id !== "Not Expandable",
              }}
            />
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default withRouter(Users);
