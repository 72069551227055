import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { getListOfRecords } from "./DataService.js";

import { Row, Col } from "antd";
import { Doughnut, Line } from "react-chartjs-2";

const Dashboard = () => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [color, setColor] = useState([]);

  const [dataIcd, setDataIcd] = useState([]);
  const [labelsIcd, setLabelIcd] = useState([]);
  const [colorIcd, setColorIcd] = useState([]);

  const [dataDoctor, setDataDoctor] = useState([]);
  const [labelsDoctor, setLabelDoctor] = useState([]);
  const [colorDoctor, setColorDoctor] = useState([]);

  const generateColor = () => {
    return "#" + Math.random().toString(16).substr(-6);
  };
  React.useEffect(() => {
    getListOfRecords({
      sp: "consultationPerAge",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        //setConsultationPerAge(res);
        setData(Object.values(res).map((item) => item.value));
        setLabels(Object.values(res).map((item) => item.type));
        setColor(Object.values(res).map((item) => generateColor()));
      }
    });

    getListOfRecords({
      sp: "icdGraph",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((result) => {
      if (result) {
    
        //setConsultationPerAge(res);
        setDataIcd(Object.values(result).map((item) => item.value));
        setLabelIcd(Object.values(result).map((item) => item.type));
        setColorIcd(Object.values(result).map((item) => generateColor()));
      }
    });

    getListOfRecords({
      sp: "doctorGraph",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((result) => {
      if (result) {

        setDataDoctor(Object.values(result).map((item) => item.value));
        setLabelDoctor(Object.values(result).map((item) => item.type));
        setColorDoctor(Object.values(result).map((item) => generateColor()));
      }
    });
  }, []);

  const data2 = {
    // labels: ["January", "February", "March", "April", "May"],
    labels: labels,
    datasets: [
      {
        label: "Consultations Per Month",
        backgroundColor: color,
        borderColor: "white",
        data: data,

        //  backgroundColor: "rgb(255, 99, 132)",
        // data: [200, 300, 250, 600, 1000],
      },
    ],
  };
  const data3 = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Consultations Per Month",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 70, 75, 56, 55, 80],
      },
    ],
  };
  const data4 = {
    // labels: ["January", "February", "March", "April", "May"],
    labels: labelsIcd,
    datasets: [
      {
        label: "Consultations Per Month",
        backgroundColor: colorIcd,
        borderColor: "white",
        data: dataIcd,

        //  backgroundColor: "rgb(255, 99, 132)",
        // data: [200, 300, 250, 600, 1000],
      },
    ],
  };

  const data5 = {
    // labels: ["January", "February", "March", "April", "May"],
    labels: labelsDoctor,
    datasets: [
      {
        label: "Consultations Per Month",
        backgroundColor: colorDoctor,
        borderColor: "white",
        data: dataDoctor,

        //  backgroundColor: "rgb(255, 99, 132)",
        // data: [200, 300, 250, 600, 1000],
      },
    ],
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Consultations Per Age</h2>
          <Doughnut data={data2} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Consultations Per Month</h2>
          <Line data={data3} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>By Icd 10 Code</h2>
          <Doughnut data={data4} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Consultations Per Doctor</h2>
          <Doughnut data={data5} />
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Dashboard);
