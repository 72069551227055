import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getuserdetailsbytokenid } from "./components/DataService";
import appJson from "./assets/app.json";
import "./App.css";
import "antd/dist/antd.css";
import Routes from "./components/Routes.jsx";
import LogIn from "./components/Users/LogIn.jsx";
import { Layout, Menu, Avatar, Button, Space, Modal, Tooltip } from "antd";
import Loader from "react-loader-spinner";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import {
  HomeOutlined,
  FileOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  CarryOutOutlined,
  LoginOutlined,
  UserOutlined,
  LineChartOutlined,
  KeyOutlined,CalendarOutlined
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { SettingsContext } from "./settingsContext.js";
const { SubMenu } = Menu;
const { Content, Footer, Sider } = Layout;
const { confirm } = Modal;

function App(props) {
  const { settings, updateSettings } = useContext(SettingsContext);
  const fullName = settings.fullName;
  const [collapsed, setCollapsed] = useState(false);
  const [imgSource, setImgSource] = useState("");
  const isLogged = settings.isLogged;
  const isAdministrator = settings.isAdministrator;
  console.log(settings)
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const handleMenuClick = (e) => {
    if (e.key === "mnuUsers") {
      props.history.push("/users");
    } else if (e.key === "mnuHome") {
      props.history.push("/");
    } else if (e.key === "mnuHimsPatients") {
      props.history.push("/himspatients");
    } else if (e.key === "mnuClinicPatients") {
      props.history.push("/clinicpatients");
    } else if (e.key === "mnuAppointments") {
       props.history.push({
         pathname: "/appointments",
         state: {
           dFrom: "",
           dTo: "",
         },
       });

    } else if (e.key === "mnuReports") {
      props.history.push("/reports");
    } else if (e.key === "mnuDocSchedule") {
      props.history.push("/docschedule");
    }
  };

  useEffect(() => {
    console.log(settings.profilePic)
    setImgSource(settings.profilePic);
   
  }, [imgSource, settings.profilePic]);

  useEffect(() => {
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    const tokenid = localStorage.getItem("tokenid");

    if (tokenid !== null) {
      getuserdetailsbytokenid(tokenid).then((res) => {
        if (res) {
  
          updateSettings({
            ...settings,
            UserName: res.EmailAddress,
            fullName: res.Name,
            profilePic: apiUrl + "api/getprofilepicture?id=" + res.Id,
            userId: res.Id,
            isLogged: true,
            isAdministrator: res.isAdministrator,
            AccessToHimsPatients: res.AccessToHimsPatients,
            isDoctor: res.isDoctor,
            isHospitalAdmin: res.isHospitalAdmin
          });
        } else {
          props.history.push("/login");
        }
      });
    }
  }, []);

  const handleLogOut = () => {
    confirm({
      title: "Logout system now?",
      icon: <ExclamationCircleOutlined />,
      content: settings.appName,
      onOk() {
        localStorage.removeItem("tokenid");
        updateSettings({
          ...settings,
          UserName: "",
          fullName: "",
          profilePic: "",
          userId: "",
          isLogged: false,
          isAdministrator: false,
          AccessToHimsPatients: false,
          isHospitalAdmin:false
        });
        props.history.push("/login");
      },
      onCancel() {},
    });
  };
  const handleProfile = () => {
    props.history.replace({
      pathname: "/registeruser",
      state: {
        Id: settings.userId,
        returnUrl: "/",
        updateUserProfile:true
      },
    });
  };

    const handleChangePassword = () => {
      props.history.replace({
        pathname: "/changepassword",
        state: {
          Id: settings.userId,
          returnUrl: "/",
        },
      });
    };
  return (
    <>
      {isLogged && (
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Avatar size={64} src={imgSource} />
              <div style={{ color: "white", marginTop: 5 }}>{fullName}</div>
              <div style={{ marginTop: 5 }}>
                <Tooltip title="Logout">
                  <Button
                    size={"small"}
                    type="link"
                    onClick={() => handleLogOut()}
                    icon={<LoginOutlined />}
                  ></Button>
                </Tooltip>
                <Tooltip title="User profile">
                  <Button
                    size={"small"}
                    type="link"
                    onClick={() => handleProfile()}
                    icon={<UserOutlined />}
                  ></Button>
                </Tooltip>
                <Tooltip title="Change Password">
                  <Button
                    size={"small"}
                    type="link"
                    onClick={() => handleChangePassword()}
                    icon={<KeyOutlined />}
                  ></Button>
                </Tooltip>
              </div>
            </div>

            <Menu
              theme="dark"
              defaultSelectedKeys={[""]}
              mode="inline"
              onClick={(e) => handleMenuClick(e)}
            >
              <Menu.Item key="mnuHome" icon={<HomeOutlined />}>
                Home
              </Menu.Item>
              <Menu.Item key="mnuAppointments" icon={<CarryOutOutlined />}>
                <Space>
                  Appointments
                  {/*   <Badge
                      count={settings.appointmentCount}
                      showZero
                      overflowCount={999}
                    /> */}
                </Space>
              </Menu.Item>
              <Menu.Item key="mnuClinicPatients" icon={<FileTextOutlined />}>
                Clinic Patients
              </Menu.Item>

              <Menu.Item key="mnuDocSchedule" icon={<CalendarOutlined />}>
                <span>Doctor Schedule</span>
              </Menu.Item>

              {settings.AccessToHimsPatients && (
                <Menu.Item key="mnuHimsPatients" icon={<FileTextOutlined />}>
                  Hims Patients
                </Menu.Item>
              )}

              {(settings.isAdministrator === true ||
                settings.isHospitalAdmin === true) && (
                <Menu.Item key="mnuReports" icon={<LineChartOutlined />}>
                  Reports
                </Menu.Item>
              )}
              {(settings.isAdministrator === true ||
                settings.isHospitalAdmin === true) && (
                <SubMenu key="sub1" icon={<FileOutlined />} title="File">
                  <Menu.Item key="mnuUsers">Users</Menu.Item>
                </SubMenu>
              )}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Content style={{ margin: "20px", overflow: "initial" }}>
              <div
                className="site-layout-background"
                style={{ padding: 10, minHeight: 500 }}
              >
                <Routes />
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              AnywhereMed ©2020 Created by Comlogik Business Systems
            </Footer>
          </Layout>
        </Layout>
      )}
      {!isLogged && <LogIn />}
    </>
  );
}

export default withRouter(App);
