import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { SettingsContext } from "../../settingsContext.js";
import { postData, getListOfRecords,getListOfRecords2 } from "../DataService.js";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  message,
  Table,
  Menu,
  Dropdown,
  Select,
  DatePicker,
} from "antd";
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { confirm } = Modal;
const AppointmentStatus = (props) => {
  const [form] = Form.useForm();
  const [patientId, setPatientId] = useState(props.patientId);
  const [caseId, setCaseId] = useState(props.caseId);
  const [showModal, setShowModal] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [data, setData] = useState([]);
  const [Id, setId] = useState(0);
  const [record, setRecord] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);
  const [transactionDate, setTransactionDate] =useState(moment(new Date()).format(
    "MM/DD/YYYY hh:mm A"
  ));
  const handleOk = () => {
    setShowModal(false);
  };
  useEffect(() => {
    /*     setPatientId(props.patientId);
    setCaseId(props.caseId); */

    handleInitialValues();

    handleGetPaymentStatus();
  }, []);


  const handleInitialValues=async()=>{
           await  getListOfRecords2(
               "select * from tblSettings where code='APPOINTMENT_STATUS' order by description"
             ).then((res) => {
               if (res) {
                 setAppointmentStatus(res);
               }
             });

          
  }
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleShowModal = () => {
    setId(0);

    setTransactionDate(moment(new Date()).format("MM/DD/YYYY hh:mm A"));
    form.setFieldsValue({
      TransDate: moment(new Date(), "MM/DD/YYYY hh:mm A"),
      Status: null
    });
    setShowModal(true);
  };
  const handleSubmitForm = async () => {
    const values = await form.validateFields();

    const valuestosave = {
      ...values,
      emailaddress: settings.UserName,
      FkIdtblAppointments: caseId,
      Id: Id,
      transactionDate:transactionDate
    };
    const payLoad = {
      endPoint: "saveAppointmentStatus",
      valuestosave: valuestosave,
    };
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
          handleGetPaymentStatus();
          setShowModal(false);
        } else {
          message.error(res.message);
        }
      }
    });
  };




  const columns = [

    {
      title: "Trans Date",
      dataIndex: "TransDate",
    },
    {
      title: "Status",
      dataIndex: "StatusDesc",
    },
    {
      title: "Username",
      dataIndex: "UserName",
    }
  ];

  const handleGetPaymentStatus = async () => {
    const payLoad = {
      sp: "udp_GetAppointmentStatus",
      param1: caseId,
      param2: "",
      param3: "",
      param4: "",
    };

    await getListOfRecords(payLoad).then((res) => {
      if (res) {

        setData(res);
      }
    });
  };
  return (
    <div>
      <h1>Appointment Status</h1>

      <Card hoverable="true">
        <Row gutter={[16, 16]}>
          <Col>
            <Button type="primary" onClick={() => handleShowModal()}>
              <FileAddOutlined />
              Add
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 340 }}
          />
        </Row>
      </Card>
      <Modal
        title="Add Appointment Status"
        visible={showModal}
        onOk={handleSubmitForm}
        onCancel={handleOk}
        width={800}
        okText="Save"
      >
        <Form
          {...formItemLayout}
          form={form}
          onFinish={() => {
            handleSubmitForm();
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Date" name="TransDate">
                <DatePicker
                  format={"MM/DD/YYYY hh:mm A"}
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                  ]}
                  onChange={(date, dateString) => {
                    let ddate = moment(new Date(dateString), "MM/DD/YYYY");
                    setTransactionDate(dateString);
                  }}
                  value={moment(new Date(transactionDate))}
                />
              </Form.Item>

              <Form.Item
                label="Status"
                name="Status"
                rules={[
                  {
                    required: true,
                    message: "Required field!",
                  },
                ]}
              >
                <Select>
                  {appointmentStatus.map((d) => (
                    <Option key={d.Valuex}>{d.Description}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default AppointmentStatus;
