import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SettingsContext } from "../settingsContext.js";
import Dashboard from "./Dashboard";
import Users from "./Users/Users";
import LogIn from "./Users/LogIn";
import RegisterUser from "./Users/RegisterUser";
import AccessDenied from "./AccessDenied";
import HimsPatients from "./Hims/HimsPatients";
import PatientInformation from "./Patient/PatientInformation";
import AppointmentDetails from "./Appointment/AppointmentDetails";
import ClinicPatients from "./Appointment/ClinicPatients";
import Appointments from "./Appointment/Appointments";
import Soap from "./Soap/Soap";
import ImageEditor from "./Soap/ImageEditor";
import Templates from "./Soap/Templates";
import Reports from "./Reports/Reports";
import VideoCon from "./Soap/VideoCon";
import ChangePassword from "./Users/ChangePassword";
import DocSchedule from "./Appointment/DocSchedule";
import ChangePassword2 from "./Users/ChangePassword2";
import ImageTemplate from "./Soap/ImageTemplate";
import ImageRecord from "./Soap/ImageRecord";
const Routes = () => {
  const { settings } = useContext(SettingsContext);
  const checkAuth = (props) => {
    let isLoggedIn = false;
    const tokenid = localStorage.getItem("tokenid");
    if (tokenid === null) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
    }

    //isLoggedIn = true;
    return isLoggedIn;
  };

  const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );

  const HimsRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );

  return (
    <Switch>
      <AuthRoute
        exact
        strict
        path="/"
        component={Dashboard}
        render={() => <Dashboard />}
      />
      <AuthRoute
        exact
        strict
        path="/users"
        component={Users}
        render={() => <Users />}
      />
      <AuthRoute
        exact
        strict
        path="/registeruser"
        component={RegisterUser}
        render={() => <RegisterUser />}
      />
      <AuthRoute
        exact
        strict
        path="/login"
        component={LogIn}
        render={() => <LogIn />}
      />
      <AuthRoute
        exact
        strict
        path="/accessdenied"
        component={AccessDenied}
        render={() => <AccessDenied />}
      />
      <AuthRoute
        exact
        strict
        path="/himspatients"
        component={HimsPatients}
        render={() => <HimsPatients />}
      />
      <AuthRoute
        exact
        strict
        path="/patientinformation"
        component={PatientInformation}
        render={() => <PatientInformation />}
      />
      <AuthRoute
        exact
        strict
        path="/appointmentdetails"
        component={AppointmentDetails}
        render={() => <AppointmentDetails />}
      />
      <AuthRoute
        exact
        strict
        path="/clinicpatients"
        component={ClinicPatients}
        render={() => <ClinicPatients />}
      />
      <AuthRoute
        exact
        strict
        path="/appointments"
        component={Appointments}
        render={() => <Appointments />}
      />
      <AuthRoute
        exact
        strict
        path="/soap"
        component={Soap}
        render={() => <Soap />}
      />
      <AuthRoute
        exact
        strict
        path="/imageeditor"
        component={ImageEditor}
        render={() => <ImageEditor />}
      />
      <AuthRoute
        exact
        strict
        path="/templates"
        component={Templates}
        render={() => <Templates />}
      />
      <AuthRoute
        exact
        strict
        path="/reports"
        component={Reports}
        render={() => <Reports />}
      />
      <AuthRoute
        exact
        strict
        path="/videocon"
        component={VideoCon}
        render={() => <VideoCon />}
      />
      <AuthRoute
        exact
        strict
        path="/changepassword"
        component={ChangePassword}
        render={() => <ChangePassword />}
      />
      <AuthRoute
        exact
        strict
        path="/changepassword2"
        component={ChangePassword2}
        render={() => <ChangePassword2 />}
      />
      <AuthRoute
        exact
        strict
        path="/docschedule"
        component={DocSchedule}
        render={() => <DocSchedule />}
      />{" "}
      <AuthRoute
        exact
        strict
        path="/imagetemplate"
        component={ImageTemplate}
        render={() => <ImageTemplate />}
      />
      <AuthRoute
        exact
        strict
        path="/imagerecord"
        component={ImageRecord}
        render={() => <ImageRecord />}
      />
    </Switch>
  );
};

export default Routes;
