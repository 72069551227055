import React, { useState, useCallback, useEffect, useContext } from "react";
import { getListOfHimsPatients, getRecord } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Space,
  Input,
  Spin,
  Popover,
  Dropdown,
  Menu,
} from "antd";
import {
  UserAddOutlined,
  SelectOutlined,
  SearchOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const HimsPatients = (props) => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { settings, updateSettings } = useContext(SettingsContext);
  const AccessToHimsPatients = settings.AccessToHimsPatients;
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [patientno, setPatientno] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleNew = () => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        Id: "",
        himsPatientNo: "",
        returnUrl: "/clinicpatients",
      },
    });
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const payLoad = {
      lastname: lastname,
      firstname: firstname,
      middlename: middlename,
      patientno: patientno,
    };

    await getListOfHimsPatients(payLoad).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [reload]);
  const menu = (
    <Menu>
      <Menu.Item icon={<CheckOutlined />} onClick={() => handleEdit(patientno)}>
        Select
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: "",
      dataIndex: "operation",
   
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => setPatientno(record.patientno)}
              trigger={["click"]}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
    {
      title: "Patient No.",
      dataIndex: "patientno",
    
      sorter: (a, b) => a.patientno.length - b.patientno.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: "patientname",
    
      sorter: (a, b) => a.patientname.length - b.patientname.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Address",
      dataIndex: "address",
    
    },
    {
      title: "Sex",
      dataIndex: "sex",
   
    },
    {
      title: "Dob",
      dataIndex: "dobstr",
  
    },
  ];
  const handleEdit = async (x) => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        Id: "",
        himsPatientNo: x,
        returnUrl: "/himspatients",
      },
    });
  };
  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, fetchData]);

  useEffect(() => {
    if (!AccessToHimsPatients) {
      props.history.push("/accessdenied");
    }
  }, [AccessToHimsPatients]);

  const handleSearch = () => {
    setReload(true);
  };

  return (
    <div>
      <h1>List of HIMS Patients</h1>

      <Card hoverable="true">
        <Row gutter={[16, 16]}>
          <Col>
            <Space>
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={() => handleNew()}
              >
                Create new patient
              </Button>
              <Input
                placeholder="Lastname"
                onChange={(e) => setLastname(e.target.value)}
                //  onPressEnter={(e) => handleSearch(e)}
                style={{ width: 150 }}
              />
              <Input
                placeholder="Firstname"
                onChange={(e) => setFirstname(e.target.value)}
                style={{ width: 150 }}
              />
              <Input
                placeholder="Middlename"
                onChange={(e) => setMiddlename(e.target.value)}
                style={{ width: 150 }}
              />
              <Input
                placeholder="Patient No."
                onChange={(e) => setPatientno(e.target.value)}
                style={{ width: 150 }}
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.patientno}
      
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
         
            Loading={isLoading}
          />
        </Row>
      </Card>
    </div>
  );
};

export default withRouter(HimsPatients);
