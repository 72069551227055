import React, { useContext } from "react";
import { SettingsContext } from "../settingsContext.js";
import { withRouter } from "react-router-dom";
import { Result, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
const AccessDenied = (props) => {
  const { settings } = useContext(SettingsContext);
  return (
    <>
      {/*      <Result
        status="Access denied"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Button type="primary">Back Home</Button>}
      /> */}

      <Result
        status="403"
        title={settings.appName}
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => props.history.replace("/")}>
            <HomeOutlined /> Back Home
          </Button>
        }
      />
    </>
  );
};

export default withRouter(AccessDenied);
