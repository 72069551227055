import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../../settingsContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
import { getListOfRecords, getRecord, postData } from "../DataService.js";
import VitalSigns from "./VitalSigns";
import Teleconsultation from "./Teleconsultation";
import {
  Row,
  Col,
  List,
  Menu,
  Typography,
  Form,
  Input,
  Button,
  message,
  Card,
  Table,
  Space,
  Modal,
  Tooltip,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { faStethoscope, faTv } from "@fortawesome/free-solid-svg-icons";
import { SelectOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;
const h = window.screen.height - 200;
const SoapComponent = (props) => {
  const { settings,updateSettings } = useContext(SettingsContext);
  const [form] = Form.useForm();
  const [appointmentDates, setAppointmentDates] = useState([]);
  const [patientId, setPatientId] = useState(props.patientId);
  const [caseId, setCaseId] = useState(props.caseId);
  const [isSaving, setIsSaving] = useState(false);
  const [data2, setData2] = useState([]);
  const [data, setData] = useState([]);
  const [showModalIcd, setShowModalIcd] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [refreshSoap, setRefreshSoap] = useState(props.refreshSoap);
  const [showTeleconsultation, setShowTeleconsultation] = useState(false);


const handleAppointmentsPerPatient=async()=>
{
      
    
      let payLoad = {
        sp: "udp_GetAppointmentsPerPatients",
        param1: props.patientId,
        param2: settings.userId,
        param3: "",
        param4: "",
      };
     await getListOfRecords(payLoad).then((res) => {
        if (res) {
          console.log(res)
          setAppointmentDates(res);
        }
      });
}

  useEffect(() => {
 
    handleAppointmentsPerPatient();
    handleGetAppointmentDetails();
  }, [props.patientId]);

  useEffect(() => {
    handleGetAppointmentDetails();
    handleGetDiagnosis();
  }, [caseId]);

  useEffect(() => {
    if (settings.refreshSoap === true) {

      handleGetAppointmentDetails();
    }
  }, [settings.refreshSoap]);

  useEffect(() => {
    handleGetIcdCodes();
  }, [searchStr]);

  const handleGetIcdCodes = () => {
    getListOfRecords({
      sp: "icd10",
      param1: searchStr,
    }).then((res) => {
      if (res) {
        setData2(res);
      }
    });
  };
  const handleMenuDates = (e) => {
    /*     let item = appointmentDates.filter((x) => x.Id === e.key);
    console.log(item);
    form.setFieldsValue({
      ChiefComplaint: item[0].ChiefComplaint,
    }); */


    setCaseId(e.key);
   
      
    //handleGetAppointmentDetails();
  };

  const handleGetAppointmentDetails = () => {
    const payLoad = {
      sp: "udp_GetAppointmentDetails",
      param1: caseId,
      param2: "",
      param3: "",
      param4: "",
    };

    getRecord(payLoad).then((res) => {
      if (res) {
 localStorage.setItem("Diagnosis", res.Diagnosis);
        form.setFieldsValue({
          ChiefComplaint: res.ChiefComplaint,
          HistoryOfPresentIllness: res.HistoryOfPresentIllness,
          PhysicalExamination: res.PhysicalExamination,
          PlanManagement: res.PlanManagement,
          emailaddress: settings.UserName,
          Diagnosis:res.Diagnosis
        });
      }
    });
  };

  const handleSubmitForm = async () => {
    const values = await form.validateFields();
    const valuestosave = {
      ...values,
      Id: caseId,
      emailaddress: settings.UserName,
    };

    const payLoad = {
      endPoint: "savesoap",
      valuestosave: valuestosave,
    };
    localStorage.setItem("Diagnosis",values.Diagnosis)
    await postData(payLoad).then((res) => {
      if (res) {
        if (res.status === 1) {
          message.success("Successfully saved!");
        } else {
          message.error(res.message);
        }
      }
    });
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

  const columns2 = [
    {
      title: "",
      dataIndex: "operation",
      width: 100,
      render: (text, record) =>
        data2.length >= 1 ? (
          <Button type="link" onClick={() => handleSelect(record.Id)}>
            {<CheckOutlined />}
            Select
          </Button>
        ) : null,
    },
    {
      title: "ICD Code",
      dataIndex: "IcdCode",
      width: "100px",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
  ];
  const handleSelect = async (Id) => {
    await postData({
      endPoint: "saveicd10diagnosis",
      valuestosave: {
        Id: Id,
        FkIdtblAppointments: caseId,
        emailaddress: settings.UserName,
      },
    }).then((res) => {
      if (res) {
        message.success("Successfully saved!");
      }
    });
    handleGetDiagnosis();
    setShowModalIcd(false);
  };
  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 50,
      render: (text, record) =>
        data.length >= 1 ? (
          <Tooltip placement="top" title={"Delete"}>
            <Button type="link" onClick={() => handleDelete(record.Id)}>
              {<DeleteOutlined />}
            </Button>
          </Tooltip>
        ) : null,
    },
    {
      title: "ICD Code",
      dataIndex: "IcdCode",
      width: 80,
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
  ];

  const handleNewIcd = () => {
    setShowModalIcd(true);
  };

  const handleCloseIcd = () => {
    setShowModalIcd(false);
  };

  const handleGetDiagnosis = () => {
    getListOfRecords({
      sp: "udp_GetDiagnosisPerAppointment",
      param1: caseId,
    }).then((res) => {
      setData(res);
    });
  };
  const handleDelete = async (e) => {
    await confirm({
      title: "Do you want to delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deleteDiagnosis",
          valuestosave: {
            Id: e,
            emailaddress: settings.UserName,
          },
        }).then((res) => {
          handleGetDiagnosis();
        });
      },
      onCancel() {},
    });
  };

  
  const handleTeleconsultation = () => {

    setShowTeleconsultation(true);
  };
  const handleCloseTeleconsultation = () => {
    setShowTeleconsultation(false);
handleGetAppointmentDetails()
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        //   {...formItemLayout}
        onFinish={() => {
          handleSubmitForm();
        }}
      >
        <Row gutter={(16, 16)}>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <Button
              type="primary"
              onClick={() => setShowTeleconsultation(true)}
            >
              Teleconsultation
            </Button>
          </Col>
    
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Text strong>Visit Dates</Text>
            <Menu
              onClick={handleMenuDates}
              defaultSelectedKeys={caseId}
              mode={"vertical"}
              theme={"light"}
            >
              {appointmentDates.map((value, index) => {
                return (
                  <Menu.Item onSelect={handleMenuDates} key={value.Id}>
                    <div>{value.TransDateStr}</div>
                  </Menu.Item>
                );
              })}
            </Menu>
          </Col>

          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item label="Chief Complaint" name="ChiefComplaint">
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>

            <Form.Item
              label="History Of Present Illness"
              name="HistoryOfPresentIllness"
            >
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>

            <Form.Item label="Physical Examination" name="PhysicalExamination">
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
            <Form.Item label="Plan Management" name="PlanManagement">
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => handleSubmitForm()}
                loading={isSaving}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
           
            <div style={{ marginTop: 10 }}>
              <Button
                disabled={caseId === ""}
                type="primary"
                onClick={() => handleNewIcd()}
              >
                 New
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Table
                rowKey={(record) => record.Id}
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ y: 340 }}
              />
            </div>

            <Form.Item
              label="Diagnosis"
              name="Diagnosis"
            >
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={showModalIcd}
        onOk={handleCloseIcd}
        onCancel={handleCloseIcd}
        width={800}
        footer={null}
      >
        <div style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search ICD"
            onChange={(e) => setSearchStr(e.target.value)}
            //  onPressEnter={(e) => handleSearch(e)}
            style={{ width: 250 }}
          />
        </div>
        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            size="small"
            columns={columns2}
            dataSource={data2}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 340 }}
          />
        </Row>
      </Modal>
      <Modal
        visible={showTeleconsultation}
        onOk={handleCloseTeleconsultation}
        onCancel={handleCloseTeleconsultation}
        width={window.screen.width - 100}
        destroyOnClose={true}
        //height={window.screen.height * window.devicePixelRatio}
        centered
        bodyStyle={{ height: h }}
      >
        <Teleconsultation caseId={caseId} patientId={patientId} />
      </Modal>
    </div>
  );
};

export default withRouter(SoapComponent);
