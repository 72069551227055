import React, { useState, useContext, useEffect } from "react";
import { css } from "@emotion/core";
import { getListOfRecords, postData, downloadReport } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import moment from "moment";
import axios from "axios";
import appJson from "../../assets/app.json";

import {
  Row,
  Col,
  Button,
  Drawer,
  AutoComplete,
  Form,
  InputNumber,
  DatePicker,
  Space,
  Input,
  Modal,
  Table,
  Menu,
  Dropdown,
  message,
  Select,
  Typography,
  Tooltip,
  Card,
  Spin,
} from "antd";
import {
  PlusOutlined,
  ClearOutlined,
  SaveOutlined,
  EditOutlined,
  MedicineBoxOutlined,
  DeleteOutlined,
  DownOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Document, Page } from "react-pdf";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Prescription = (props) => {
  const { settings,updateSettings } = useContext(SettingsContext);
  const [form] = Form.useForm();
  const [isProcessing,setIsProcessing] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false);
  const [genname, setGenName] = useState([]);
  const [formx, setForm] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [brandname, setBrandname] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [caseId, setCaseId] = useState(props.caseId);
  const [emailAddress] = useState(props.emailAddress);
  const [bName, setBName] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [Id, setId] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  const [prescriptionDate, setPrescriptionDate] = useState(
    moment(new Date(), "YYYY-MM-DD")
  );
  const [data, setData] = useState([]);
  const [showModalAddMedicines, setShowModalAddMedicines] = useState(false);
  const [followUpDate, setFollowUpDate] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [patientId, setPatientId] = useState(props.patientId);
  const [dataPrescriptions, setDataPrescriptions] = useState([]);
  const [printFile, setPrintFile] = useState(null);
const [showModalPrintWithSignature,setShowModalPrintWithSignature]= useState(false)

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };

  useEffect(() => {
    getListOfRecords({
      sp: "udp_GetListOfGenericName",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setGenName(res);
      }
    });

    getListOfRecords({
      sp: "genname",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setGenName(res);
      }
    });

    getListOfRecords({
      sp: "form",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setForm(res);
      }
    });
    getListOfRecords({
      sp: "dosage",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setDosage(res);
      }
    });
    getListOfRecords({
      sp: "brandname",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setBrandname(res);
      }
    });

    getListOfRecords({
      sp: "frequency",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setFrequency(res);
      }
    });
    getListOfRecords({
      sp: "pharma",
      param1: "",
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setPharmacies(res);
      }
    });

    handleGetPrescription();
  }, []);

  useEffect(() => {
    getListOfRecords({
      sp: "brandname",
      param1: bName,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      if (res) {
        setBrandname(res);
      }
    });
  }, [bName]);

  const handleGetPrescription =async () => {
  await  getListOfRecords({
      sp: "udp_PrescriptionPerPatient",
      param1: patientId,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setDataPrescriptions(res);
    });
  };

  const handeChangePrescriptionDate = (date, dateString) => {
    setPrescriptionDate(dateString);
  };
  const handleRefresh = () => {
    setIsNew(false);
    setData([]);
    setPrescriptionId("");
    form.setFieldsValue({
      TransDate: moment(new Date(), "MM/DD/YYYY"),
      GenName: "",
      BrandName: "",
      Dosage: "",
      Form: "",
      Frequency: "",
      StartFrom: moment(new Date(), "MM/DD/YYYY"),
      StartTo: moment(new Date(), "MM/DD/YYYY"),
      Note: "",
      Qty: "",
    });
    setDataPrescriptions([]);
    setPrescriptionDate(null);
    setFollowUpDate(null);
    setRemarks("");
    setPharmacy("");
    handleGetPrescription();
  };
  const handleNew = () => {
    handleRefresh();
    setIsNew(true);
    setPrescriptionId("");
    setPrescriptionDate(moment(new Date(), "YYYY-MM-DD"));
    setShowModalAddMedicines(true);
  };
  const handleCloseAddMedicines = () => {
    setShowModalAddMedicines(false);
  };

  const handleEdit = () => {
    const arr = data.filter((item) => item.Id === Id);

    form.setFieldsValue({
      GenName: arr[0].GenName,
      BrandName: arr[0].BrandName,
      Dosage: arr[0].Dosage,
      Form: arr[0].Form,
      Frequency: arr[0].Frequency,
      DurationFrom:
        arr[0].DurationFrom === "" || arr[0].DurationFrom === null
          ? null
          : moment(new Date(arr[0].DurationFrom), "YYYY-MM-DD"),
      DurationTo:
        arr[0].DurationTo === "" || arr[0].DurationTo === null
          ? null
          : moment(new Date(arr[0].DurationTo), "YYYY-MM-DD"),
      Note: arr[0].Note,
      Qty: arr[0].Qty,
    });
    setShowModalAddMedicines(true);
  };
  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width: 75,
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => {
                console.log(record.Id);
                setId(record.Id);
              }}
              trigger={["click"]}
              disabled={!isNew}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
    {
      title: "Gen. Name",
      dataIndex: "GenName",
    },
    {
      title: "Brand Name",
      dataIndex: "BrandName",
    },
    {
      title: "Dosage",
      dataIndex: "Dosage",
    },
    {
      title: "Form",
      dataIndex: "Form",
    },
    {
      title: "Frequency",
      dataIndex: "Frequency",
    },
    {
      title: "From",
      dataIndex: "DurationFromStr",
    },
    {
      title: "To",
      dataIndex: "DurationToStr",
    },
    {
      title: "Note",
      dataIndex: "Note",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
    },
  ];

  const handleDeleteItem = () => {
    if (data.length <= 1) {
      return message.error("Prescription must have at least 1 medicine!");
    }

    confirm({
      title: "Do you want to delete this item?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        const arr = data.filter((item) => item.Id !== Id);
        setData(arr);
        if (prescriptionId !== "") {
          postData({
            endPoint: "deletePrescriptionDetails",
            valuestosave: {
              Id: Id,
              emailaddress: settings.UserName,
            },
          }).then((res) => {});
        }
      },
      onCancel() {},
    });
  };
  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit()}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleDeleteItem()}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleAddItem = async () => {
    const values = await form.validateFields();

    if (values.Qty <= 0) {
      return message.error("Invalid Quantity!");
    }

    const DurationFrom =
      values.DurationFrom === null || values.DurationFrom === undefined
        ? ""
        : moment(new Date(values.DurationFrom), "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          );
    const DurationTo =
      values.DurationTo === null || values.DurationFrom === undefined
        ? ""
        : moment(new Date(values.DurationTo), "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          );

    const valuesToSave = {
      ...values,
      DurationFrom: DurationFrom,
      DurationTo: DurationTo,
      Id: Id === "" ? uuidv4() : Id,

    };
    form.setFieldsValue({
      GenName: "",
      BrandName: "",
      Dosage: "",
      Form: "",
      Frequency: "",
      Note: "",
      Qty: "",
    });

    if (Id === "") {
      setData([...data, valuesToSave]);
    } else {
      const arr = data.filter((item) => item.Id === Id);

     
       setShowModalAddMedicines(false);
    }

    if (prescriptionId !== "") {
      await postData({
        endPoint: "insertupdatePrescription",
        valuestosave: {
          ...values,
          DurationFrom: DurationFrom,
          DurationTo: DurationTo,
          Id: Id === "" ? "0" : Id,
          FkIdtblPrescriptionParent: prescriptionId,
          emailaddress: settings.UserName,
        },
      }).then((res) => {
        if (res) {
   handleGetPrescription();
        }
      });
      await getListOfRecords({
        sp: "prescriptionDetails",
        param1: prescriptionId,
        param2: "",
        param3: "",
        param4: "",
      }).then((res) => {
        setData(res);
      });
    }

    if (Id === "") {
      message.success("Medicine sucessfully added!");
    } else {
      message.success("Medicine sucessfully updated!");
    }
    setId("");
  };
  const handleSubmitForm = async () => {
    if (data.length <= 0) {
      return message.error("Please add medicines!");
    } else if (prescriptionDate === "") {
      return message.error("Prescription date is required!");
    }

    const presDate = moment(new Date(prescriptionDate), "YYYY-MM-DD").format(
      "MM/DD/YYYY"
    );

    const fDate =
      followUpDate === null || followUpDate === undefined
        ? null
        : moment(new Date(followUpDate), "YYYY-MM-DD").format("MM/DD/YYYY");
    console.log(fDate);
    const valuestosave = {
      Id: prescriptionId,
      data: data,
      TransDate: presDate,
      emailaddress: settings.UserName,
      FkIdtblPharmacyDiagnostics: pharmacy,
      caseId: caseId,
      FollowUpDate: fDate,
      Remarks: remarks,
      patientId: patientId,
      doctorId:settings.userId
    };

    const payLoad = {
      endPoint: "saveprescription",
      valuestosave: valuestosave,
    };

    postData(payLoad).then((res) => {
      if (res.status === 1) {
        setPrescriptionId(res.param1);
        handleGetPrescription();
        message.success("Successfully saved!");
      } else {
        Modal.error({
          centered: true,
          title: settings.appName,
          content: res.message,
          onOk: () => {},
        });
      }
    });
  };

  const handleMenuDates = async (e) => {
    setPrescriptionId(e.key);
    let p = dataPrescriptions.filter((i) => i.prescriptionId === e.key);

    setPrescriptionDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));
    if (p[0].FollowUpDate !== null) {
      setFollowUpDate(moment(new Date(p[0].FollowUpDate), "YYYY-MM-DD"));
    } else {
      setFollowUpDate(null);
    }
    setRemarks(p[0].Remarks);

console.log(p[0].FkIdtblPharmacyDiagnostics);

if (p[0].FkIdtblPharmacyDiagnostics!==null)
{
  if (p[0].FkIdtblPharmacyDiagnostics.substr(0, 8) !== "00000000") {
      setPharmacy(p[0].FkIdtblPharmacyDiagnostics);
    } else {
      setPharmacy(null);
    }
}
   
 
    await getListOfRecords({
      sp: "prescriptionDetails",
      param1: e.key,
      param2: "",
      param3: "",
      param4: "",
    }).then((res) => {
      setData(res);
    });
  };



  const handlePrint = async (w) => {



   setShowModalPrintWithSignature(false)
    setIsProcessing(true)
    downloadReport({
      reportName: "prescription.rpt",
      param1: prescriptionId,
      param2: false,
      param3: caseId,
      param4: "",
      isExcel: false,
      forEmail: false,
      withSignature: w,
    }).then((res) => {
      setIsProcessing(false);
    });
  };

  
  const handleEditPrescription = (e) => {
    setPrescriptionId(e);

    let p = dataPrescriptions.filter((i) => i.prescriptionId === e);
    console.log(p);
    setPrescriptionDate(moment(new Date(p[0].TransDate), "YYYY-MM-DD"));

    let fdate = moment(p[0].FollowUpDate, "YYYY-MM-DD");
    if (fdate.isValid()) {
      setFollowUpDate(moment(new Date(p[0].FollowUpDate), "YYYY-MM-DD"));
    } else {
      setFollowUpDate(null);
    }
    setRemarks(p[0].Remarks);
    setPharmacy(p[0].FkIdtblPharmacyDiagnostics);

    setIsNew(true);
  };

  const handleDeletePrescription = async (e) => {
    await confirm({
      title: "Do you want to delete this prescription?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        postData({
          endPoint: "deletePrescription",
          valuestosave: {
            Id: e,
            emailaddress: settings.UserName,
          },
        }).then((res) => {
          handleRefresh();
        });
      },
      onCancel() {},
    });
  };
const handleEmail =  (e) => {
  if (emailAddress === "") {
    return message.error("Patient's Email address is required!");
  }
 setIsProcessing(true)
  downloadReport({
    reportName: "prescription.rpt",
    param1: prescriptionId,
    param2: "",
    param3: caseId,
    param4: "",
    isExcel: false,
    forEmail: true,
    emailAddress: emailAddress,
  }).then((res) => {
    message.success("Prescription successfully emailed to the patient!");
 setIsProcessing(false);
  });
};

  return (
    <div>
      <Spin spinning={isProcessing} tip="Please wait...">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Row>
              <Space>
                <Button
                  disabled={isNew}
                  type="primary"
                  onClick={() => handleNew()}
                >
                  New
                </Button>
                <Button type="primary" onClick={() => handleRefresh()}>
                  Refresh
                </Button>
              </Space>
            </Row>

            <Row>
              <Text strong>Prescriptions</Text>
            </Row>
            <Row>
              <Menu
                onClick={handleMenuDates}
                defaultSelectedKeys={prescriptionId}
                mode={"vertical"}
                theme={"light"}
              >
                {dataPrescriptions.map((value, index) => {
                  return (
                    <Menu.Item
                      onSelect={handleMenuDates}
                      key={value.prescriptionId}
                    >
                      <div>
                        {value.TransDateStr + "    "}
                        <Space>
                          <Tooltip title="Edit">
                            <Button
                              onClick={(e) =>
                                handleEditPrescription(e.target.value)
                              }
                              value={value.prescriptionId}
                              icon={<EditOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Button
                              onClick={(e) =>
                                handleDeletePrescription(e.target.value)
                              }
                              value={value.prescriptionId}
                              icon={<DeleteOutlined />}
                            ></Button>
                          </Tooltip>
                        </Space>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row>
              <Col span={24}>Trans. Date</Col>
              <Col span={24}>
                <DatePicker
                  format={"MM/DD/YYYY"}
                  placeholder="Trans. Date"
                  value={prescriptionDate}
                  onChange={(e) => setPrescriptionDate(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>Request To:</Col>
              <Col span={24}>
                <Select
                  value={pharmacy}
                  onChange={(e) => setPharmacy(e)}
                  allowClear
                  style={{ width: 300 }}
                >
                  {pharmacies.map((d) => (
                    <Option key={d.Id}>{d.Name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24}>Follow-up Date:</Col>
              <Col span={24}>
                <DatePicker
                  format={"MM/DD/YYYY"}
                  placeholder="Follow-up Date"
                  value={followUpDate}
                  onChange={(e) => setFollowUpDate(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>Remarks:</Col>
              <Col span={24}>
                <Input
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                  style={{ width: 300 }}
                />
              </Col>
            </Row>

            <Row gutter={[8, 8]} style={{ marginTop: 10 }}>
              <Space>
                <Button
                  onClick={() => {
                    setId("");
                    setShowModalAddMedicines(true);
                  }}
                  type="primary"
                  disabled={!isNew}
                >
                  
                  Add Medicine(s)
                </Button>
                <Button
                  disabled={!isNew}
                  onClick={() => handleSubmitForm()}
                  type="primary"
                >
                 
                  Save
                </Button>

                <Button
                  disabled={prescriptionId === ""}
                  type="primary"
                  onClick={() => setShowModalPrintWithSignature(true)}
                >
                  Print
                </Button>

                <Button
                  disabled={prescriptionId === ""}
                  type="primary"
                  onClick={(e) => handleEmail(e)}
                >
                 Email Prescription
                </Button>
              </Space>
            </Row>

            <Row gutter={[8, 8]} style={{marginTop:10}}>
              <Col span={24}>
                <Table
                  rowKey={(record) => record.Id}
                  size="small"
                  columns={columns}
                  dataSource={data}
                  pagination={{ pageSize: 50 }}
                  scroll={{ y: 340 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          visible={showModalAddMedicines}
          onOk={handleCloseAddMedicines}
          onCancel={handleCloseAddMedicines}
          width={1200}
          footer={null}
        >
          <Form
            {...formItemLayout}
            form={form}
            onFinish={() => {
              handleSubmitForm();
            }}
          >
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item label="Generic name" name="GenName">
                  <AutoComplete
                    value="GenName"
                    options={genname}
                    placeholder="Please select"
                    onChange={(e) => setBName(e)}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    rules={[
                      {
                        required: true,
                        message: "Required field!",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item label="Brand name" name="BrandName">
                  <AutoComplete
                    value="BrandName"
                    options={brandname}
                    placeholder="Please select"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>

                <Form.Item label="Dosage" name="Dosage">
                  <AutoComplete
                    value="BrandName"
                    options={dosage}
                    placeholder="Please select"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
                <Form.Item label="Form" name="Form">
                  <AutoComplete
                    value="Form"
                    options={formx}
                    placeholder="Please select"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Quantity"
                  name="Qty"
                  rules={[
                    {
                      required: true,
                      message: "Required field!",
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item label="Duration Start" name="DurationFrom">
                  <DatePicker format={"MM/DD/YYYY"} />
                </Form.Item>
                <Form.Item label="End" name="DurationTo">
                  <DatePicker format={"MM/DD/YYYY"} />
                </Form.Item>
                <Form.Item label="Frequency" name="Frequency">
                  <AutoComplete
                    value="Form"
                    options={frequency}
                    placeholder="Please select"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>

                <Form.Item label="Note" name="Note">
                  <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    onClick={() => handleAddItem()}
                    disabled={!isNew}
                    type="primary"
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
      <Modal
        title="Print Prescription with doctor's signature?"
        visible={showModalPrintWithSignature}
        onOk={() => handlePrint(true)}
        onCancel={() => handlePrint(false)}
        okText="Yes"
        cancelText="No"
      ></Modal>
    </div>
  );
};

export default Prescription;
