import React, { createContext, useState } from "react";

export const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    UserName: "",
    fullName: "",
    profilePic: "",
    appName: "AnywhereMed",
    userId: "",
    isSaving: false,
    apiUrl: "",
    isLogged: false,
    isAdministrator: false,
    AccessToHimsPatients: false,
    appointmentCount: 0,
    isDoctor: false,
    genericNames: [],
    dosage: [],
    formx: [],
    icd: [],
    refreshSoap:false,
    isHospitalAdmin:false,
    caseId:""
  });

  const updateSettings = (s) => {
    setSettings(s);
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
