import React, {useEffect, useState, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import appJson from "../../assets/app.json";
import CanvasDraw from "react-canvas-draw";
import { CompactPicker } from "react-color";
import { SettingsContext } from "../../settingsContext.js";
import {
  Button,
  Input,
  Typography,
  Space,
  message,
  Select,
  Modal,
  Row,
  Col,
  DatePicker,
} from "antd";
import Templates from "./Templates";
import {
  getListOfRecords2,
  postData,
  getListOfRecords,
} from "../DataService.js";
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
function ImageRecord(props) {
  const canvasArea = useRef();
  const { settings } = useContext(SettingsContext);
  const fileInput = useRef(null);
  const [brushRadius, setBrushRadius] = useState(5);
  const [color, setColor] = useState();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [url, setUrl] = useState(props.location.state.url);
  const [imgSource, setImgSource] = useState(props.location.state.url);
  const [transDate, setTransDate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [notes, setNotes] = useState("");
  const [templateId, setTemplateId] = useState(props.templateId);
  const [templateName, setTemplaName] = useState(props.templateName);
  const [patientId, setPatientId] = useState(props.patientId);
  const [Id, setId] = useState(props.location.state.Id);
  const [imageRecord, setImageRecord] = useState([]);
  const [reload, setReload] = useState(false);
  const [savedData, setSaveData] = useState(props.location.state.savedData);
  const [refVisible, setRefVisible] = useState(false); 
  const handleChangeComplete = (color) => {
    setColor(color.hex);
    setDisplayColorPicker(false);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "20px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const handlePickFile = () => {
    fileInput.current.click();
  };
  const onFilePicked = (files) => {
    console.log(files[0]);

    if (files[0] === undefined) return false;
    setFileList(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileList(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      let url = fileReader.result;
      var img = new Image();
      img.src = url;

      setImgSource(img.src);
    });
    fileReader.readAsDataURL(files[0]);
  };
  const handleShowAddTemplate = () => {
    setShowTemplate(true);
  };

  const handleCloseTemplates = () => {
    setShowTemplate(false);
  };

  const handleGetTemplates = async () => {
    const query =
      "select * from tblImageTemplates where doctorid = '" +
      props.location.state.doctorId +
      "' order by templatename";

    await getListOfRecords2(query).then((res) => {
      if (res) {
        setTemplates(res);
      }
    });
  };

  const handleGetPreviousRecords = async () => {
    //udp_GetListOfImageRecord
    const payLoad = {
      sp: "udp_GetListOfImageRecord",
      param1: patientId,
    };

    getListOfRecords(payLoad).then((res) => {
      if (res) {
        setImageRecord(res);
      }
    });
  };

  const handleChangeTemplate = (e) => {
    let apiUrl = appJson.apiUrl;

    setUrl(apiUrl + "api/getTemplate?id=" + e);

    setTransDate(moment(new Date(), "MM/DD/YYYY hh:mm A"));
  };

  const handleCreateNewRecord = () => {
    if (url == "") {
      return message.error("Please select a template!");
    }
    props.history.push({
      pathname: "/imagetemplate",
      state: {
        doctorId: settings.userId,
      },
    });
  };
  const handleClear = () => {
    canvasArea.current.clear();
  };
  const handleChangeTransDate = (date, dateString) => {
    setTransDate(dateString);
  };
  const handleSave = () => {
    if (templateId === 0) {
      return message.error("Please select a template!");
    }

    const valuestosave = {
      caseId: props.caseId,
      imageData: canvasArea.current.getSaveData(),
      templateId: templateId,
      notes: notes,
      transDate: transDate,
      templateName: templateName,
      emailaddress: settings.UserName,
      Id: Id,
    };
    const payLoad = {
      endPoint: "saveImageRecord",
      valuestosave: valuestosave,
    };

    console.log(payLoad);
    postData(payLoad).then((res) => {
      if (res.status === 1) {
        message.success("Successfully saved!");

        setId(res.param1);
      } else {
        message.error(res.message);
      }
    });
  };

  const handleChangeNotes = (e) => {
    setNotes(e.target.value);
  };
  const handleLoadImageRecord = () => {
    console.log(savedData);
    // const savedData = t[0].SavedData;
    // canvasArea.current.loadSaveData(t[0].SavedData);
  };

  const handleLoadRecord =  () => {
    canvasArea.current.loadSaveData(savedData);
  };

  useEffect(() => {
    if (Id === "") {
      setTransDate(moment(new Date()).format("MM/DD/YYYY hh:mm A"));
    } else {
     // handleLoadRecord();
     console.log(canvasArea.current)
      canvasArea.current.loadSaveData(localStorage.getItem("savedData"));
      canvasArea.current.drawImage();
    }
  }, []);

/*   useEffect(() => {
    if (!refVisible) {
      return;
    }
        console.log(canvasArea);
  }, refVisible);
 */

  /*   useState(() => {
    console.log(props.imageRecordId);
    if (props.imageRecordId !== "") {
      handleLoadImageRecord();
    }
  }, [props.imageRecordId]); */

  const handleChangePreviousRecord = (e) => {
    /*  let apiUrl = appJson.apiUrl;
        setId(e);
        const t = imageRecord.filter(f=>f.Id==e);
        setUrl(apiUrl + "api/getTemplate?id=" + t[0].FkIdtblImageTemplates);
        
        setSaveData(t[0].SavedData);
       canvasArea.current.loadSaveData(t[0].SavedData); */
    // canvasArea.current.drawImage();

    let apiUrl = appJson.apiUrl;
    const t = imageRecord.filter((f) => f.Id == e);

    const url = apiUrl + "api/getTemplate?id=" + t[0].FkIdtblImageTemplates;
    const savedData = t[0].SavedData;

    props.handleChangePreviousRecord(
      e,
      url,
      savedData,
      "Body Map",
      t[0].FkIdtblImageTemplates
    );
  };

  return (
    <div>
      <div>
        <Text strong>Name of patient</Text>
      </div>

      <Row style={{ marginBottom: 10 }}>
        <Space>
          <Button type="primary" onClick={() => handleClear()}>
            Clear
          </Button>
          <Button type="primary" onClick={handleClick}>
            Change Color
          </Button>

          {displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={handleClose} />
              <CompactPicker onChange={handleChangeComplete} />
            </div>
          ) : null}
        </Space>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Text strong>Previous Record</Text>
        </Col>
        <Col span={24}>
          <Select
            style={{ width: 400 }}
            onSelect={(e) => handleChangePreviousRecord(e)}
          >
            {imageRecord.map((d) => (
              <Option key={d.Id}>{d.Description}</Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <CanvasDraw
              ref={canvasArea}
              brushColor={color}
              canvasWidth={700}
              canvasHeight={500}
              brushRadius={8}
              lazyRadius={0}
              imgSrc={url}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={(8, 8)}>
        <Col span={24}>
          <Text strong>Date</Text>
        </Col>
      </Row>
      <Row gutter={(8, 8)}>
        <Col span={24}>
          <DatePicker
            format={"MM/DD/YYYY hh:mm A"}
            onChange={handleChangeTransDate}
            value={moment(new Date(transDate), "MM/DD/YYYY hh:mm A")}
            //  showTime={{ format: "hh:mm" }}
            showTime={{ user12hours: true }}
          />
        </Col>
      </Row>

      <Row gutter={(8, 8)}>
        <Col span={24}>
          <Text strong>Notes</Text>
        </Col>
      </Row>
      <Row gutter={(8, 8)}>
        <Col span={24}>
          <TextArea
            autoSize={{ minRows: 5, maxRows: 5 }}
            value={notes}
            onChange={handleChangeNotes}
          />
        </Col>
      </Row>

      <Row gutter={(8, 8)} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Button type="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </Col>
      </Row>

      <input
        style={{ display: "none" }}
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => onFilePicked(e.target.files)}
      />

      <Modal
        visible={showTemplate}
        onOk={handleCloseTemplates}
        onCancel={handleCloseTemplates}
        width={700}
        destroyOnClose={true}
      >
        <Templates doctorId={settings.userId} />
      </Modal>
    </div>
  );
}

export default withRouter(ImageRecord);
