import React, { useContext, useEffect, useState, useForm } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
import { SettingsContext } from "../../settingsContext.js";
import SoapComponent from "./SoapComponent";
import Teleconsultation from "./Teleconsultation";
import VitalSigns from "./VitalSigns";
import Prescription from "./Prescription";
import DiagnosticRequest from "./DiagnosticRequest";
import HimsResults from "./HimsResults";
import ImagesDocuments from "./ImagesDocuments";
import MedicalCertificate from "./MedicalCertificate";
import ReferralForm from "./ReferralForm";
import Allergies from "./Allergies";
import ImageTemplate from "./ImageTemplate";
import {
  postData,
  getHimsPatientInfo,
  getRecord,
  getListOfRecords,
  getListOfRecords2
} from "../DataService.js";
import {
  Form,
  Layout,
  Typography,
  Tabs,
  Row,
  Col,
  Button,
  Modal,
  Space,
  Badge,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { faStethoscope,faTv } from "@fortawesome/free-solid-svg-icons";
const { Header, Content } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;
const { Title } = Typography;
const h = window.screen.height-200;
const Soap = (props) => {

  const { settings,updateSettings } = useContext(SettingsContext);
  const [Id, setId] = useState(props.location.state.Id);
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [sex, setSex] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [bp, setBp] = useState("");
  const [rr, setRr] = useState("");
  const [hr, setHr] = useState("");
  const [himsPatientNo, setHimsPatientNo] = useState("");
  const [showModalVitalSigns, setShowModalVitalSigns] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [showTeleconsultation,setShowTeleconsultation]=useState(false)
  const [showAllergies, setShowAllergies] = useState(false)
  const [allergy,setAllergy] = useState([])
  const [remountKey,setRemountKey] = useState("")
  const handleGetAppointmentDetails = async () => {
     localStorage.setItem("caseId",Id);
    let payLoad = {
      sp: "udp_GetAppointmentDetails",
      param1: Id,
    };
    await getRecord(payLoad).then((res) => {
    
      setPatientName(res.PatientName);
      setAge(res.Age);
      setSex(res.Gender);
      setPatientId(res.FktblPatientInformationId);
      setEmailAddress(res.EmailAddress);
      setHimsPatientNo(res.himsPatientNo);
    });

    handleGetVitalSigns();
  };
    const handleGetAllergies = async () => {
      const query =
        "select * from tblAllergies where FktblPatientInformationId = '" +
        patientId +
        "' and deletedby is null";

      await getListOfRecords2(query).then((res) => {
        if (res) {
          setAllergy(res);
        }
      });
    };
  const handleGetVitalSigns = async () => {
    const payLoad = {
      sp: "udp_GetLatestVitalSigns",
      param1: Id,
    };

    await getRecord(payLoad).then((res) => {
      if (res) {
        setWeight(res.Weight);
        setHeight(res.Height);
        setBmi(res.Bmi2);
        setBp(res.Systolic + "/" + res.Diastolic);
        setRr(res.Rr);
        setHr(res.Hr);
      }
    });
  };
  useEffect(() => {
    if (!settings.isDoctor) {
      props.history.push("/accessdenied");
    }

    handleGetAppointmentDetails();
    handleGetAllergies();
  }, [handleGetAppointmentDetails, props.history, settings.isDoctor]);

  const handleCloseVitalSigns = () => {
    setShowModalVitalSigns(false);

    handleGetVitalSigns();
  };
  const handleCloseAllergies = () => {
    setShowAllergies(false);
    handleGetAllergies();

  };
  

  const handleTeleconsultation=()=>{
        updateSettings({
          ...settings,
          refreshSoap: false,
        });
    setShowTeleconsultation(true)
  }
  const handleCloseTeleconsultation=()=>
  {
    setShowTeleconsultation(false);
   
         updateSettings({
           ...settings,
          refreshSoap:true
         });



  }

  const handleClickTab=(e)=>
  {


    

  }

const handleTemplateImages=()=>{
     props.history.push({
       pathname: "/imagetemplate",
       state: {
         doctorId: settings.userId,
         caseId:Id,
         patientId:patientId
       },
     });
}
  return (
    <div>
      <Layout>
        <Header className="site-layout-background" style={{ width: "100%" }}>
          <div style={{ height: 40 }}>
            <Title level={4}>
              Name:{patientName} Age:{age} Sex:{sex}
            </Title>
          </div>
          <div style={{ height: 40 }}>
            <Title level={4}>
              Weight:{weight} Height:{height} Bmi:{bmi} BP:{bp} Hr:{hr} Rr:{rr}{" "}
              <Space>
                <Button
                  type="primary"
                  onClick={() => setShowModalVitalSigns(true)}
                >
                  Vital Signs
                </Button>

                <Badge count={allergy.length <= 0 ? null : allergy.length}>
                  <Button
                    type="primary"
                    onClick={() => setShowAllergies(true)}
                  >
                    Allergies
                  </Button>
                </Badge>
                <Button
                  type="primary"
                  onClick={ handleTemplateImages}
                >
                  Template Images
                </Button> 
              </Space>
            </Title>
          </div>
        </Header>
      </Layout>
      <Content>
        <Tabs defaultActiveKey="1" onChange={handleClickTab}>
          <TabPane tab="Soap" key="1">
            <Row>
              <Col span={24}>
                <SoapComponent caseId={Id} patientId={patientId} />
              </Col>
            </Row>
          </TabPane>
          {/*    <TabPane tab="Image Template" key="8">
            <Row>
              <Col span={24}>
                <ImageTemplate
                  caseId={Id}
                  patientId={patientId}
                  doctorId={settings.userId}
                  key={remountKey}
                />
              </Col>
            </Row>
          </TabPane> */}
          {/*     <TabPane tab="Vital Signs" key="2">
            <Row>
              <Col>
                <VitalSigns caseId={Id} patientId={patientId} />
              </Col>
            </Row>
          </TabPane> */}
          <TabPane tab="Prescriptions" key="2">
            <Row>
              <Col span={24}>
                <Prescription
                  caseId={Id}
                  patientId={patientId}
                  emailAddress={emailAddress}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Diagnostic Requests" key="3">
            <Row>
              <Col span={24}>
                <DiagnosticRequest
                  caseId={Id}
                  patientId={patientId}
                  emailAddress={emailAddress}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="HIMS Results" key="4">
            <Row>
              <Col span={24}>
                <HimsResults himsPatientNo={himsPatientNo} />
              </Col>
            </Row>
          </TabPane>
          {/*           <TabPane tab="HIMS Results" key="8">
            <Row>
              <Col span={24}>
                <HimsResults himsPatientNo={himsPatientNo} />
              </Col>
            </Row>
          </TabPane> */}
          <TabPane tab="Images" key="5">
            <Row>
              <Col span={24}>
                <ImagesDocuments patientId={patientId} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Medical Certificates" key="6">
            <Row>
              <Col span={24}>
                <MedicalCertificate
                  emailAddress={emailAddress}
                  patientId={patientId}
                  caseId={Id}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Referral Form" key="7">
            <Row>
              <Col span={24}>
                <ReferralForm
                  emailAddress={emailAddress}
                  patientId={patientId}
                  caseId={Id}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Content>
      <Modal
        visible={showModalVitalSigns}
        onOk={handleCloseVitalSigns}
        onCancel={handleCloseVitalSigns}
        width={1000}
      >
        <VitalSigns caseId={Id} patientId={patientId} />
      </Modal>

      <Modal
        visible={showAllergies}
        onOk={handleCloseAllergies}
        onCancel={handleCloseAllergies}
        width={1000}
        destroyOnClose={true}
      >
        <Allergies caseId={Id} patientId={patientId} />
      </Modal>

      <Modal
        visible={showTeleconsultation}
        onOk={handleCloseTeleconsultation}
        onCancel={handleCloseTeleconsultation}
        width={window.screen.width - 100}
        //height={window.screen.height * window.devicePixelRatio}
        centered
        bodyStyle={{ height: h }}
      >
        <Teleconsultation caseId={Id} patientId={patientId} />
      </Modal>
    </div>
  );
};

export default withRouter(Soap);
