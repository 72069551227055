import React, { useContext, useEffect, useState, useForm } from "react";
import { SettingsContext } from "../../settingsContext.js";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  postData,
  getHimsPatientInfo,
  getRecord,
  getListOfRecords2,
} from "../DataService.js";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Checkbox,
  Tabs,
  message,
  AutoComplete,
  Breadcrumb,
  Avatar,
  Card,
  Typography,
  DatePicker,
  Select,
  Space,
  Modal,
  Spin,

} from "antd";
import { Link } from "react-router-dom";
import {
  SaveOutlined,
  FileImageOutlined,
  CloudUploadOutlined,
  FormOutlined,
  HomeOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const PatientInformation = (props) => {
  const [form] = Form.useForm();
  const { settings, updateSettings } = useContext(SettingsContext);
  const AccessToHimsPatients = settings.AccessToHimsPatients;
  const [Id, setId] = useState(props.location.state.Id);
  const [himsPatientNo] = useState(props.location.state.himsPatientNo);
  const [isSaving, setIsSaving] = useState(false);
  const [DateOfBirth, setDateOfBirth] = useState("");
  const returnUrl = props.location.state.returnUrl;
  const [firstLoad, setFirstLoad] = useState(true);
  const [sex, setSex] = useState([]);
  const [civilStatus, setCivilStatus] = useState([]);
  const [patientId,setPatientId] = useState("")
const [bloodType,setBloodType] = useState([])

  const handleInititalValues = async () => {
    let settings = [];

    await getListOfRecords2(
      "select * from tblSettings where code='GENDER' order by description"
    ).then((res) => {
      if (res) {
        let x = [];
        res.forEach((element) => {
          x.push(<Option key={element.Valuex}>{element.Description}</Option>);
        });
        setSex(x);
      }
    });


    await getListOfRecords2(
      "select * from tblSettings where code='CIVILSTATUS' order by description"
    ).then((res) => {
      if (res) {
        let x = [];
        res.forEach((element) => {
          x.push(<Option key={element.Valuex}>{element.Description}</Option>);
        });
        setCivilStatus(x);
      }
    });


    await getListOfRecords2(
      "select * from tblSettings where code='BLOODTYPE' order by description"
    ).then((res) => {
      if (res) {
        let x = [];
        res.forEach((element) => {
          x.push(<Option key={element.Valuex}>{element.Description}</Option>);
        });
        setBloodType(x);
      }
    });
  };

  useEffect(() => {
    handleInititalValues();
  }, []);

  useEffect(() => {
    if (himsPatientNo !== "") {
      getHimsPatientInfo(himsPatientNo).then((res) => {
        setDateOfBirth("")
        if (res.dobstr !== null) {
          setDateOfBirth(res.dobstr);
        }
        
      
        let s1 = "";
        if (res.sex === "Female") {
          s1 = "F";
        } else if (res.sex === "Male") {
          s1 = "M";
        } else s1 = "N";
       

        form.setFieldsValue({
          HimsPatientNo: himsPatientNo,
          Lastname: res.lastname,
          Firstname: res.firstname,
          Middlename: res.middlename,
          Address: res.address,
          Dob:
            res.dobstr !== null
              ? moment(new Date(res.dobstr), "MM/DD/YYYY")
              : "",
          Gender: s1,
          CivilStatus: "X",
        });

      setPatientId("")

      });
    }
  }, []);

  useEffect(() => {
    if (Id !== "") {
      const payLoad = {
        sp: "udp_GetPatientInformation",
        param1: Id,
        param2: "",
        param3: "",
        param4: "",
      };

      getRecord(payLoad).then((res) => {
        if (res.dobstr !== null) {
          setDateOfBirth(res.dobstr);
        }
        setPatientId(res.PatientId);
        form.setFieldsValue({
          HimsPatientNo: res.HimsPatientNo,
          PatientId: res.PatientId,
          Lastname: res.Lastname,
          Firstname: res.Firstname,
          Middlename: res.Middlename,
          Suffix: res.Suffix,
          Dob:
            res.dobstr !== null
              ? moment(new Date(res.dobstr), "MM/DD/YYYY")
              : "",
          Address: res.Address,
          Gender: res.Gender,
          CivilStatus: res.CivilStatus,
          BloodType: res.BloodType,
          EmailAddress: res.EmailAddress,
          PrimaryMobileNo: res.PrimaryMobileNo,
          OtherMobileNo: res.OtherMobileNo,
          EmergencyContactName: res.EmergencyContactName,
          EmergencyContactNo: res.EmergencyContactNo,
          EmergencyContactRelationship: res.EmergencyContactRelationship,
          ReferringPhysician: res.ReferringPhysician,
          PrimaryCarePhysician: res.PrimaryCarePhysician,
          OtherPhysician1: res.OtherPhysician1,
          OtherPhysician2: res.OtherPhysician2,
        });
      });
    } else {
      form.setFieldsValue({
        BloodType: "N/A",
      });
    }
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };

  const handleChangeDateOfBirth = (date, dateString) => {
    setDateOfBirth(dateString);
  };

  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();

      setIsSaving(true);

      const valuestosave = {
        ...values,
        emailaddress: settings.UserName,
        Id: Id,
        DateOfBirth: DateOfBirth,
      };
      const payLoad = {
        endPoint: "savepatientinformation",
        valuestosave: valuestosave,
      };
      postData(payLoad).then((res) => {
        if (res.status === 1) {
          setId(res.param1);

          Modal.success({
            centered: true,
            title: settings.appName,
            content: "Successfully saved!",
            onOk: () => {},
          });

          setId(res.param1);
          const patientId = form.getFieldValue("PatientId");
          console.log(patientId)
          if (patientId === undefined ) {
            form.setFieldsValue({ PatientId: res.patientId });
            setPatientId(res.patientId)
          }
        } else {
          Modal.error({
            centered: true,
            title: settings.appName,
            content: res.message,
            onOk: () => {},
          });
        }
      });
      setIsSaving(false);
    } catch (errorInfo) {}
  };

  const handleAppointmentDetails = () => {
    props.history.push({
      pathname: "/appointmentdetails",
      state: {
        patientId: Id,
        Id: "",
        returnUrl: "/clinicpatients",
      },
    });
  };
  return (
    <>
      <Spin spinning={isSaving} tip="Saving Please wait...">
        <Layout>
  
          <Content >
            <Row>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Card
                  hoverable={true}
                  style={{ width: "100%" }}
                  // title={<Title level={4}>Patient Information</Title>}
                >
                  <Space>
                    <Button
                      type="link"
                      icon={<HomeOutlined />}
                      onClick={() => props.history.push("/")}
                      loading={isSaving}
                    >
                      Home
                    </Button>
                    <Button
                      type="link"
                      icon={<FormOutlined />}
                      onClick={() => props.history.push("/clinicpatients")}
                      loading={isSaving}
                    >
                      Clinic Patients
                    </Button>
                    <Button
                      type="link"
                      icon={<FormOutlined />}
                      onClick={() => props.history.push("/himspatients")}
                      loading={isSaving}
                    >
                      Hims Patients
                    </Button>
                  </Space>
                  <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={() => {
                      handleSubmitForm();
                    }}
                  >
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Patient Information" key="1">
                        <div>
                          <Form.Item
                            label="Hims Patient No."
                            name="HimsPatientNo"
                          >
                            <Input disabled />
                          </Form.Item>

                          <Form.Item
                            label="Patient ID."
                            name="PatientId"
                            rules={[
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Text strong>{patientId}</Text>
                          </Form.Item>

                          <Form.Item
                            label="Lastname"
                            name="Lastname"
                            rules={[
                              {
                                required: true,
                                message: "Required field!",
                              },
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label="Firstname"
                            name="Firstname"
                            rules={[
                              {
                                required: true,
                                message: "Required field!",
                              },
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Middlename"
                            name="Middlename"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Suffix"
                            name="Suffix"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 20,
                                message: "Maximum characters 20!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Sex"
                            name="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Required field!",
                              },
                            ]}
                          >
                            <Select allowClear placeholder="Please select!">
                              {sex}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label="Civil Status"
                            name="CivilStatus"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                            ]}
                          >
                            <Select allowClear placeholder="Please select!">
                              {civilStatus}
                            </Select>
                          </Form.Item>

                          <Form.Item label="Date of birth" name="Dob">
                            <DatePicker
                              format={"MM/DD/YYYY"}
                              onChange={handleChangeDateOfBirth}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Address"
                            name="Address"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 500,
                                message: "Maximum characters 500!",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>

                          <Form.Item
                            label="BloodType"
                            name="BloodType"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                            ]}
                          >
                            <Select allowClear placeholder="Please select!">
                              {bloodType}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label="Email Address"
                            name="EmailAddress"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 100,
                                message:
                                  "Maximum characters for email address is 100!",
                              },
                              {
                                type: "email",
                                message: "Invalid email address!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            label="Primary MobileNo"
                            name="PrimaryMobileNo"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Other MobileNo"
                            name="OtherMobileNo"
                            rules={[
                              {
                                required: false,
                                message: "Required field!",
                              },
                              {
                                max: 50,
                                message: "Maximum characters 50!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </TabPane>
                      <TabPane tab="Emergency Contact Information" key="2">
                        <Form.Item
                          label="Name"
                          name="EmergencyContactName"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Contact No."
                          name="EmergencyContactNo"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Relationship"
                          name="EmergencyContactRelationship"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </TabPane>
                      <TabPane tab="Physician Information" key="3">
                        <Form.Item
                          label="Referring Physician"
                          name="ReferringPhysician"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Primary Care Physician"
                          name="PrimaryCarePhysician"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Other Physician 1"
                          name="OtherPhysician1"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Other Physician 2"
                          name="OtherPhysician2"
                          rules={[
                            {
                              required: false,
                              message: "Required field!",
                            },
                            {
                              max: 100,
                              message: "Maximum characters 100!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </TabPane>
                    </Tabs>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Row style={{marginTop:10}}>
              <Col offset={5}>
                <Space>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => handleSubmitForm()}
                    loading={isSaving}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    icon={<FileDoneOutlined />}
                    onClick={() => handleAppointmentDetails()}
                    loading={isSaving}
                    disabled={Id === ""}
                  >
                    Set An Appointment
                  </Button>
                </Space>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </>
  );
};

export default withRouter(PatientInformation);
