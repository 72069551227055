import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { postData, getHimsPatientInfo, getRecord } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Checkbox,
  Tabs,
  message,
  AutoComplete,
  Breadcrumb,
  Avatar,
  Card,
  Typography,
  DatePicker,
  Select,
  Space,
  Modal,
  Spin,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
const { Header, Content } = Layout;
const ChangePassword2 = (props) => {
  const { settings, updateSettings } = useContext(SettingsContext);
  const [isSaving, setIsSaving] = useState(false);
  const [Id,setId]=useState(props.location.state.Id)
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 7,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 19,
        offset: 7,
      },
    },
  };
  const handleLogOut = () => {
    localStorage.removeItem("tokenid");
    updateSettings({
      ...settings,
      UserName: "",
      fullName: "",
      profilePic: "",
      userId: "",
      isLogged: false,
      isAdministrator: false,
      AccessToHimsPatients: false,
    });
    props.history.push("/login");
  };
  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();

      setIsSaving(true);

      const valuestosave = {
        userId: Id,
        newpassword: values.Password,
        emailaddress: settings.UserName,
      };
      const payLoad = {
        endPoint: "changepassword2",
        valuestosave: valuestosave,
      };
      postData(payLoad).then((res) => {
        if (res.status === 1) {
          Modal.success({
            centered: true,
            title: settings.appName,
            content: "Successfully saved!",
            onOk: () => {
              props.history.push("/users")
            },
          });
        } else {
          Modal.error({
            centered: true,
            title: settings.appName,
            content: "Invalid old password!",
            onOk: () => {},
          });
        }
      });
      setIsSaving(false);
    } catch (errorInfo) {}
  };

  return (
    <>
      <Spin spinning={isSaving} tip="Saving Please wait...">
        <h1>Change Password</h1>
        <Row>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Card
              hoverable={true}
              style={{ width: "100%" }}
              // title={<Title level={4}>Patient Information</Title>}
            >
              <Form
                {...formItemLayout}
                form={form}
                onFinish={() => {
                  handleSubmitForm();
                }}
              >
               {/*  <Form.Item
                  label="Old Password"
                  name="OldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Old Password is required!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item> */}

                <Form.Item
                  label="Password"
                  name="Password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required!",
                    },
                    {
                      min: 3,
                      message: "Minimum password characters is 3!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="ConfirmPassword"
                  dependencies={["Password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("Password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    loading={isSaving}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default withRouter(ChangePassword2);
