import React, { useState, useCallback, useEffect, useContext } from "react";
import { getListOfRecords } from "../DataService.js";
import { SettingsContext } from "../../settingsContext.js";
import { withRouter } from "react-router-dom";
import { FaNotesMedical } from "react-icons/fa";
import moment from "moment";
import VitalSigns from "../Soap/VitalSigns";
import PaymentStatus from "./PaymentStatus";
import AppointmentStatus from "./AppointmentStatus.jsx"
import "./style.css";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Space,
  Input,
  Spin,
  DatePicker,
  Popover,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import {
  UserAddOutlined,
  SelectOutlined,
  SearchOutlined,
  EditOutlined,
  CreditCardOutlined,
  ArrowRightOutlined,
  MedicineBoxOutlined,
  DownOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons";

const { Search } = Input;
const Appointments = (props) => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { settings, updateSettings } = useContext(SettingsContext);
  const AccessToHimsPatients = settings.AccessToHimsPatients;
  const [patientName, setPatientName] = useState("");
  const [showAppointmentStatus, setShowModalAppointmentStatus] = useState(false)
  const [dateFrom, setDateFrom] = useState(
    props.location.state.dFrom === ""
      ? moment(new Date()).format("MM/DD/YYYY")
      : moment(new Date(props.location.state.dFrom)).format("MM/DD/YYYY")
  );
  const [dateTo, setDateTo] = useState(
    props.location.state.dTo === ""
      ? moment(new Date()).format("MM/DD/YYYY")
      : moment(new Date(props.location.state.dTo)).format("MM/DD/YYYY")
  );
  const [patientid, setPatientId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [noOfAppointments, setNoOfAppointments] = useState(0);
  const [caseId, setCaseId] = useState("");
  const [Id, setId] = useState(false);
  const [showModalVitalSigns, setShowModalVitalSigns] = useState(false);
  const [showModalPaymentStatus,setshowModalPaymentStatus] = useState(false)

  const handleNew = () => {
    props.history.push({
      pathname: "/patientinformation",
      state: {
        Id: "",
        himsPatientNo: "",
        returnUrl: "/clinicpatients",
      },
    });
  };

  const handleAppointment = (x) => {
    props.history.push({
      pathname: "/appointmentdetails",
      state: {
        patientId: x,
        Id: "",
        returnUrl: "/clinicpatients",
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit()}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<MedicineBoxOutlined />} onClick={() => handleSoap()}>
        Soap
      </Menu.Item>
      <Menu.Item
        icon={<MedicineBoxOutlined />}
        onClick={() => handleVitalSigns()}
      >
        Vital Signs
      </Menu.Item>

      <Menu.Item
        icon={<MoneyCollectOutlined />}
        onClick={() => handlePaymentStatus()}
      >
        Payment Status
      </Menu.Item>

      <Menu.Item
        icon={<FaNotesMedical />}
        onClick={() => handleAppointmentStatus()}
      >
        Appointment Status
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      dataIndex: "operation",
      width:100,
      render: (text, record) =>
        data.length >= 1 ? (
          <span className="table-operation">
            <Dropdown
              overlay={menu}
              onClick={() => setCaseId(record.Id)}
              trigger={["click"]}
            >
              <a>
                Action <DownOutlined />
              </a>
            </Dropdown>
          </span>
        ) : null,
    },
    {
      title: "Patient ID",
      dataIndex: "PatientId",
    
    },
    {
      title: "Name",
      dataIndex: "Name",

      sorter: (a, b) => a.Name.length - b.Name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Doctor",
      dataIndex: "DoctorsName",
    },
    {
      title: "Type of Consultation",
      dataIndex: "TypeOfConsultationStr",
    },
    {
      title: "Reason for Visit",
      dataIndex: "ReasonForVisitDesc",
    },
    {
      title: "Date",
      dataIndex: "TransDateStr",
    },
    {
      title: "Payment Status",
      dataIndex: "PaymentStatus",
    },
    {
      title: "Status",
      dataIndex: "StatusDesc",
    }
  ];
  const handleEdit = () => {
    props.history.push({
      pathname: "/appointmentdetails",
      state: {
        Id: caseId,
        returnUrl: "/appointments",
      },
    });
  };





  useEffect(() => {
 
   

    handleSearch();
    //updateSettings({ ...settings, appointmentCount: data.length });
  }, []);

  const handleCloseVitalSigns = () => {
    setPatientId("");
    setShowModalVitalSigns(false);
 
  };

  const handleClosePaymentStatus=()=>{
    setshowModalPaymentStatus(false)
        handleSearch();
  }
  const handleSearch =async () => {
    
     const payLoad = {
      sp:"udp_AppointmentPerDoctor",
      param1: settings.isDoctor === true ? settings.userId : "",
      param2: patientName,
      param3: dateFrom,
      param4: dateTo,
    };

    await getListOfRecords(payLoad).then((res) => {
   
      setData(res);
    }); 

    // updateSettings({ ...settings, appointmentCount: data.length });
  };

  const rowSelection = {
    hideDefaultSelections: true,
    selectedRowKeys,
    selections: [],
    type: "radio",
  };

  const handleSoap = () => {
    props.history.push({
      pathname: "/soap",
      state: {
        Id: caseId,
        returnUrl: "/appointments",
      },
    });
  };
  const handleSelectRow = (record) => {
    const selectedRowKeys = [];

    if (selectedRowKeys.indexOf(record.Id) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.Id), 1);
    } else {
      selectedRowKeys.push(record.Id);
    }

    setSelectedRowKeys(selectedRowKeys);
  };
  const setRowClassName = (record) => {
    return record.Id === selectedRowKeys[0] ? "clickRowStyl" : "";
  };
  const handleVitalSigns = () => {
    const p = data.filter((i) => i.Id === caseId);
    console.log(p[0]);
    setPatientId(p[0].FktblPatientInformationId);
    setShowModalVitalSigns(true);
  };

    const handlePaymentStatus = () => {
      const p = data.filter((i) => i.Id === caseId);
      setPatientId(p[0].FktblPatientInformationId);
      setshowModalPaymentStatus(true);
    };


    const   handleAppointmentStatus = () => {
   
      setShowModalAppointmentStatus(true);
    };


    const handleCloseAppointmentStatus = () => {
      setShowModalAppointmentStatus(false);
      handleSearch();
    };


  return (
    <div>
      <h1>Appointments</h1>

      <Card hoverable="true">
        <Row gutter={[16, 16]}>
          <Col>
            <Space>
              <Input
                placeholder="Encode string to search!"
                onChange={(e) => setPatientName(e.target.value)}
                //  onPressEnter={(e) => handleSearch(e)}
                style={{ width: 250 }}
              />

              <DatePicker
                placeholder="Date From"
                defaultValue={moment(new Date(), "MM/DD/YYYY")}
                format={"MM/DD/YYYY"}
                onChange={(date, dateString) => {
                  let ddate = moment(new Date(dateString), "MM/DD/YYYY");
                  setDateFrom(dateString);
                }}
                value={moment(new Date(dateFrom))}
              />

              <DatePicker
                placeholder="Date To"
                defaultValue={moment(new Date(), "MM/DD/YYYY")}
                format={"MM/DD/YYYY"}
                onChange={(date, dateString) => {
                  let ddate = moment(new Date(dateString), "MM/DD/YYYY");
                  setDateTo(dateString);
                }}
                value={moment(new Date(dateTo))}
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Table
            rowKey={(record) => record.Id}
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
            onRow={(record) => ({
              onClick: () => {
                handleSelectRow(record);
              },
            })}
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <p style={{ margin: 0 }}>
                    Case No: <strong>{record.CaseNo}</strong>
                  </p>
                  <p style={{ margin: 0 }}>Added By:{record.AddedBy}</p>
                  <p style={{ margin: 0 }}>Date Added:{record.DateAdded}</p>
                </div>
              ),
              rowExpandable: (record) => record.Id !== "Not Expandable",
            }}
          />
        </Row>
      </Card>

      <Modal
        visible={showModalVitalSigns}
        onOk={handleCloseVitalSigns}
        onCancel={handleCloseVitalSigns}
        width={1200}
        destroyOnClose={true}
      >
        <VitalSigns caseId={caseId} patientId={patientid} />
      </Modal>

      <Modal
        visible={showModalPaymentStatus}
        onOk={handleClosePaymentStatus}
        onCancel={handleClosePaymentStatus}
        width={1200}
        destroyOnClose={true}
      >
        <PaymentStatus caseId={caseId} patientId={patientid} />
      </Modal>

      <Modal
        visible={showAppointmentStatus}
        onOk={handleCloseAppointmentStatus}
        onCancel={handleCloseAppointmentStatus}
        width={1200}
        destroyOnClose={true}
      >
        <AppointmentStatus caseId={caseId} patientId={patientid} />
      </Modal>
    </div>
  );
};

export default withRouter(Appointments);
