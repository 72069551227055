import React, { useState, useRef, useContext } from "react";
import { Avatar, Button, message, Input } from "antd";
import { SettingsContext } from "../../settingsContext.js";

import appJson from "../../assets/app.json";
import axios from "axios";
function Templates(props) {
  const { settings } = useContext(SettingsContext);
  const [imgSource, setImgSource] = useState("");
  const [fileList, setFileList] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const fileInput = useRef(null);
  const handlePickAFile = () => {
    fileInput.current.click();
  };

  const handleChangeTemplateName = (e) => {
    setTemplateName(e.target.value);
  };

  const handleUpload = async () => {
    if (fileList === null) {
      return message.error("Please select a file to be uploaded!");
    }

    if (templateName === "") {
      return message.error("Please encode template name!");
    }

    let filename = fileList.name;
    const fd = new FormData();
    fd.append("doctorId", props.doctorId);
    fd.append("templateName", templateName);
    fd.append("emailaddress", settings.UserName);
    fd.append("image", fileList);
    let apiUrl = "";
    apiUrl = appJson.apiUrl;
    var token = localStorage.getItem("tokenid");
    axios.defaults.baseURL = apiUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(apiUrl + "api/uploadTemplateImage", fd, filename, {
        onUploadProgress: (uploadEvent) => {
          console.log(
            "Upload progress: " +
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
              "%"
          );
        },
      })
      .then((res) => {
        message.success(res.data.message);
      });
  };

  const onFilePicked = (files) => {
    if (files[0] === undefined) return false;

    setFileList(null);

    let filename = files[0].name;
    if (filename.lastIndexOf(".") <= 0) {
      return message.error("Invalid image file!");
    }

    if (files[0].size >= 2000000) {
      return message.error("File must not be greater than 2MB!");
    }

    setFileList(files[0]);

    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setImgSource(fileReader.result);
    });
    fileReader.readAsDataURL(files[0]);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1>Templates</h1>
        <Avatar shape="square" size={400} src={imgSource} />
      </div>

      <div style={{ marginTop: 10 }}>
        <div>Create a Template</div>

        <div>
          <Input
            style={{ width: 300 }}
            value={templateName}
            placeholder="Template Name"
            onChange={handleChangeTemplateName}
          />
        </div>
      </div>

      <div>
        <div style={{ marginTop: 20 }}>
          <Button block type="primary" onClick={() => handlePickAFile()}>
            Pick a file
          </Button>
        </div>
        <br />
        <div>
          <Button block type="primary" onClick={() => handleUpload()}>
            Save
          </Button>
        </div>
      </div>

      <input
        style={{ display: "none" }}
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => onFilePicked(e.target.files)}
      />
    </div>
  );
}

export default Templates;
