import React from "react";
import { ReactPainter } from "react-painter";

const ImageEditor = () => {
  return (
    <div>
      <ReactPainter
        width={300}
        height={300}
        onSave={(blob) => console.log(blob)}
        image="http://localhost:50386/api/getBodyDiagram?id=2232323"
        render={({ triggerSave, canvas }) => (
          <div>
            <button onClick={triggerSave}>Save Canvas</button>
            <div>{canvas}</div>
          </div>
        )}
      />
    </div>
  );
};

export default ImageEditor;
